import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, FormInput, Container } from "shards-react";
import CommonModal from "../CommonModal";
import { UserDetailsAction } from "../../../flux/Action/UsersAction";
import { Store } from "../../../flux";
import Loader from "../loader";
import PopUp from "../../Popup/PopUpGrade";
export const UserListRow = ({
    currentPosts,
    userStatus,
    updateUser,
    pageNo,
    ShowUsers,
    callApi
}) => {
    const [open, setOpen] = useState(false);
    const [inputType, setInputType] = useState(true);
    const [userDetails, setUserDetails] = useState();
    const [isHide, setisHide] = useState(true);
    const [isValidationPopup, setisValidationPopup] = useState(false);
    const [Userid, setUserid] = useState();
    const [userValue, setUserValue] = useState({
        user_id: "fdg",
        email: "",
        name: "",
        website_url: "",
        mob_no: "",
        company_name: "",
        physical_address: "",
        city_town: "",
        postal_address: "",
        company_email_address: "",
        company_mob_no: ""
    });

    useEffect(() => {
        if (userDetails) {
            console.log("Fsdfs", userDetails);
            setUserValue({
                ...userValue,
                user_id: userDetails.id,
                email: userDetails.email,
                name: userDetails.name,
                website_url: userDetails.website_url,
                mob_no: userDetails.mob_no,
                company_name: userDetails.company_name,
                physical_address: userDetails.physical_address,
                postal_address: userDetails.postal_address,
                city_town: userDetails.city_town,
                company_email_address: userDetails.company_email_address,
                company_mob_no: userDetails.company_mob_no
            });
        }
    }, [userDetails]);

    const onChange = () => {
        setUserDetails(Store.UserDetails().data.data);
    };

    const ShowInputModal = id => {
        setInputType(true);
        if (!open) {
            Store.addChangeListener(onChange);
            let formData = new FormData();
            formData.append("user_id", id);
            UserDetailsAction(formData);
        }
        setOpen(!open);
    };

    const ShowModal = id => {
        setInputType(false);
        if (!open) {
            Store.addChangeListener(onChange);
            let formData = new FormData();
            formData.append("user_id", id);
            UserDetailsAction(formData);
        }
        setOpen(!open);
        setUserDetails();
    };

    const HideModals = id => {
        setisValidationPopup(false)
    };

    const body2 = () => {
        return (

            <p size="sm" className="mb-1 text" >Are You Sure You Want To Approve This Email</p>

        );
    };
    const popChangebody = body2();



    const renderRow = (Obj, i) => {
        return (
            <tr key={i}>
                <td>{i + 1 + (pageNo - 1) * 10}</td>
                <td>{Obj.name}</td>
                <td>{Obj.email}</td>
                <td>{Obj.mob_no}</td>
                <td>
                    {" "}
                    <Button
                        onClick={() => {
                            userStatus(Obj.id, Obj.status)

                        }}
                        theme={Obj.status === "Active" ? "success" : "danger"}
                    >
                        {Obj.status}
                    </Button>{" "}
                </td>

                <td style={{ textAlign: "center" }}>
                    {/* <Button className="action-btn" title="Verify Email" theme="success"
                        onClick={() => {
                            setisValidationPopup(true)
                            setUserid(Obj.id)
                        }}
                    >
                        <i class="material-icons">
                            domain_verification
                        </i>
                    </Button> */}
                    &nbsp;
                    <Button className="action-btn"
                        title="Edit"
                        theme="info"
                        onClick={() => {
                            ShowInputModal(Obj.id);
                            setisHide(true);
                        }}
                    >
                        {" "}
                        <i class="material-icons">
                            edit
                        </i>
                    </Button>
                    &nbsp;
                    <Button className="action-btn"
                        title="View"
                        onClick={() => {
                            ShowModal(Obj.id);
                            setisHide(false);
                        }}
                    >
                        <i class="material-icons">
                            visibility
                        </i>
                    </Button>
                    &nbsp;
                    <Button className="action-btn" title="View List" theme="success"
                        onClick={() => {
                            ShowUsers(Obj.id)
                        }}
                    >
                        <i class="material-icons">
                            grid_view
                        </i>
                    </Button>
                </td>
            </tr >
        );
    };
    const tableBody = currentPosts.length && currentPosts.map(renderRow);

    return (
        <>
            <tbody>
                {tableBody.length !== undefined ?
                    tableBody
                    : <tr>
                        <td colSpan="15" className="text-center noRec-found"> No record found</td>
                    </tr>}
            </tbody>

            <PopUp
                title={"Email Verification"}
                open={isValidationPopup}
                hideModal={HideModals}
                body={popChangebody}
                callApi={() => {
                    callApi(Userid)
                    setisValidationPopup(false)
                }}
                showButton={true}
                ButtonNmae={"Yes"}
            />
            <PopUp
                title={inputType ? "Edit User" : "View User"}
                open={open}
                hideModal={ShowModal}
                showButton={isHide}
                callApi={() => {
                    updateUser(userDetails.id, userValue);
                    setOpen(false);
                    setUserDetails();
                }}
                body={
                    userDetails ? (
                        <>
                            <h6 className="mb-2">
                                <strong>Contact details</strong>
                                <hr />
                            </h6>
                            <div className="row mb-3">
                                <div className="col-6 mb-2">
                                    <strong>Full Name:</strong>
                                </div>
                                {inputType ? (
                                    <FormInput
                                        value={userValue.name}
                                        className="col-6 mb-2"
                                        onChange={e => {
                                            setUserValue({
                                                ...userValue,
                                                name: e.target.value
                                            });
                                        }}
                                    />
                                ) : (
                                    <div className="col-6 mb-2">{userDetails.name}</div>
                                )}
                                <div className="col-6 mb-2">
                                    <strong>Email Address:</strong>
                                </div>
                                {inputType ? (
                                    <FormInput
                                        value={userValue.email}
                                        className="col-6 mb-2"
                                        onChange={e => {
                                            setUserValue({
                                                ...userValue,
                                                email: e.target.value
                                            });
                                        }}
                                    />
                                ) : (
                                    <div className="col-6 mb-2">{userDetails.email}</div>
                                )}
                                <div className="col-6 mb-2">
                                    <strong>Mobile No:</strong>
                                </div>
                                {inputType ? (
                                    <FormInput
                                        value={userValue.mob_no}
                                        className="col-6 mb-2"
                                        onChange={e => {
                                            setUserValue({
                                                ...userValue,
                                                mob_no: e.target.value
                                            });
                                        }}
                                    />
                                ) : (
                                    <div className="col-6 mb-2">{userDetails.mob_no}</div>
                                )}
                            </div>
                            <h6 className="mb-2">
                                <strong>Company Information</strong>
                                <hr />
                            </h6>
                            <div className="row">
                                <div className="col-6 mb-2">
                                    <strong>Registered Company name:</strong>
                                </div>
                                {inputType ? (
                                    <FormInput
                                        value={userValue.company_name}
                                        className="col-6 mb-2"
                                        onChange={e => {
                                            setUserValue({
                                                ...userValue,
                                                company_name: e.target.value
                                            });
                                        }}
                                    />
                                ) : (
                                    <div className="col-6 mb-2">{userDetails.company_name}</div>
                                )}
                                <div className="col-6 mb-2">
                                    <strong>Physical Location:</strong>
                                </div>
                                {inputType ? (
                                    <FormInput
                                        value={userValue.physical_address}
                                        className="col-6 mb-2"
                                        onChange={e => {
                                            setUserValue({
                                                ...userValue,
                                                physical_address: e.target.value
                                            });
                                        }}
                                    />
                                ) : (
                                    <div className="col-6 mb-2">
                                        {userDetails.physical_address}
                                    </div>
                                )}
                                <div className="col-6 mb-2">
                                    <strong>Postal Code:</strong>
                                </div>
                                {inputType ? (
                                    <FormInput
                                        value={userValue.postal_address}
                                        className="col-6 mb-2"
                                        onChange={e => {
                                            setUserValue({
                                                ...userValue,
                                                postal_address: e.target.value
                                            });
                                        }}
                                    />
                                ) : (
                                    <div className="col-6 mb-2">{userDetails.postal_address}</div>
                                )}
                                <div className="col-6 mb-2">
                                    <strong>City / Town:</strong>
                                </div>
                                {inputType ? (
                                    <FormInput
                                        value={userValue.city_town}
                                        className="col-6 mb-2"
                                        onChange={e => {
                                            setUserValue({
                                                ...userValue,
                                                city_town: e.target.value
                                            });
                                        }}
                                    />
                                ) : (
                                    <div className="col-6 mb-2">{userDetails.city_town}</div>
                                )}
                                <div className="col-6 mb-2">
                                    <strong>Company Email Address:</strong>
                                </div>

                                {inputType ? (
                                    <FormInput
                                        value={userValue.company_email_address}
                                        className="col-6 mb-2"
                                        onChange={e => {
                                            setUserValue({
                                                ...userValue,
                                                company_email_address: e.target.value
                                            });
                                        }}
                                    />
                                ) : (
                                    <div className="col-6 mb-2">
                                        {userDetails.company_email_address}
                                    </div>
                                )}

                                <div className="col-6 mb-2">
                                    <strong>Company Website:</strong>
                                </div>
                                {inputType ? (
                                    <FormInput
                                        value={userValue.website_url}
                                        className="col-6 mb-2"
                                        onChange={e => {
                                            setUserValue({
                                                ...userValue,
                                                website_url: e.target.value
                                            });
                                        }}
                                    />
                                ) : (
                                    <div className="col-6 mb-2">{userDetails.website_url}</div>
                                )}
                                <div className="col-6 mb-2">
                                    <strong style={{ whiteSpace: "noWrap" }}>Company Telephone Contacts:</strong>
                                </div>
                                {inputType ? (
                                    <FormInput
                                        value={userValue.company_mob_no}
                                        className="col-6 mb-2"
                                        onChange={e => {
                                            setUserValue({
                                                ...userValue,
                                                company_mob_no: e.target.value
                                            });
                                        }}
                                    />
                                ) : (
                                    <div className="col-6 mb-2">{userDetails.company_mob_no}</div>
                                )}
                            </div>
                        </>
                    ) : (
                        <Loader />
                    )
                }
            />
        </>
    );
};
