import React from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";

const PrivateRoute = ({ component: Component, ...rest }) => {
  var token = Cookies.get("auth");
  var auth = true;
  if (token == undefined || token == null) {
    auth = false;
  } else {
    token = true;
  }
  console.log("hello from privaate route", auth);
  return (
    <Route
      {...rest}
      render={props =>

        auth === true ? <Component {...props} /> : <Redirect to="/login" />

      }
    />
  );
};

export default PrivateRoute;
