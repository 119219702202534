import dispatcher from "../dispatcher";
import axios from "axios";
import Constants from "../constants";
import api from "../../api";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HandleError from "../../utils/HandleError";

export const listgradeAction = async data => {
  var token = JSON.parse(Cookies.get("auth"));
  axios({
    url: api.baseURL + "/list_grades",
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    },
    data: data
  })
    .then(
      res => (
        console.log("res", res),
        dispatcher.dispatch({
          type: Constants.GRADELIST_SUCCESS,
          payload: res
        })
      )
    )
    .catch(error => {
      console.log("res", error.response);
      HandleError(error);
    });
};

export const gradeCategoryAction = () => {
  var token = JSON.parse(Cookies.get("auth"));
  axios({
    url: api.baseURL + "/grade_categories",
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    }
  })
    .then(res =>
      // console.log(res, "im res"),
      dispatcher.dispatch({
        type: Constants.GRADECATEGORIES_SUCCESS,
        payload: res
      })
    )
    // .catch(error => {
    //   dispatcher.dispatch({
    //     type: Constants.GRADECATEGORIES_ERROR,
    //     payload: error.response
    //   });
    //   if (error.response) {
    //     toast.error(error.response.data.message);
    //   }
    // });
    .catch(error => {
      HandleError(error);
    });
};

export const addgradeAction = ({ formData }) => {
  console.log("formdata", formData);
  var token = JSON.parse(Cookies.get("auth"));
  axios({
    url: api.baseURL + "/store_grades",
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    },
    data: formData
  })
    .then(
      res => (
        // console.log(res.data, "im res"),
        toast.success(" Grade added successfully"),
        dispatcher.dispatch({
          type: Constants.GRADECATEGORIES_SUCCESS,
          payload: res
        }),
        listgradeAction()
      )
    )
    // .catch(error => {
    //   dispatcher.dispatch({
    //     type: Constants.GRADECATEGORIES_ERROR,
    //     payload: error.response
    //   });
    //   if (error.response) {
    //     toast.error(error.response.data.message);
    //   }
    // });
    .catch(error => {
      HandleError(error);
    });
};

export const deletegradeAction = ({ formData }) => {
  console.log("formdata", formData);
  var token = JSON.parse(Cookies.get("auth"));
  axios({
    url: api.baseURL + "/delete_grade",
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    },
    data: formData
  })
    .then(
      res => (
        toast.error(`${res.data.message}  "successfully"`),
        listgradeAction(),
        dispatcher.dispatch({
          type: Constants.GRADECATEGORIES_SUCCESS,
          payload: res
        })
      )
    )
    // .catch(error => {
    //   dispatcher.dispatch({
    //     type: Constants.GRADECATEGORIES_ERROR,
    //     payload: error.response
    //   });
    //   if (error.response) {
    //     toast.error(error.response.data.message);
    //   }
    // });
    .catch(error => {
      HandleError(error);
    });
};

export const showGradeAction = ({ formData }) => {
  console.log("formdata", formData);
  var token = JSON.parse(Cookies.get("auth"));
  axios({
    url: api.baseURL + "/show_grade",
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    },
    data: formData
  })
    .then(
      res => (
        console.log(res.data, "im res"),
        dispatcher.dispatch({
          type: Constants.SHOWGRADE_SUCCESS,
          payload: res
        })
      )
    )
    .catch(error => {
      HandleError(error);
    });
};
export const updateGradeAction = ({ formData }) => {
  console.log("formdata", formData);
  var token = JSON.parse(Cookies.get("auth"));
  axios({
    url: api.baseURL + "/update_grade",
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    },
    data: formData
  })
    .then(
      res => (
        console.log(res.data, "im res"),
        toast.success("Grade Update successfully"),
        dispatcher.dispatch({
          type: Constants.SHOWGRADE_SUCCESS,
          payload: res
        }),
        listgradeAction()
      )
    )
    // .catch(error => {
    //   console.log(error);
    //   console.log(error, "hello");
    //   dispatcher.dispatch({
    //     type: Constants.GRADECATEGORIES_ERROR,
    //     payload: error.response
    //   });
    //   toast.error(error.response.data.message);
    // });
    .catch(error => {
      HandleError(error);
    });
};
