import dispatcher from "../dispatcher";
import axios from "axios";
import Constants from "../constants";
import api from "../../api";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HandleError from "../../utils/HandleError";
export const usersAction = async data => {
  var token = JSON.parse(Cookies.get("auth"));
  axios({
    url: api.baseURL + "/users/list",
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    },
    data: data
  })
    .then(
      res => (
        console.log("res", res),
        dispatcher.dispatch({
          type: Constants.USERLIST_SUCCESS,
          payload: res
        })
      )
    )
    .catch(error => {
      console.log("hell", error.response);
      HandleError(error);
    });
};

export const TelecomUsersAction = async data => {
  var token = JSON.parse(Cookies.get("auth"));
  axios({
    url: api.baseURL + "/telecom_users_list",
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    },
    data: data
  })
    .then(
      res => (
        console.log("res", res),
        dispatcher.dispatch({
          type: Constants.TELECOM_USERLIST_SUCCESS,
          payload: res
        })
      )
    )
    .catch(error => {
      console.log("hell", error.response);
      HandleError(error);
    });
};

export const AddTelecomUsersAction = async data => {
  var token = JSON.parse(Cookies.get("auth"));
  axios({
    url: api.baseURL + "/register_client",
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    },
    data: data
  })
    .then(
      res => (
        console.log("res", res),
        dispatcher.dispatch({
          type: Constants.ADD_TELECOM_USERLIST_SUCCESS,
          payload: res
        })
      )
    )
    .catch(error => {
      console.log("hell", error.response);
      HandleError(error);
    });
};

export const TelecomUserUpdateAction = formData => {
  var token = JSON.parse(Cookies.get("auth"));

  axios({
    url: api.baseURL + "/update_telecom_profile",

    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    },
    data: formData
  })
    .then(
      res => res
      // dispatcher.dispatch({
      //   type: Constants.USERDETAILS_SUCCESS,
      //   payload: res
      // })
    )
    .catch(error => {
      HandleError(error);
    });
};

export const TelecomUserDetailsAction = id => {
  var token = JSON.parse(Cookies.get("auth"));
  axios({
    url: api.baseURL + "/users/show_user",
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    },
    data: id
  })
    .then(res =>
      dispatcher.dispatch({
        type: Constants.USERDETAILS_SUCCESS,
        payload: res
      })
    )
    .catch(error => {
      HandleError(error);
    });
};

export const UserDetailsAction = id => {
  var token = JSON.parse(Cookies.get("auth"));
  axios({
    url: api.baseURL + "/users/show_user",
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    },
    data: id
  })
    .then(res =>
      dispatcher.dispatch({
        type: Constants.USERDETAILS_SUCCESS,
        payload: res
      })
    )
    .catch(error => {
      HandleError(error);
    });
};

export const UserStatusAction = formData => {
  var token = JSON.parse(Cookies.get("auth"));

  axios({
    url: api.baseURL + "/users/update_user_status",
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    },
    data: formData
  })
    .then(res =>
      dispatcher.dispatch({
        type: Constants.USERDETAILS_SUCCESS,
        payload: res
      })
    )
    .catch(error => {
      HandleError(error);
    });
};

export const UserUpdateAction = formData => {
  var token = JSON.parse(Cookies.get("auth"));

  axios({
    url: api.baseURL + "/users/update_user",

    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    },
    data: formData
  })
    .then(
      res => res
      // dispatcher.dispatch({
      //   type: Constants.USERDETAILS_SUCCESS,
      //   payload: res
      // })
    )
    .catch(error => {
      HandleError(error);
    });
};
