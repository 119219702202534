import React, { useEffect, useState } from "react";
import {
    Container, Row, Col, Card, CardHeader, CardBody, FormSelect, FormInput, Form, FormRadio
} from "shards-react";
import PageHeader from "../../components/PageHeader";
import { Store } from "../../flux";
import { UserListRow } from "../../components/common/ListRow/ListRow";
import PaginatedItems from "../../components/pagination";
import Loader from '../../components/common/loader';
import { useHistory } from "react-router-dom";
import {
    UserStatusAction,
    UserUpdateAction,
    TelecomUsersAction,
    AddTelecomUsersAction,
    TelecomUserUpdateAction
} from "../../flux/Action/UsersAction";
import Cookies from "js-cookie";
import axios from "axios";
import HandleError from "../../utils/HandleError";
import api from "../../api";
import { toast } from "react-toastify";
import PopUp from "../../components/Popup/PopUpGrade";
import { TelecomUserListRow } from "../../components/common/ListRow/TelecomUserListRow";
const TelecomUserList = () => {
    const [loading, setLoading] = useState(true);
    const [AddUserModal, setAddUserModal] = useState(false);
    const [userList, setUserList] = useState([]);
    const [currentPage, setcurrentPage] = useState(1);
    const [currentPosts, setcurrentPosts] = useState();
    const [isopen, setisopen] = useState(false);
    // ACTIVE/ INACTIVE STATUS DROPDOWN
    const [dropdown, setdropDown] = useState()
    const navigate = useHistory();
    // change user status
    const [userStatus, setuserStatus] = useState(false)
    const [TokenConfirmationModal, setTokenConfirmationModal] = useState(false)
    const [TokenRegenerateID, setTokenRegenerateID] = useState()
    const [userStatusID, setuserStatusID] = useState(false)
    const [userStatuss, setuserStatuss] = useState("")
    const [isOpenssssss, setisopenssssss] = useState(false)
    const [fromData, setFromData] = useState({
        email: '',
        company_name: ''
    })

    console.log("AddUserModal", AddUserModal);
    console.log("fromData", fromData);
    console.log("userList", userList);
    useEffect(() => {
        Store.addChangeListener(onChange);
        console.log("user_status");
        let formData = new FormData();
        formData.append("page_no", currentPage);
        formData.append("user_status", dropdown);
        TelecomUsersAction(formData);
    }, [isOpenssssss, isopen, currentPage, dropdown, TokenConfirmationModal]);

    // useEffect(() => {
    //     Store.addChangeListener(onChange);
    // }, [isOpenssssss, isopen, currentPage, dropdown]);

    const onChange = () => {
        if (Store.TelecomUserList().status === 200) {
            console.log("onChange");
            setLoading(false);
            setUserList(Store.TelecomUserList().data.data.users);
            setcurrentPosts(Store.TelecomUserList().data.data.tot_users);
        }
    };

    const getFieldData = (e) => {
        const { value, name } = e.target

        setFromData((preValue) => {
            return {
                ...preValue,
                [name]: value
            }
        })
    }

    const AddEditTelecomUser = () => {
        AddTelecomUsersAction(fromData)
    }

    const HandlePageClick = data => {
        setcurrentPage(data.selected + 1);
    };

    const ShowUsers = (id) => {
        navigate.push(`/telecom-user-detail/${id}`)
    }
    const updateUser = async (id, ...userValue) => {
        let formData = new FormData();
        formData.append("id", id);
        formData.append("email", userValue[0].email);
        formData.append("name", userValue[0].name);
        formData.append("mob_no", userValue[0].mob_no);
        formData.append("company_name", userValue[0].company_name);
        formData.append("physical_address", userValue[0].physical_address);
        formData.append("postal_address", userValue[0].postal_address);
        formData.append("city_town", userValue[0].city_town);
        formData.append("subs_amount", userValue[0].subs_amount);
        TelecomUserUpdateAction(formData);
        setisopen(!isopen);
    };

    let UserStatus = (id, status) => {
        console.log("Obj.status", status);
        setuserStatuss(status)
        setuserStatusID(id)
        setuserStatus(true)
    }

    let regenerateToken = () => {
        let formData = new FormData();
        formData.append("page_no", currentPage);
        formData.append("user_status", dropdown);
        formData.append("user_id", TokenRegenerateID);
        TelecomUsersAction(formData);
        setTokenConfirmationModal(false)
    }
    // change status pop 
    let statyuspopup = () => {
        return (
            <>
                {userStatuss === "Active" ? <p style={{ margin: "0" }}>Are you sure you want to deactivate  this user account?</p> : <p style={{ margin: "0" }}>Are you sure you want to activate this user account?</p>}
            </>
        )
    }
    let body = statyuspopup()

    let HideModals = () => setuserStatus(false)
    let HideTokenConfirmationModal = () => setTokenConfirmationModal(false)
    let OpenAddUserModal = () => setAddUserModal(true)
    let HideAddUserModals = () => setAddUserModal(false)

    let callApi = () => {
        if((fromData.email !== '') && (fromData.company_name !== '')){
            setLoading(true);
            var token = JSON.parse(Cookies.get("auth"));
            axios({
                url: api.baseURL + "/register_client",
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Accept: "application/json",
                    Authorization: "BEARER " + token.data.token
                },
                data: fromData
            })
            .then(res => {
                console.log("resres", res);
                if (res.data.status == "success") {
                        setLoading(false)
                        setisopenssssss(!isOpenssssss)
                        toast.success("RSSP user added Successfully")
                        setAddUserModal(false)
                    } else {
                        console.log("res.data.message", res.data.message);
                        toast.error(res.data.message)
                        HandleError(res.data)
                    }
                }
            )
            .catch((error) => {
                setLoading(false)
                toast.error(error.response.data.message)
            })
        } else {
            toast.error("Please fill required fields!")
        }
    }
    let updateStatusApi = () => {
        setuserStatus(false)
        setLoading(true);
        var token = JSON.parse(Cookies.get("auth"));
        axios({
            url: api.baseURL + "/update_telecom_user_status",
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token
            },
            data: JSON.stringify({user_id: userStatusID})
        })
        .then(res => {
            if (res.data.status == "success") {
                    setisopenssssss(!isOpenssssss)
                    toast.success(res.data.message)
                    setAddUserModal(false)
                } else if (res.data.status == "success") {
                    HandleError(res.data)
                }
            }
        )
    }
    return (
        <>
            <Container fluid className="main-content-container px-4">
                <PageHeader
                    title={"RSSP users"}
                    subtitle={"MsingiPACK Cloud"}
                    name={"Add user"}
                    Status={true}
                    onsubmit={OpenAddUserModal}
                />
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="border-bottom d-flex justify-content-between">
                                <h6 className="m-0">Users</h6>
                                <h6 className="m-0">
                                    <FormSelect onChange={e => {
                                        setdropDown(e.target.value);
                                    }}>
                                        <option value="" >
                                            All
                                        </option>
                                        <option value="active">Active Users</option>
                                        <option value="inactive">Inactive Users</option>

                                    </FormSelect>
                                </h6>
                            </CardHeader>
                            <CardBody className="p-0 pb-3">
                                {loading === true ?
                                    <Loader />
                                    :
                                    <>
                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <thead className="bg-light">
                                                    <tr>
                                                        <th scope="col" className="border-0"> S.No </th>
                                                        <th scope="col" className="border-0">
                                                            Company Name
                                                        </th>
                                                        <th scope="col" className="border-0">
                                                            Email
                                                        </th>

                                                        <th scope="col" className="border-0">
                                                            Phone
                                                        </th>
                                                        <th scope="col" className="border-0">
                                                            Subscription Amount
                                                        </th>
                                                        <th scope="col" className="border-0">
                                                            Token
                                                        </th>
                                                        <th scope="col" className="border-0">
                                                            Status
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="border-0"
                                                            style={{ textAlign: "center" }}
                                                        >
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <TelecomUserListRow
                                                    currentPosts={userList}
                                                    //sendSubject={sendSubject}
                                                    pageNo={currentPage}
                                                    title={" View Subject"}
                                                    userStatus={UserStatus}
                                                    regenerateToken={(id) => {
                                                        setTokenRegenerateID(id)
                                                        setTokenConfirmationModal(true)
                                                    }}
                                                    updateUser={updateUser}
                                                    ShowUsers={ShowUsers}
                                                />
                                            </table>
                                        </div>
                                        {currentPosts > 10 &&
                                            <PaginatedItems
                                                List={currentPosts}
                                                HandlePageClick={HandlePageClick}
                                            />}
                                    </>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <PopUp
                title={"User account status"}
                open={userStatus}
                hideModal={HideModals}
                body={body}
                callApi={updateStatusApi}
                showButton={true}
                ButtonNmae={"Yes"}
            />
            <PopUp
                title={"Warning!"}
                open={TokenConfirmationModal}
                hideModal={HideTokenConfirmationModal}
                body="Changing a token will stop all active users in the account, Are you sure you want to change?"
                callApi={regenerateToken}
                showButton={true}
                ButtonNmae={"Yes"}
            />

            <PopUp
                title={"RSSP user"}
                open={AddUserModal}
                hideModal={HideAddUserModals}
                body={
                    <>
                        <Form onSubmit={AddEditTelecomUser} className="login-from-bx">
                            <Row>
                                <Col>
                                    <h5>Contact Details</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="form-group">
                                    <label>Full Name</label>
                                    <FormInput
                                        type="text"
                                        placeholder="Name"
                                        name="name"
                                        onChange={getFieldData}
                                    />
                                </Col>
                                <Col className="form-group">
                                    <label>Email Address <span style={{color: "red"}}>*</span></label>
                                    <FormInput
                                        type="email"
                                        placeholder="Email"
                                        name="email"
                                        onChange={getFieldData}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="form-group">
                                    <label>Mobile Number</label>
                                    <FormInput
                                        type="text"
                                        placeholder="Mobile Number"
                                        name="mob_no"
                                        onChange={getFieldData}
                                    />
                                </Col>
                                <Col className="form-group">
                                    <label>Registerd Company Name <span style={{color: "red"}}>*</span></label>
                                    <FormInput
                                        type="text"
                                        placeholder="Company Name"
                                        name="company_name"
                                        onChange={getFieldData}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="form-group">
                                    <label>Physical Address</label>
                                    <FormInput
                                        type="text"
                                        placeholder="Physical Address"
                                        name="physical_address"
                                        onChange={getFieldData}
                                    />
                                </Col>
                                <Col className="form-group">
                                    <label>Postal Address</label>
                                    <FormInput
                                        type="text"
                                        placeholder="Postal Address"
                                        name="postal_address"
                                        onChange={getFieldData}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="form-group">
                                    <label>City/Town</label>
                                    <FormInput
                                        type="text"
                                        placeholder="City/Town"
                                        state="invalid"
                                        name="city_town"
                                        onChange={getFieldData}
                                    />
                                </Col>
                                <Col className="form-group">
                                    <label>Subscription Amount</label>
                                    <FormInput
                                        type="text"
                                        placeholder="Subscription Amount"
                                        state="invalid"
                                        name="subs_amount"
                                        onChange={getFieldData}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </>
                }
                callApi={callApi}
                showButton={true}
                ButtonNmae={"Add User"}
            />
        </>
    );
};

export default TelecomUserList;
