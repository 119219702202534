import React, { useEffect, useState } from "react";
import PaginatedItems from "../../../components/pagination";
import { CMSListAction } from "../../../flux/Action/CMS";
import CMSlistData from "../../../components/common/List";
import { CardBody } from "shards-react";
import { useHistory } from "react-router-dom";
import { Store } from "../../../flux";
const CMSListRow = () => {
  const [CMSList, setCMSList] = useState([]);
  const [CurrentPage, setcurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(10);
  const [ToTContact, setToTContact] = useState();
  const [isopen, setisopen] = useState(false);

  const history = useHistory();

  useEffect(() => {
    Store.addChangeListener(onChange);

    // Call CMS List
    let formData = new FormData();
    formData.append("page_no", CurrentPage);
    CMSListAction(formData);
  }, []);

  const onChange = () => {
    if (Store.CMS_Lists().status === 200) {
      setCMSList(Store.CMS_Lists().data.data.contents);
      setToTContact(Store.CMS_Lists().data.data.tot_contents);
    }
  };

  const EditCMS = id => {
    history.push({
      pathname: `/editcms/` + id,
      state: {
        id: id
      }
    });
  };

  console.log(CMSList);
  return (
    <>
      <CardBody className="p-0 pb-3">
        <div className="table-responsive">
          <table className="table mb-0">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0">
                  S.No
                </th>
                <th scope="col" className="border-0">
                  Title
                </th>
                <th scope="col" className="border-0">
                  Slug
                </th>
                <th
                  scope="col"
                  className="border-0"
                  style={{ textAlign: "center" }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <CMSlistData
              currentPosts={CMSList}
              CurrentPage={CurrentPage}
              EditCMS={EditCMS}
            />
          </table>
        </div>
        {ToTContact > 10 &&
          <PaginatedItems List={ToTContact} />}
      </CardBody>
    </>
  );
};

export { CMSListRow };
