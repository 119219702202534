import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, FormGroup } from "shards-react";
import PageHeader from "../../components/PageHeader";
import PopUp from "../../components/Popup/PopUpGrade";
import ReactQuill from "react-quill";
import axios from "axios";
import api from "../../api";

import {
  listgradeAction,
  gradeCategoryAction,
  addgradeAction,
  deletegradeAction,
  showGradeAction,
  updateGradeAction
} from "../../flux/Action/GradeAction";
import { Button } from "shards-react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import HandleError from "../../utils/HandleError";

import logoSlide from "../../images/safaricom.png"
import logoSlide2 from "../../images/avatar2.svg"


const PromotionSliderManagement = () => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({id:0,image:""});
  const [isOpen, setisOpen] = useState(false);
  const [isDeleteOpen, setisDeleteOpen] = useState(false);
  const [scrollList, setScrollList] = useState([]);
  const [Loading, setLoading] = useState(true)
  const [bannerList, setBannerList] = useState([]);
  const [imageData, setImagedata] = useState([]);
  const [imagePreview, setImagePreview] = useState("");

  const history = useHistory();

  useEffect(() => {
    console.log("effect");
    promotion_slider_listing()
}, []);
  
  const hideModal = () => {
    setOpen(!open);
  };

  const HideModal = () => {
    setisOpen(!isOpen);
  };

  const HideModals = () => {
    setisDeleteOpen(!isDeleteOpen);
  };
  const addBanner = () => {
    gradeCategoryAction();
    setImagePreview("")
    setOpen(true);
  };

  const viewBannerManagementDetail = () => {
    history.push({
      pathname: '/promotion-detail',
    });
  };

  const updateBanner = (data) => {   
    setState(data) 
    setImagePreview("")
    setisOpen(!isOpen);
  };

  // const deleteBanner = () => {
  //   setisDeleteOpen(!isDeleteOpen);
  // }

  const promotion_slider_listing = () =>{
    var token = JSON.parse(Cookies.get("auth"));
    axios({
        url: api.baseURL + `/banner_list`,
        method: "POST",
        headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "BEARER " + token.data.token
        },
        data: {}
    })
        .then(res => {
            if (res.data.status === "success") {
              setBannerList(res.data.data)
                setLoading(false)
            }
        })
        .catch(error => {
            HandleError(error);
        });
  }

  const deleteDialog = (data) => {   
    setState({ ...state, id: data.id }) 
    setisDeleteOpen(!isDeleteOpen);
  };

  const deleteBanner = () =>{
    console.log("call");
    var token = JSON.parse(Cookies.get("auth"));
    axios({
        url: api.baseURL + `/banner_delete`,
        method: "POST",
        headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "BEARER " + token.data.token
        },
        data: {id:state.id}
    }).then(res => {
            if (res.data.status === "success") {
              promotion_slider_listing()
             // setBannerList(res.data.data)
              //  setLoading(false)
              setisDeleteOpen(!isDeleteOpen);
              setState({ id:0,image:"" })
            }
        })
        .catch(error => {
            HandleError(error);
        });
  }

  const newInputHandler = (e) => {
    //  console.log(e.target.type);
      if (e.target.type != "file") {
       // setNewState({ ...newState, [e.target.name]: e.target.value })
      }else {
        setImagePreview(URL.createObjectURL(e.target.files[0]))
        setImagedata(e.target.files)
      }
  
    }

  const createBannerSubmit = () => {
    console.log("call");
    var token = JSON.parse(Cookies.get("auth"));
    console.log(imageData);
    var formdata = new FormData();            
    if (imageData.length > 0) {
        formdata.append('uploaded_image', imageData[0])
    }

    axios({
      url: api.baseURL + `/banner_create`,
      method: "POST",
      headers: {
         'Content-Type': 'multipart/form-data',
        Accept: "application/json",
        Authorization: "BEARER " + token.data.token
      },
      data: formdata
    }).then(res => {
      if (res.data.status === "success") {        
        setImagedata([])
        hideModal()
        // setBannerList(res.data.data)
        //  setLoading(false)
       // setisDeleteOpen(!isDeleteOpen);
       promotion_slider_listing()
      }
    })
      .catch(error => {
        HandleError(error);
      });
  }

  const updateBannerSubmit = () => {
    console.log("call");
    var token = JSON.parse(Cookies.get("auth"));
    console.log(imageData);
    var formdata = new FormData();
        formdata.append('id', state.id)       
        formdata.append('image', state.image)
        if (imageData.length > 0) {
            formdata.append('uploaded_image', imageData[0])
        }

    axios({
      url: api.baseURL + `/banner_update`,
      method: "POST",
      headers: {
         'Content-Type': 'multipart/form-data',
        Accept: "application/json",
        Authorization: "BEARER " + token.data.token
      },
      data: formdata
    }).then(res => {
      if (res.data.status === "success") {
        setImagedata([])
        promotion_slider_listing()
        setisOpen(!isOpen);
        setState({ id:0,image:"" })
        // setBannerList(res.data.data)
        //  setLoading(false)
       // setisDeleteOpen(!isDeleteOpen);
       // scroll_listing()
      }
    })
      .catch(error => {
        HandleError(error);
      });
  }

  return (
    <>
      <Container fluid className="main-content-container">
        <PageHeader
          title={"Promotion Slider Management"}
          subtitle={"MsingiPACK Cloud"}
          name={"Add New"}
          onsubmit={addBanner}
          Status={true}
        />

        <PopUp
          title={"Add Logo"}
          open={open}
          hideModal={hideModal}
          body={
            <>
             <div className="form-group">
              <label>Upload Logo</label>
                <input type="file" name="uploadfile" id="img" className="mb-2 form-control"
                  accept="image/*"
                  onChange={(e) => newInputHandler(e)}
                />
                {(imagePreview)?<img src={imagePreview} style={{ maxWidth: "100%", height: "80px" }} />:""}
             </div>
            </>
          }
          callApi={createBannerSubmit}
          showButton={true}
        />

        <PopUp
          title={"Update Logo"}
          open={isOpen}
          hideModal={HideModal}
          body={
            <>
              <div className="form-group">
                <label>Upload Logo</label>
                <input type="file" name="uploadfile" id="img" className="mb-2 form-control"
                  accept="image/*"
                  onChange={(e) => newInputHandler(e)}
                />
                <img src={(imagePreview !="")?imagePreview:state.image} style={{maxWidth: "100%", height: "60px"}} />
              </div>
            </>
          }
          callApi={updateBannerSubmit}
          showButton={true}
          ButtonNmae={"Update"}
        />

        <PopUp
          title={"Delete Logo"}
          open={isDeleteOpen}
          hideModal={HideModals}
          body={
            <>
              Are you sure you want to delete this logo
            </>
          }
           callApi={deleteBanner}
          showButton={true}
          ButtonNmae={"Delete"}
        />

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <div className="table-responsive promotion-slider-table">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" width="5%">
                          S.No
                        </th>
                        <th scope="col" width="20%">
                          Logo Image
                        </th>
                        <th
                          scope="col"
                          width="15%"
                          style={{ textAlign: "center" }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    
                    <tbody>
                    {bannerList.map((val, index) =>
                      <tr> 
                        <td>{index+1}</td>
                        <td>
                          <img src={val.image} height={60}/>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {/* <Button className="action-btn" title="View" onClick={viewBannerManagementDetail}>
                            <i className="material-icons">
                              visibility
                            </i>
                          </Button>
                          &nbsp; */}
                          <Button className="action-btn"
                              theme="info"
                              title="Edit"
                              onClick={()=>updateBanner(val)}
                          >
                              <i class="material-icons">
                                  edit
                              </i>
                          </Button>
                          &nbsp;
                          <Button className="action-btn" title="Delete" theme="danger"  onClick={()=>deleteDialog(val)}>
                              <i class="material-icons">
                                  delete
                              </i>
                          </Button>
                        </td>
                      </tr>
                    )}
                      {/* <tr> 
                        <td>2</td>
                        <td>
                          <img src={logoSlide2} height={60}/>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <Button className="action-btn" title="View" onClick={viewBannerManagementDetail}>
                            <i className="material-icons">
                              visibility
                            </i>
                          </Button>
                          &nbsp;
                          <Button className="action-btn"
                              theme="info"
                              title="Edit"
                              onClick={updateBanner}
                          >
                              <i class="material-icons">
                                  edit
                              </i>
                          </Button>
                          &nbsp;
                          <Button className="action-btn" title="Delete" theme="danger"  onClick={()=>deleteDialog()}>
                              <i class="material-icons">
                                  delete
                              </i>
                          </Button>
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default PromotionSliderManagement;
