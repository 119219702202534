import React, { useState, useEffect } from "react";
import Api from "../api";
import {
    Container,
    Row,
    Col,
    Card,
    Form,
    FormInput,
    Button
} from "shards-react";
import { Link } from "react-router-dom";
import { authAction } from "../flux/Action/AuthAction";
import { Store } from "../flux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";

function ForgetPassword() {
    const [username, setusername] = useState({ email: "" })
    const [loading, setLoading] = useState(false);
    const [LoginRes, setLoginRes] = useState({});
    const navigate = useHistory();

    useEffect(() => {
        console.log("heelo");
        console.log("heeelo", LoginRes);
        Store.addChangeListener(onChange);
        if (LoginRes && LoginRes.status === 201) {
            toast.success(LoginRes.data.message);
            setTimeout(() => navigate.push("/dashboard"), 1000);
        } else {
            setLoading(false);
        }
    }, [LoginRes]);


    function onChange() {
        setLoginRes(Store.addLogin());
    }

    function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        setTimeout(() => setLoading(false), 2000);
        let formData = new FormData();
        const config = {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(username)
        };

        fetch(Api.baseURL + `/send-verification-email`, config)
            .then((res) => res.json())
            .then((data) => {
                if (data.status === "success") {
                    toast.success(data.message)
                }

                if (data.status === "error") {
                    toast.error(data.message)
                }
            })
            .catch((err) => {
            });
    }
    return (
        <div className="auth-section">
            <div className="auth-container">
                <Row className="row h-100 justify-content-center align-items-center">
                    <Col lg="12" className="mb-4">
                        {/* Complete Form Example */}
                        <Card small className="py-4">
                            <Container>
                                <Row>
                                    <Col>
                                        <Form onSubmit={handleSubmit}>
                                            <div className="auth-title text-center mb-2">
                                                <h2>Forget Password</h2>
                                                <p>Please enter your email below to <br /> receive reset password link</p>
                                            </div>
                                            <Row form>
                                                <Col className="form-group">
                                                    <label htmlFor="feEmailAddress">Email</label>
                                                    <FormInput
                                                        id="feEmailAddress"
                                                        type="email"
                                                        placeholder="Email"
                                                        name="username"
                                                        onChange={(e) => setusername({ ...username, ["email"]: e.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row form>
                                                <Col md="12">
                                                    <Button type="submit" disabled={loading} className="w-100">
                                                        Submit
                                                    </Button>
                                                    
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </Container>
                        </Card>
                    </Col>
                </Row>
                <ToastContainer />
            </div>
        </div>
    );
}
export default ForgetPassword;
