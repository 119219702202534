import React, { useState, useEffect } from "react";
import {
   Container,
   Row,
   Col,
   Card,
   Form,
   FormInput,
   Button
} from "shards-react";
import { Link } from "react-router-dom";
import { authAction } from "../flux/Action/AuthAction";
import { Store } from "../flux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
function LoginContainer() {
   const [username, setUsername] = useState("");
   const [password, setPassword] = useState("");
   const [loading, setLoading] = useState(false);
   const [LoginRes, setLoginRes] = useState({});
   const navigate = useHistory();

   useEffect(() => {
      Store.addChangeListener(onChange);
      var token = Cookies.get("auth");
      if (token !== undefined) {
         navigate.push("/dashboard")
      }
      if (LoginRes && LoginRes.status === 201) {
         toast.success(LoginRes.data.message);
         setTimeout(() => navigate.push("/dashboard"), 1000);
      } else {
         setLoading(false);

      }

   }, [LoginRes]);


   function onChange() {
      setLoginRes(Store.addLogin());
   }

   function handleSubmit(e) {
      e.preventDefault();
      setLoading(true);
      setTimeout(() => setLoading(false), 2000);
      let formData = new FormData();
      formData.append("email", username);
      formData.append("password", password);
      authAction({ formData, e });
   }
   return (
      <div className="auth-section">
         <input type="hidden" value="27-09-2022" name="viraj" />
         <div className="auth-container">
            <Row className="row h-100 justify-content-center align-items-center">
               <Col lg="12">
                  <div className="text-center mb-4">
                     <img src="/images/msingipack-logo.svg" className="auth-logo" alt="logo" />
                  </div>
                  <Card small className="py-4 shadow-none">
                     <Container>
                        <Row>
                           <Col>
                              <Form onSubmit={handleSubmit} className="login-from-bx">
                                 <div className="auth-title text-center mb-2">
                                    <h2>Admin Portal</h2>
                                    <p>Login to your Account</p>
                                 </div>
                                 <Row form>
                                    <Col className="form-group">
                                       <label htmlFor="feEmailAddress">Email</label>
                                       <FormInput
                                          id="feEmailAddress"
                                          type="email"
                                          placeholder="Email"
                                          name="username"
                                          onChange={e => setUsername(e.target.value)}
                                       />
                                    </Col>
                                 </Row>
                                 <Row form>
                                    <Col className="form-group">
                                       <label htmlFor="fePassword" className="with-link-label">Password
                                          <Link to="forget-password">Forget Password?</Link>
                                       </label>
                                       <FormInput
                                          id="fePassword"
                                          type="password"
                                          placeholder="Password"
                                          name="password"
                                          onChange={e => setPassword(e.target.value)}
                                       />
                                    </Col>
                                 </Row>

                                 <Row form>
                                    <Col>
                                       <Button type="submit" disabled={loading} className="w-100">
                                          Login
                                       </Button>

                                    </Col>

                                 </Row>
                              </Form>
                           </Col>
                        </Row>
                     </Container>
                  </Card>
               </Col>
            </Row>
            <ToastContainer />
         </div>
      </div>
   );
}
export default LoginContainer;
