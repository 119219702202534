import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, FormGroup } from "shards-react";
import PageHeader from "../../components/PageHeader";
import PopUp from "../../components/Popup/PopUpGrade";
import ReactQuill from "react-quill";
import axios from "axios";
import api from "../../api";
import Cookies from "js-cookie";
import HandleError from "../../utils/HandleError";
import {
  listgradeAction,
  gradeCategoryAction,
  addgradeAction,
  deletegradeAction,
  showGradeAction,
  updateGradeAction
} from "../../flux/Action/GradeAction";
import { Button } from "shards-react";
import { useHistory } from "react-router-dom";


import bannerImage from "../../images/banner.jpg"
import bannerImage2 from "../../images/banner2.jpg"


const BannerManagement = () => {
  const [open, setOpen] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [state, setState] = useState({ id: 0, url: "", content: "",image:"" });
  const [newState, setNewState] = useState({ id: 0, url: "", content: "",image:"" });
  const [imagePreview, setImagePreview] = useState("");
  const [imageData, setImagedata] = useState([]);
  const [isDeleteOpen, setisDeleteOpen] = useState(false);
  const [bannerList, setBannerList] = useState([]);
  const [Loading, setLoading] = useState(true)

  const history = useHistory();

  useEffect(() => {
    console.log("effect");
    scroll_listing()
  }, []);

  const hideModal = () => {
    setOpen(!open);
  };

  const updateHideModal = () => {
    setisOpen(!isOpen);
    setImagePreview("")
  };

  const HideModals = () => {
    setisDeleteOpen(!isDeleteOpen);
  };
  const addBanner = () => {
    gradeCategoryAction();
    setOpen(true);
  };

  const viewBannerManagementDetail = (data) => {
    history.push({
      pathname: '/banner-management-detail',
      state: data
    });
  };

  const updateBanner = (data) => {
    setState(data)
    setisOpen(!isOpen);
  };

  const inputHandler = (e) => {
   // console.log(e.target.type);
    if (e.target.type != "file") {
      setState({ ...state, [e.target.name]: e.target.value })
    }else {
      setImagePreview(URL.createObjectURL(e.target.files[0]))
      setImagedata(e.target.files)
    }

  }

  const deleteDialog = (data) => {
    setState({ ...state, id: data.id })
    setisDeleteOpen(!isDeleteOpen);
  };


  const scroll_listing = () => {
    var token = JSON.parse(Cookies.get("auth"));
    axios({
      url: api.baseURL + `/slider_list`,
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "BEARER " + token.data.token
      },
      data: {}
    }).then(res => {
      if (res.data.status === "success") {
        setBannerList(res.data.data)
        setLoading(false)
      }
    })
      .catch(error => {
        HandleError(error);
      });
  }

  const deleteBanner = () => {
    console.log("call");
    var token = JSON.parse(Cookies.get("auth"));
    axios({
      url: api.baseURL + `/slider_delete`,
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "BEARER " + token.data.token
      },
      data: { id: state.id }
    }).then(res => {
      if (res.data.status === "success") {
        // setBannerList(res.data.data)
        //  setLoading(false)
        setisDeleteOpen(!isDeleteOpen);
        scroll_listing()
      }
    })
      .catch(error => {
        HandleError(error);
      });
  }

  const updateBannerSubmit = () => {
    console.log("call");
    var token = JSON.parse(Cookies.get("auth"));
    console.log(imageData);
    var formdata = new FormData();
        formdata.append('id', state.id)
        formdata.append('url', state.url)
        formdata.append('content', state.content)
        formdata.append('image', state.image)
        if (imageData.length > 0) {
            formdata.append('uploaded_image', imageData[0])
        }

    axios({
      url: api.baseURL + `/slider_update`,
      method: "POST",
      headers: {
         'Content-Type': 'multipart/form-data',
        Accept: "application/json",
        Authorization: "BEARER " + token.data.token
      },
      data: formdata
    }).then(res => {
      if (res.data.status === "success") {
        setImagedata([])
        updateHideModal()
        scroll_listing()
        // setBannerList(res.data.data)
        //  setLoading(false)
       // setisDeleteOpen(!isDeleteOpen);
       // scroll_listing()
      }
    })
      .catch(error => {
        HandleError(error);
      });
  }

  const newInputHandler = (e) => {
  //  console.log(e.target.type);
    if (e.target.type != "file") {
      setNewState({ ...newState, [e.target.name]: e.target.value })
    }else {
      setImagePreview(URL.createObjectURL(e.target.files[0]))
      setImagedata(e.target.files)
    }

  }

  const createBannerSubmit = () => {
    console.log("call");
    var token = JSON.parse(Cookies.get("auth"));
    console.log(imageData);
    var formdata = new FormData();        
    formdata.append('url', newState.url)
    formdata.append('content', newState.content)    
    if (imageData.length > 0) {
        formdata.append('uploaded_image', imageData[0])
    }

    axios({
      url: api.baseURL + `/slider_create`,
      method: "POST",
      headers: {
         'Content-Type': 'multipart/form-data',
        Accept: "application/json",
        Authorization: "BEARER " + token.data.token
      },
      data: formdata
    }).then(res => {
      if (res.data.status === "success") {
        setNewState({ id: 0, url: "", content: "",image:"" });
        setImagedata([])
        hideModal()
        // setBannerList(res.data.data)
        //  setLoading(false)
       // setisDeleteOpen(!isDeleteOpen);
        scroll_listing()
      }
    })
      .catch(error => {
        HandleError(error);
      });
  }



  return (
    <>
      <Container fluid className="main-content-container">
        <PageHeader
          title={"Banner Management"}
          subtitle={"MsingiPACK Cloud"}
          name={"Add New"}
          onsubmit={addBanner}
          Status={true}
        />

        <PopUp
          title={"Add Banner"}
          open={open}
          hideModal={hideModal}
          body={
            <>
              <div className="form-group">
                <label>Upload Banner (Dimensions: 1920x580 pixels)</label>
                <input type="file" name="uploadfile" id="img" className="mb-2 form-control"
                  accept="image/*"
                  onChange={(e) => newInputHandler(e)}
                />
                {(imagePreview)?<img src={imagePreview} style={{ maxWidth: "100%", height: "80px" }} />:""}
              </div>
              <div className="form-group">
                <label>URL</label>
                <input
                  size="sm"
                  type="text"
                  placeholder="Url"
                  className="mb-2 form-control"
                  name="url"
                  value={newState.url}
                  onChange={(e) => newInputHandler(e)}
                />
              </div>
              <div className="form-group">
                <label>Add Content</label>
                <textarea
                  size="sm"
                  type="text"
                  placeholder="Add Content"
                  className="mb-2 form-control"
                  style={{ resize: "none", minHeight: "140px" }}
                  name="content"
                  value={newState.content}
                  onChange={(e) => newInputHandler(e)}
                />
              </div>
            </>
          }
          callApi={createBannerSubmit}
          showButton={true}
        />

        <PopUp
          title={"Update Banner"}
          open={isOpen}
          hideModal={updateHideModal}
          body={
            <>
              <div className="form-group">
                <label>Upload Banner (Dimensions: 1920x580 pixels)</label>
                <input type="file"
                  name="uploadfile"
                  id="img"
                  className="mb-2 form-control"
                  accept="image/*"
                  onChange={(e) => inputHandler(e)}
                />
                <img src={(imagePreview !="")?imagePreview:state.image} style={{ maxWidth: "100%", height: "80px" }} />
              </div>
              <div className="form-group">
                <label>URL</label>
                <input
                  size="sm"
                  type="text"
                  placeholder="Url"
                  className="mb-2 form-control"
                  name="url"
                  value={state.url}
                  onChange={(e) => inputHandler(e)}
                />
              </div>
              <div className="form-group">
                <label>Add Content</label>
                <textarea
                  size="sm"
                  type="text"
                  placeholder="Add Content"
                  className="mb-2 form-control"
                  name="content"
                  style={{ resize: "none", minHeight: "140px" }}
                  value={state.content}
                  onChange={(e) => inputHandler(e)}
                />
              </div>
            </>
          }
          callApi={updateBannerSubmit}
          showButton={true}
          ButtonNmae={"Update"}
        />

        <PopUp
          title={"Delete Banner"}
          open={isDeleteOpen}
          hideModal={deleteDialog}
          body={
            <>
              Are you sure you want to delete this banner
            </>
          }
          callApi={deleteBanner}
          showButton={true}
          ButtonNmae={"Delete"}
        />

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <div className="table-responsive banner-management-table">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" width="5%">
                          S.No
                        </th>
                        <th scope="col" width="20%">
                          Banner Image
                        </th>
                        <th scope="col" width="20%">
                          Link
                        </th>
                        <th scope="col" width="40%">
                          Content
                        </th>
                        <th
                          scope="col"
                          width="15%"
                          style={{ textAlign: "center" }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {bannerList.map((val, index) =>
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            <img src={val.image} height={80} />
                          </td>
                          <td>
                            {val.url}
                          </td>
                          <td>
                            <p>
                              {val.content}
                            </p>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <Button className="action-btn" title="View" onClick={() => viewBannerManagementDetail(val)}>
                              <i className="material-icons">
                                visibility
                              </i>
                            </Button>
                            &nbsp;
                            <Button className="action-btn"
                              theme="info"
                              title="Edit"
                              onClick={() => updateBanner(val)}
                            >
                              <i class="material-icons">
                                edit
                              </i>
                            </Button>
                            &nbsp;
                            <Button className="action-btn" title="Delete" theme="danger" onClick={() => deleteDialog(val)}>
                              <i class="material-icons">
                                delete
                              </i>
                            </Button>
                          </td>
                        </tr>
                      )}
                      {/* <tr> 
                        <td>2</td>
                        <td>
                          <img src={bannerImage2} height={80}/>
                        </td>
                        <td>
                          https://www.google.com/
                        </td>
                        <td>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <Button className="action-btn" title="View" onClick={viewBannerManagementDetail}>
                            <i className="material-icons">
                              visibility
                            </i>
                          </Button>
                          &nbsp;
                          <Button className="action-btn"
                              theme="info"
                              title="Edit"
                              onClick={updateBanner}
                          >
                              <i class="material-icons">
                                  edit
                              </i>
                          </Button>
                          &nbsp;
                          <Button className="action-btn" title="Delete" theme="danger"  onClick={deleteBanner}>
                              <i class="material-icons">
                                  delete
                              </i>
                          </Button>
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default BannerManagement;
