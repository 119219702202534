import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Card, CardBody } from "shards-react";

class MultilevelStats extends React.Component {
  constructor(props) {
    super(props);

  }

  render() {
    const { variation, label, value, label1, value1, heading } = this.props;

    const dataFieldClasses = classNames(
      "stats-small__data text-center mw-100"
    );

    return (
      <Card small className="p-4 dashboard-bx-custom">
        <div className={dataFieldClasses}  >
          <h5 className="m-2"><b>{heading}</b></h5>
        </div>
        <div className="d-flex justify-content-center">
          <span className="">{label}  &nbsp;&nbsp; -</span>
          <h6 className=""><b>&nbsp;&nbsp;{value}</b></h6>
        </div>
        <div className="d-flex justify-content-center " >
          <span className="">{label1} &nbsp;&nbsp; -</span>
          <h6 className=""><b>&nbsp;&nbsp;{value1}</b></h6>
        </div>
      </Card>
    );
  }
}

MultilevelStats.propTypes = {
  /**
   * The Small Stats variation.
   */
  label: PropTypes.string,
  /**
   * The value.
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

MultilevelStats.defaultProps = {
  value: 0,
  label: "Label",
};

export default MultilevelStats;
