import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import PaginatedItems from "../../components/pagination";
import Loader from '../../components/common/loader';
import HandleError from "../../utils/HandleError";
import api from "../../api";
import Cookies from "js-cookie";
import axios from "axios";
import { TelecomUserRechargeListRow } from "../../components/common/ListRow/TelecomUserRechargeListRow";
const TelecomUserRechargeList = () => {
    let { id } = useParams();
    let location = useLocation()
    const [loading, setLoading] = useState(true);
    const [userList, setUserList] = useState([]);
    const [currentPosts, setcurrentPosts] = useState();
    const [currentPage, setcurrentPage] = useState(1);

    console.log("idid", id);
    console.log("userList", userList);
    console.log("location", location);

    useEffect(() => {
        var token = JSON.parse(Cookies.get("auth"));
        let Data = { "page_no": currentPage, "client_id": Number(location.state), "user_id": parseInt(id) }
        axios({
            url: api.baseURL + `/get_user_recharge_list`,
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
            data: Data
        })
            .then(
                res => (
                    // console.log(res)
                    setUserList(res.data.data.recharges),
                    setcurrentPosts(res.data.data.tot_users),
                    setLoading(false)
                )
            )
            .catch(error => {
                console.log("res", error.response);
                HandleError(error);
            });
    }, [currentPage]);


    const HandlePageClick = data => {
        setcurrentPage(data.selected + 1);
    };


    return (
        <>
            <Container fluid className="main-content-container px-4">
                <PageHeader
                    title={"Recharge list"}
                    subtitle={"MsingiPACK Cloud"}
                    name={"Add users"}
                    Status={false}
                />
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="border-bottom">
                                <h6 className="m-0">List</h6>
                            </CardHeader>
                            <CardBody className="p-0 pb-3">
                                {loading === true ?
                                    <Loader />
                                    :
                                    <>
                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <thead className="bg-light">
                                                    <tr>
                                                        <th scope="col" className="border-0"> S.No </th>
                                                        <th scope="col" className="border-0"> Msisdn </th>
                                                        <th scope="col" className="border-0"> Request Id </th>
                                                        <th scope="col" className="border-0"> Product Name </th>
                                                        <th scope="col" className="border-0"> Product ID </th>
                                                        <th scope="col" className="border-0"> Subscription date </th>
                                                        <th scope="col" className="border-0"> User ID </th>
                                                        <th scope="col" className="border-0"> Transaction Reference </th>
                                                        <th scope="col" className="border-0"> User phone </th>
                                                        <th scope="col" className="border-0"> Amount </th>
                                                        <th scope="col" className="border-0"> Description </th>
                                                        <th scope="col" className="border-0"> Start date </th>
                                                        <th scope="col" className="border-0"> end date </th>
                                                        <th scope="col" className="border-0"> Status </th>
                                                        <th scope="col" className="border-0"> Recharge status </th>
                                                        <th scope="col" className="border-0"> SMS status </th>
                                                        {/* <th scope="col" className="border-0">
                                                        Status
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="border-0"
                                                        style={{ textAlign: "center" }}
                                                    >
                                                        Action
                                                    </th> */}
                                                    </tr>
                                                </thead>
                                                {console.log(userList, "currenr")}
                                                <TelecomUserRechargeListRow
                                                    currentPosts={userList}
                                                    // sendSubject={sendSubject}
                                                    pageNo={currentPage}
                                                    title={" View Subject"}
                                                // userStatus={userStatus}
                                                // updateUser={updateUser}
                                                // ShowUsers={ShowUsers}
                                                />
                                            </table>
                                        </div>
                                        <PaginatedItems
                                            List={currentPosts}
                                            HandlePageClick={HandlePageClick}
                                        />
                                    </>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default TelecomUserRechargeList;