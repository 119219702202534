import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Container,

} from "shards-react";
import PageHeader from "../../components/PageHeader";
import PopUp from "../../components/Popup/PopUpGrade";
import Loader from '../../components/common/loader';
import Cookies from "js-cookie";
import api from "../../api";
import HandleError from "../../utils/HandleError";
import { FreetrailRequestlist } from "../../components/common/ListRow/FreetrailRequestlist";
import axios from "axios";
import PaginatedItems from "../../components/pagination";

const FreeTrail = () => {
    const [loading, setLoading] = useState()
    const [listData, setlistData] = useState([])
    const [currentPage, setcurrentPage] = useState(1)
    const [TotalReuest, setTotalReuest] = useState()
    const [PaymentStatus, setPaymentStatus] = useState()
    useEffect(() => {
        var token = JSON.parse(Cookies.get("auth"));
        axios({
            url: api.baseURL + "/list_free_trial",
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token
            },
            params: { page_no: currentPage }
        })
            .then(res => {
                console.log("res", res);
                if (res) {
                    setlistData(res.data.data.trials)
                    setTotalReuest(res.data.data.tot_free_trials)
                }
            })
            .catch(error => {
                console.log("res", error.response);
                HandleError(error);
            });
    }, [PaymentStatus, currentPage])


    const HandlePageClick = data => {
        setcurrentPage(data.selected + 1);
    };
    return (
        <>
            <Container fluid className="main-content-container px-4">
                <PageHeader
                    title={"Free Trial Request"}
                    subtitle={"MsingiPACK Cloud"}
                    name={"Add Notification"}
                    // onsubmit={addSubject}
                    Status={false}
                />
                <PopUp
                // title={"Add Notification"}
                // open={isopen}
                // hideModal={hideModal}
                // body={popUpbody}
                // callApi={callSubjectApi}
                // showButton={true}
                />
                <PopUp
                // title={"Update Subjects"}
                // open={open}
                // hideModal={hideModal}
                // body={popUpbody}
                // callApi={callSubjectUpdateApi}
                // showButton={true}
                />
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="border-bottom">
                                <h6 className="m-0"> Free Trial Request</h6>
                            </CardHeader>
                            <CardBody className="p-0 pb-3">
                                {loading === true ?
                                    <Loader />
                                    :
                                    <>
                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <thead className="bg-light">
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Message</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <FreetrailRequestlist
                                                    currentPosts={listData}
                                                    //sendSubject={sendSubject}
                                                    title={" View Subject"}
                                                    setPaymentStatus={setPaymentStatus} PaymentStatus={PaymentStatus}
                                                    pageNo={currentPage}
                                                />
                                            </table>
                                        </div>
                                        {TotalReuest > 10 &&
                                            <PaginatedItems
                                                List={TotalReuest}
                                                HandlePageClick={HandlePageClick}
                                            />}
                                    </>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default FreeTrail

