import { EventEmitter } from "events";
import Dispatcher from "./dispatcher";
import Constants from "./constants";
import getSidebarNavItems from "../data/sidebar-nav-items";

let _store = {
  menuVisible: false,
  navItems: getSidebarNavItems(),
  isAuth: false,
  Login_Data: {},
  UserList: {},
  TelecomUserList: {},
  AddTelecomUserList: {},
  SubjectList: {},
  TopicList: {},
  UnitList: {},
  GradeList: {},
  GradeCategory: {},
  UserDetails: {},
  showGrade: {},
  dashBoard_stats: {},
  listContactUs: {},
  subjectUpdate: {},
  subscriptionList: {},
  isForceLogout: false,
  listPlans: {},
  CMS_List: {},
  CMS_Store_List: {},
  CMS_Update_List: {},
  Notification_Count: {}
};

class Store extends EventEmitter {
  constructor() {
    super();

    this.registerToActions = this.registerToActions.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);

    Dispatcher.register(this.registerToActions.bind(this));
  }

  registerToActions({ type, payload }) {
    switch (type) {
      case Constants.TOGGLE_SIDEBAR:
        this.toggleSidebar();
        break;
      case Constants.LOGIN_SUCCESS:
        _store.Login_Data = payload;
        _store.isForceLogout = true;
        this.emit(Constants.CHANGE);
        break;
      case Constants.LOGIN_ERROR:
        _store.Login_Data = payload;
        this.emit(Constants.CHANGE);
        break;
      case Constants.USERLIST_SUCCESS:
        _store.UserList = payload;
        this.emit(Constants.CHANGE);
        break;
      case Constants.TELECOM_USERLIST_SUCCESS:
        _store.TelecomUserList = payload;
        this.emit(Constants.CHANGE);
        break;
      case Constants.ADD_TELECOM_USERLIST_SUCCESS:
        _store.AddTelecomUserList = payload;
        this.emit(Constants.CHANGE);
        break;
      case Constants.SUBJECTLIST_SUCCESS:
        _store.SubjectList = payload;
        this.emit(Constants.CHANGE);
        break;
      case Constants.TOPICLIST_SUCCESS:
        break;
      case Constants.UNITLIST_SUCCESS:
        break;
      case Constants.GRADELIST_SUCCESS:
        _store.GradeList = payload;
        this.emit(Constants.CHANGE);
        break;
      case Constants.GRADECATEGORIES_SUCCESS:
        _store.GradeCategory = payload;
        this.emit(Constants.CHANGE);
        break;
      case Constants.SHOWGRADE_SUCCESS:
        _store.showGrade = payload;
        this.emit(Constants.CHANGE);
        break;
      case Constants.USERDETAILS_SUCCESS:
        _store.UserDetails = payload;
        this.emit(Constants.CHANGE);
        break;
      case Constants.DASHBOARD_STATS_SUCCESS:
        _store.dashBoard_stats = payload;
        this.emit(Constants.CHANGE);
        break;
      case Constants.CONTACT_US_SUCCESS:
        console.log(payload, "pay");
        _store.listContactUs = payload;
        this.emit(Constants.CHANGE);
        break;
      case Constants.SUBJECT_UPDATE:
        console.log(payload, "subone");
        _store.subjectUpdate = payload;
        this.emit(Constants.CHANGE);
        break;
      case Constants.CLEAR_SUBJECT:
        _store.subjectUpdate = {}
        console.log("subone", _store.subjectUpdate);
        this.emit(Constants.CHANGE);
        break;
      case Constants.SUBSCRIPTIONLIST_SUCCESS:
        _store.subscriptionList = payload;
        this.emit(Constants.CHANGE);
        break;
      case Constants.FORCE_LOGOUT:
        _store.isForceLogout = false;
        this.emit(Constants.CHANGE);
        break;
      case Constants.PLANSLIST:
        _store.listPlans = payload;
        this.emit(Constants.CHANGE);
        break;
      case Constants.CMS_LIST:
        _store.CMS_List = payload;
        this.emit(Constants.CHANGE);
        break;
      case Constants.CMS_STORE_LIST:
        _store.CMS_Store_List = payload;
        this.emit(Constants.CHANGE);
        break;
      case Constants.CMS_UPDATE_LIST:
        _store.CMS_Update_List = payload;
        this.emit(Constants.CHANGE);
        break;
      case Constants.NOTIFICATION_UPDATE:
        _store.Notification_Count = payload;
        this.emit(Constants.CHANGE);
        break;
      default:
    }
  }

  toggleSidebar() {
    _store.menuVisible = !_store.menuVisible;
    this.emit(Constants.CHANGE);
  }
  getMenuState() {
    return _store.menuVisible;
  }
  getSidebarItems() {
    return _store.navItems;
  }
  addLogin = () => {
    return _store.Login_Data;
  };
  userList = () => {
    return _store.UserList;
  };
  TelecomUserList = () => {
    return _store.TelecomUserList;
  };
  AddTelecomUserList = () => {
    return _store.AddTelecomUserList;
  };
  subjectList = () => {
    return _store.SubjectList;
  };
  topicList = () => {
    return _store.TopicList;
  };
  unitList = () => {
    return _store.UnitList;
  };
  gradeList = () => {
    return _store.GradeList;
  };
  gradeCategory = () => {
    return _store.GradeCategory;
  };
  UserDetails = () => {
    return _store.UserDetails;
  };
  ShowGrade = () => {
    return _store.showGrade;
  };
  DashBoard_Stats = () => {
    return _store.dashBoard_stats;
  };
  ListContactUs = () => {
    return _store.listContactUs;
  };
  subjectUpdate = () => {
    return _store.subjectUpdate;
  };
  SubscriptionList = () => {
    return _store.subscriptionList;
  };
  Force_Logout = () => {
    return _store.isForceLogout;
  };
  ListPlans = () => {
    return _store.listPlans;
  };
  CMS_Lists = () => {
    return _store.CMS_List;
  };
  CMS_Store_Lists = () => {
    return _store.CMS_Store_List;
  };
  CMS_Update_Lists = () => {
    return _store.CMS_Update_List;
  };
  Notification_Count = () => {
    return _store.Notification_Count;
  };
  addChangeListener(callback) {
    this.on(Constants.CHANGE, callback);
  }
  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE, callback);
  }
}

export default new Store();
