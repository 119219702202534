import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Form,
    FormInput,
    Button
} from "shards-react";
import { Link } from "react-router-dom";
import { authAction } from "../flux/Action/AuthAction";
import { Store } from "../flux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Api from "../api";
function ResetPassword() {
    const { id } = useParams();
    const [UserDetails, setUserDetails] = useState({
        token: id,
        password: "",
        confirm_password: ""
    });
    const [loading, setLoading] = useState(false);
    const [LoginRes, setLoginRes] = useState({});
    const navigate = useHistory();
    useEffect(() => {
        console.log("heelo");
        console.log("heeelo", LoginRes);
        Store.addChangeListener(onChange);
        if (LoginRes && LoginRes.status === 201) {
            toast.success(LoginRes.data.message);
            setTimeout(() => navigate.push("/dashboard"), 1000);
        } else {
            setLoading(false);
        }
    }, [LoginRes]);


    function onChange() {
        setLoginRes(Store.addLogin());
    }

    function handleSubmit(e) {
        let data = {}
        e.preventDefault();
        setLoading(true);
        setTimeout(() => setLoading(false), 2000);
        const config = {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(UserDetails)
        };

        fetch(Api.baseURL + `/reset-password`, config)
            .then((res) => res.json())
            .then((data) => {
                if (data.status === "success") {
                    navigate.push("/login")
                }
                if (data.status === "error") {
                    console.log(data.message);
                }
            })
            .catch((err) => {

            });
    }
    return (
        <Container fluid className="container h-100" style={{ marginTop: 100 }}>
            <Row className="row h-100 justify-content-center align-items-center">
                <Col lg="5" className="mb-4">
                    <Row className="justify-content-center align-items-center"></Row>
                    {/* Complete Form Example */}
                    <Card small className="py-4">
                        <Container>
                            <Row>
                                <Col>
                                    <Form onSubmit={handleSubmit}>
                                        <Row form>
                                            <Col className="form-group">
                                                <label htmlFor="feEmailAddress">Password</label>
                                                <FormInput
                                                    id="feEmailAddress"
                                                    type="text"
                                                    placeholder="password"
                                                    name="username"
                                                    onChange={(e) => {
                                                        setUserDetails({
                                                            ...UserDetails,
                                                            password: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col className="form-group">
                                                <label htmlFor="fePassword">Confirm Password</label>
                                                <FormInput
                                                    id="fePassword"
                                                    type="password"
                                                    placeholder="confirm_Password"
                                                    name="password"
                                                    onChange={(e) => {
                                                        setUserDetails({
                                                            ...UserDetails,
                                                            confirm_password: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col md="6">
                                                <Button type="submit" disabled={loading}>
                                                    Submit
                                                </Button>

                                            </Col>

                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </Container>
                    </Card>
                </Col>
            </Row>
            <ToastContainer />
        </Container>
    );
}
export default ResetPassword;
