const api = {
  baseURL: ""
};

if (process.env.REACT_APP_MODE === "development") {
  api.baseURL = "http://elearning-backend.arkasoftwares.in/api/v1/admin"
} else if (process.env.REACT_APP_MODE === "production") {
  api.baseURL = "https://api.msingipack.cloud/api/v1/admin"
}
export default api;
/*http://elearning-backend.arkasoftwares.in/  */
//http://192.168.2.80:3334/api/v1
//http://elearning-backend.arkasoftwares.in/api/v1/admin
//http://0.0.0.0:3334
//6Lceux0gAAAAANTpEUMt2wrol0yNvoNvPDGeK3fi
//6Lceux0gAAAAABciKCJm__F6FLiHKxHQYw3VuItX