import dispatcher from "../dispatcher";
import axios from "axios";
import Constants from "../constants";
import api from "../../api";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ContactAction = () => {
  var token = JSON.parse(Cookies.get("auth"));
  axios({
    url: api.baseURL + "/list_contact_us",
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    }
  })
    .then(
      res => (
        console.log(res.data, "pay"),
        dispatcher.dispatch({
          type: Constants.CONTACT_US_SUCCESS,
          payload: res
        })

      )
    )
    .catch(error => {
      if (error.response) {
        toast.error(error.response.data.message);
      }
    });
};