import dispatcher from "../dispatcher";
import axios from "axios";
import Constants from "../constants";
import api from "../../api";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
import HandleError from "../../utils/HandleError";
import * as process from "process";
import { toast } from "react-toastify";

export const NotificationAction = formData => {
    console.log(api.baseURL, "base");
    var token = JSON.parse(Cookies.get("auth"));
    console.log("auth");
    axios({
        url: api.baseURL + `/bulknotification_list`,
        method: "GET",
        headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "BEARER " + token.data.token
        },
        params: { page_no: formData }
    })
        .then(res =>
            dispatcher.dispatch({
                type: Constants.SUBJECTLIST_SUCCESS,
                payload: res
            })
        )
        .catch(error => {
            console.log("res", error.response);
            HandleError(error);
        });
};

export const storeNotificationAction = (title, message, user) => {
    // let formData = {
    //     title: NotificationTitle,
    //     message: ContentUrl
    // }
    // console.log(formData, "data");
    var token = JSON.parse(Cookies.get("auth"));
    axios({
        url: api.baseURL + "/notification",
        method: "POST",
        headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "BEARER " + token.data.token
        },
        data: JSON.stringify({ "title": title, "message": message, "users": user })
    })
        .then(
            res => (
                console.log(res.data.message, "res"),
                toast.success(res.data.message),
                dispatcher.dispatch({
                    type: Constants.SUBJECT_UPDATE,
                    payload: res
                })
            )
        )
        .catch(error => {
            console.log("res", error.response);
            HandleError(error);
        });
};

export const NotificationCounts = () => {
    var token = JSON.parse(Cookies.get("auth"));
    axios({
        url: api.baseURL + "/admin_notification_count",
        method: "GET",
        headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "BEARER " + token.data.token
        }
    })
        .then(res => {
            if (res.data.status == "success") {
                dispatcher.dispatch({
                    type: Constants.NOTIFICATION_UPDATE,
                    payload: res
                })
            }
        })
        .catch(error => {
            HandleError(error);
        });
}