import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, Alert } from "shards-react";
import PageHeader from "../components/PageHeader";
import PaginatedItems from "../components/pagination";
import Cookies from "js-cookie";
import LoginLogsList from "../components/common/LoginLogs/Loginlogs";
import api from "../api";
const LoginLogs = () => {
    const [SubscriptionList, setSubscriptionList] = useState([])
    const [currentPage, setcurrentPage] = useState(1);
    const [PaymentStatus, setPaymentStatus] = useState()
    const [tot_subs, settotsubs] = useState()
    useEffect(() => {

        var token = JSON.parse(Cookies.get("auth"));
        const config = {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token
            },
            params: { "page_no": currentPage }
        };
        fetch(api.baseURL + `/list_login_logs/?page_no=${currentPage}`, config)
            .then((res) => res.json())
            .then((data) => {
                setSubscriptionList(data.data)
                settotsubs(data.data.tot_logs)
            })
            .catch((err) => {
                if (err) {
                    console.log("this is err", err);
                }
            });
    }, [PaymentStatus, currentPage]);

    const HandlePageClick = data => {
        setcurrentPage(data.selected + 1);
    };

    return (
        <>
            <Container fluid className="main-content-container px-4">
                <PageHeader
                    title={"Session Logs"}
                    subtitle={"MsingiPACK Cloud"}
                    name={"Add users"}
                    Status={false}
                />
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="border-bottom">
                                <h6 className="m-0">Session Logs</h6>
                            </CardHeader>
                            <CardBody className="p-0 pb-3">
                                <div className="table-responsive">
                                    <table className="table mb-0">
                                        <thead className="bg-light">
                                            <tr>
                                                <th scope="col" className="border-0">
                                                    S.No
                                                </th>
                                                <th scope="col" className="border-0">
                                                    Name
                                                </th>
                                                <th scope="col" className="border-0">
                                                    Email
                                                </th>
                                                <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                                                    Login Time
                                                </th>
                                                <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                                                    Logout Time
                                                </th>
                                            </tr>
                                        </thead>
                                        <LoginLogsList SubscriptionList={SubscriptionList} setPaymentStatus={setPaymentStatus} PaymentStatus={PaymentStatus} pageNo={currentPage} />

                                    </table>
                                </div>
                                {tot_subs > 10 &&
                                    < PaginatedItems
                                        List={tot_subs}
                                        HandlePageClick={HandlePageClick}
                                    />}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default LoginLogs;
