import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

// routes config
import routes from "../routes";
import withTracker from "../withTracker";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const TheContent = () => {
  console.log("hello");
  return (
    <main className="main-content">
      <Suspense fallback={loading}>
        <Switch>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={withTracker(props => {
                  return (
                    <>
                      {console.log(props, "props")}
                      <route.layout {...props}>
                        <route.component {...props} />
                      </route.layout>
                    </>
                  );
                })}
              />
            );
          })}
        </Switch>
      </Suspense>
    </main>
  );
};

export default TheContent;
