import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import api from "../../../api";
import PopUp from "../../Popup/PopUpGrade";
import axios from "axios";
import HandleError from "../../../utils/HandleError";
export const Upgradeuserlist = ({
    currentPosts,
    pageNo,
    open,
    setOpen,
    setPaymentStatus,
    PaymentStatus
}) => {
    const [isPopUpOpen, setisPopUpOpen] = useState(false);
    const [UserId, setUserId] = useState()

    const body2 = () => {
        return (
            <>
                <div>Do you want to accept Upgrade User request?</div>
            </>
        );
    };
    const popChangebody = body2();

    const openPopup = (id) => {
        setUserId(id)
        setisPopUpOpen(!isPopUpOpen);
    };

    const HideModals = () => {
        setisPopUpOpen(!isPopUpOpen);
    };

    const callApi = () => {
        const data = { "subscription_id": UserId }
        var token = JSON.parse(Cookies.get("auth"));
        axios({
            url: api.baseURL + "/toggle_payment_status",
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token
            },
            data: data
        })
            .then(res => {
                if (res.data.status === "success") {
                    setPaymentStatus(!PaymentStatus)
                    setisPopUpOpen(!isPopUpOpen);
                }
            })
            .catch(error => {
                HandleError(error);
            });
    }
    const renderRow = (Obj, i) => {
        return (
            <>
                <tr key={i}>
                    <td scope="col" style={{ textAlign: 'center' }}>{i + 1 + (pageNo - 1) * 10} </td>
                    <td scope="col"  >
                        {Obj.user_name}
                    </td>
                    <td scope="col" style={{ textAlign: 'center' }} >
                        {Obj.invoice_id}
                    </td>
                    <td scope="col" style={{ textAlign: 'center' }} >
                        {Obj.email}
                    </td>
                    <td scope="col" style={{ textAlign: 'center' }}  >
                        {Obj.tot_users}
                    </td>
                    <td scope="col" style={{ textAlign: 'center' }} >
                        {Obj.plan_tenure}
                    </td>
                    <td scope="col" style={{ textAlign: 'center' }} >
                        {Obj.plan_name}
                    </td>
                    <td scope="col" style={{ textAlign: 'center' }}>
                        {Obj.total}
                    </td>
                    <td scope="col" style={{ textAlign: 'center' }} >
                        {Obj.apply_date}
                    </td>
                    {Obj.is_payment_done == "Unpaid" ? <td scope="col" style={{ textAlign: 'center' }}>
                        <span className="badge bg-warning" style={{ "cursor": "pointer" }} onClick={() => openPopup(Obj.id)}> {Obj.is_payment_done}</span>
                    </td> :
                        <td scope="col" style={{ textAlign: 'center' }}>
                            <span className="badge bg-success"> {Obj.is_payment_done}</span>
                        </td>
                    }
                    <td scope="col" style={{ textAlign: 'center' }} >
                        <button type="button" className="action-btn btn btn-danger">
                            <a
                                href={api.baseURL + `/download_invoice/${Obj.id}`}
                                theme="info"
                                style={{ color: "white" }}
                                title="Download Invoice"
                                target="_blank">
                                <span className="material-icons">file_download</span>
                            </a>
                        </button>
                    </td>
                </tr>
            </>);
    };
    const tableBody = currentPosts.length && currentPosts.map(renderRow);

    return (
        <>
            <tbody>
                <>    <PopUp
                    title="Accept Request"
                    open={isPopUpOpen}
                    hideModal={HideModals}
                    body={popChangebody}
                    showButton={true}
                    callApi={callApi}
                /></>
                {
                    tableBody.length !== undefined ?
                        tableBody
                        : <tr>
                            <td colSpan="15" className="text-center noRec-found"> No record found</td>
                        </tr>
                }
            </tbody>
        </>
    );
};
