import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "shards-react";

export const SubjectListRow = ({
    currentPosts,
    sendSubject,
    title,
    deleteSubject,
    updateSubject,
    pageNo
}) => {
    console.log("subone", currentPosts);
    let history = useHistory();
    // view content button
    const sendGrade = (content_id) => {
        history.push({
            pathname: `/topics/` + content_id,
        });
    };
    const renderRow = (Obj, i) => {
        return (
            <tr key={i}>
                <td>{i + 1 + (pageNo - 1) * 10}</td>
                <td>{Obj.grade_name}</td>
                <td>{Obj.subject_name}</td>
                <td><img src={Obj.icon} alt="" style={{ maxWidth: "50px" }} /></td>
                <td align="right">
                    <Button
                        className="action-btn"
                        title="View"
                        onClick={() => sendGrade(Obj.id)}
                    >
                        <i class="material-icons">
                            visibility
                        </i>
                    </Button>
                    &nbsp;
                    <Button
                        className="action-btn"
                        title="Delete"
                        onClick={() => {
                            deleteSubject(Obj.id);
                        }}
                        theme="danger"
                    >
                        <i class="material-icons">
                            delete
                        </i>
                    </Button>
                    &nbsp;
                    <Button
                        className="action-btn"
                        theme="info"
                        title="Edit"
                        onClick={() => {
                            updateSubject(Obj.subject_name, Obj.id, Obj.content_url);
                        }}
                    >
                        {" "}
                        <i class="material-icons">
                            edit
                        </i>
                    </Button>
                </td>
            </tr>
        );
    };
    const tableBody = currentPosts && currentPosts.length && currentPosts.map(renderRow);
    return (
        <>
            <tbody>
                {tableBody && tableBody.length !== undefined ?
                    tableBody
                    : <tr>
                        <td colSpan="15" className="text-center noRec-found"> No record found</td>
                    </tr>}
            </tbody>
        </>
    );
};
