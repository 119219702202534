import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import api from "../../api";
import Cookies from "js-cookie";
import {
  Card,
  CardHeader,
  CardBody,
} from "shards-react";
import { useHistory } from "react-router-dom";

const TopReferrals = ({ title }) => {
  const [SubscriptionList, setSubscriptionList] = useState()
  const [tot_subs, settotsubs] = useState()

  let history = useHistory()
  useEffect(() => {

    var token = JSON.parse(Cookies.get("auth"));
    const config = {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "BEARER " + token.data.token
      },
      params: {
        "page_no": 1, "sub_type": "approved"
      }
    };
    fetch(api.baseURL + `/list_subscriptions/?page_no=${1}&sub_type=approved`, config)
      .then((res) => res.json())
      .then((data) => {
        setSubscriptionList(data.data.subs)
        settotsubs(data.data.tot_subs)
      })
      .catch((err) => {
        if (err) {
          console.log("this is err", err);
        }
      });
  }, []);
  return (
    <Card small style={{ maxHeight: "500px" }} >
      <CardHeader className="border-bottom ">
        <h6 className="m-0">{title}</h6>
        <div className="block-handle" />
      </CardHeader>
      <CardBody className="p-0 " style={{ overflowY: "scroll" }}>
        <div className="table-responsive">
          <table className="table mb-0" >
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0">
                  Email
                </th>
                <th scope="col" className="border-0">
                  Price
                </th>
                <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {SubscriptionList !== undefined && SubscriptionList.map((item, idx) => {
                return (
                  <tr>
                    <th scope="col" className="border-0">
                      {item.email}
                    </th>
                    <th scope="col" className="border-0">
                      {item.total && item.total.toLocaleString()}
                    </th>
                    <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                      <button type="button" className="action-btn btn btn-danger">
                        <a
                          href={api.baseURL + `/download_invoice/${item.id}`}
                          theme="info"
                          style={{ color: "white" }}
                          title="Download Invoice"
                          target="_blank"
                        >
                          <span className="material-icons">file_download</span>
                        </a>
                      </button>
                    </th>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
}
TopReferrals.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The referral data.
   */
  referralData: PropTypes.array
};

TopReferrals.defaultProps = {
  title: "Recent Subscription",
  referralData: [
    {
      title: "GitHub",
      value: "19,291"
    },
    {
      title: "Stack Overflow",
      value: "11,201"
    },
    {
      title: "Hacker News",
      value: "9,291"
    },
    {
      title: "Reddit",
      value: "8,281"
    },
    {
      title: "The Next Web",
      value: "7,128"
    },
    {
      title: "Tech Crunch",
      value: "6,218"
    },
    {
      title: "YouTube",
      value: "1,218"
    },
    {
      title: "Adobe",
      value: "1,171"
    }
  ]
}


export default TopReferrals;
