import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, FormSelect } from "shards-react";
import PageHeader from "../../components/PageHeader";
import PaginatedItems from "../../components/pagination";
import Cookies from "js-cookie";
import api from "../../api";
import SubscriptionsList from "../../components/common/Subscription/subscriptionList";
const Subscritions = () => {
    const [SubscriptionList, setSubscriptionList] = useState(0)
    const [currentPage, setcurrentPage] = useState(1);
    const [PaymentStatus, setPaymentStatus] = useState()
    const [tot_subs, settotsubs] = useState()
    // ACTIVE/ INACTIVE STATUS DROPDOWN
    const [dropdown, setdropDown] = useState()
    useEffect(() => {
        console.log("MOUNT",);

        return () => console.log("UNMOUNT",)
    }, []);


    useEffect(() => {

        var token = JSON.parse(Cookies.get("auth"));
        const config = {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token
            },
            params: { "page_no": currentPage, "sub_type": dropdown }
        };
        fetch(api.baseURL + `/list_subscriptions/?page_no=${currentPage}&&sub_type=${dropdown}`, config)
            .then((res) => res.json())
            .then((data) => {
                setSubscriptionList(data.data)
                settotsubs(data.data.tot_subs)
            })
            .catch((err) => {
                if (err) {
                    console.log("this is err", err);
                }
            });
    }, [PaymentStatus, currentPage, dropdown]);

    const HandlePageClick = data => {
        setcurrentPage(data.selected + 1);
    };

    return (
        <>
            <Container fluid className="main-content-container px-4 ">
                <PageHeader
                    title={"Subscription"}
                    subtitle={"MsingiPACK Cloud"}
                    name={"Add users"}
                    Status={false}
                />
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="border-bottom d-flex justify-content-between">
                                <h6 className="m-0">Subscription</h6>
                                <div className="subscription-select-bx">
                                    <label>Filter:</label>
                                    <FormSelect onChange={e => {
                                        setdropDown(e.target.value);
                                    }}>
                                        <option value="" >
                                            All
                                        </option>
                                        <option value="approved">Paid</option>
                                        <option value="pending">Unpaid</option>

                                    </FormSelect>
                                </div>
                            </CardHeader>
                            <CardBody className="p-0 pb-3">
                                <div className="table-responsive">
                                    <table className="table mb-0">
                                        <thead className="bg-light">
                                            <tr>
                                                <th scope="col" className="border-0">
                                                    S.No
                                                </th>
                                                <th scope="col" className="border-0">
                                                    User Name
                                                </th>
                                                <th scope="col" className="border-0">
                                                    Invoice Id
                                                </th>
                                                <th scope="col" className="border-0">
                                                    Email
                                                </th>
                                                <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                                                    Total User
                                                </th>
                                                <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                                                    Plan Tenure
                                                </th>
                                                <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                                                    Plan Name
                                                </th>
                                                <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                                                    Amount
                                                </th>
                                                <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                                                    Apply Date
                                                </th>
                                                <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                                                    Start Date
                                                </th>
                                                <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                                                    End Date
                                                </th>
                                                <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                                                    Payment Status
                                                </th>
                                                <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                                                    Action
                                                </th>

                                            </tr>
                                        </thead>
                                        <SubscriptionsList SubscriptionList={SubscriptionList} setPaymentStatus={setPaymentStatus} PaymentStatus={PaymentStatus} pageNo={currentPage} />
                                    </table>
                                </div>
                                {tot_subs > 10 &&
                                    <PaginatedItems
                                        List={tot_subs}
                                        HandlePageClick={HandlePageClick}
                                    />}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Subscritions;
