import ReactPaginate from "react-paginate";
import React from "react";
function Pagination({ List, HandlePageClick }) {
  // console.log(HandlePageClick);
  let limit;
  if (List > 0) {
    limit = Math.ceil(List / 10);
  }
  return (
    <>
      <ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        pageCount={limit}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={HandlePageClick}
        containerClassName={"pagination justify-content-center"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
      />
    </>
  );
}

export default Pagination;
