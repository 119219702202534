import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import PageHeader from "../../components/PageHeader";
import { CMSListRow } from "../../components/common/ListRow/CMSListRow";
const CMSlist = () => {
  return (
    <>
      <Container fluid className="main-content-container px-4">
        <PageHeader
          title={"CMS"}
          subtitle={"MsingiPACK Cloud"}
          name={"Add users"}
          Status={false}
        />
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">CMS</h6>
              </CardHeader>
              {/* cmslist component */}
              <CMSListRow />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CMSlist;
