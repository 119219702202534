import dispatcher from "../dispatcher";
import axios from "axios";
import Constants from "../constants";
import api from "../../api";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HandleError from "../../utils/HandleError";

export const CMSListAction = async data => {
  var token = JSON.parse(Cookies.get("auth"));
  axios({
    url: api.baseURL + "/content",
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    },
    data: data
  })
    .then(
      res => (
        console.log("res", res),
        dispatcher.dispatch({
          type: Constants.CMS_LIST,
          payload: res
        })
      )
    )
    .catch(error => {
      console.log("hell", error.response);
      HandleError(error);
    });
};

export const CMSStoreAction = async id => {
  var token = JSON.parse(Cookies.get("auth"));
  axios({
    url: api.baseURL + `/content/${id}`,
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    }
  })
    .then(
      res => (
        console.log("res", res),
        dispatcher.dispatch({
          type: Constants.CMS_STORE_LIST,
          payload: res
        })
      )
    )
    .catch(error => {
      console.log("hell", error.response);
      HandleError(error);
    });
};

export const CMSUpdateAction = async value => {
  var token = JSON.parse(Cookies.get("auth"));
  axios({
    url: api.baseURL + `/content/${value.id}`,
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    },
    data: JSON.stringify(value)
  })
    .then(
      res => (
        toast.success(res.data.message),
        dispatcher.dispatch({
          type: Constants.CMS_UPDATE_LIST,
          payload: res
        })
      )
    )
    .catch(error => {
      console.log("hell", error.response);
      HandleError(error);
    });
};
