import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Container,
    FormInput,
} from "shards-react";
import PageHeader from "../../components/PageHeader";
import PopUp from "../../components/Popup/PopUpGrade";
import Loader from '../../components/common/loader';
import Cookies from "js-cookie";
import api from "../../api";
import HandleError from "../../utils/HandleError";
import { FreeTrailListRowurlcopy } from "../../components/common/ListRow/FreeTrailListRowurl copy";
import axios from "axios";
import { toast } from "react-toastify";

const FreeTrailurlcopy = () => {
    const [loading, setLoading] = useState(true)
    const [listData, setlistData] = useState()
    const [showReloadButtons, setshowReloadButtons] = useState(false)
    const [updateTopic, setUpdateTopic] = useState(false)

    // for reload list
    const [ReloadList, setReloadList] = useState(false)

    // add content  popup
    const [openPopup, setopenPopup] = useState(false)
    const [contentData, setContentData] = useState()
    const [error, setError] = useState("")
    // const [currentPage, setcurrentPage] = useState(1)
    // const [TotalReuest, setTotalReuest] = useState()
    // const [PaymentStatus, setPaymentStatus] = useState()
    // // popup for add free trails content
    // const [loadingMsg, setloadingMsg] = useState(false)
    // //state for show/hide "Add Free Trial Content" button 
    // const [showButton, setshowButton] = useState(true)
    useEffect(() => {
        setshowReloadButtons(true)
        var token = JSON.parse(Cookies.get("auth"));
        axios({
            url: api.baseURL + "/free_trial",
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token
            }
        })
            .then(res => {
                if (res) {
                    setshowReloadButtons(false)
                    setlistData(res.data.data)
                    setLoading(false)
                }
            })
            .catch(error => {
                console.log("res", error.response);
                HandleError(error);
            });
    }, [ReloadList])

    // add free trail content
    const addContent = () => {
        setopenPopup(true)
    }

    const hideModal = () => {
        setopenPopup(false)
        setError("")
    }

    const body1 = () => {
        return (
            <Container>
                <>
                    <p size="sm" className="mb-1 text-danger" value={error}>{error}</p>
                    <FormInput
                        size="sm"
                        type="text"
                        placeholder="Content Name"
                        className="mb-2"
                        onChange={e => {
                            setContentData({ ...contentData, "subjectName": e.target.value });
                        }}
                    />
                    <FormInput type="file" accept=".zip" onChange={(e) => {
                        setContentData({ ...contentData, "Pdf": e.target.files[0] });
                        // setpdfValidate(false)
                    }} />
                </>
            </Container>
        );
    };
    const AddContentpopUpbody = openPopup && body1();

    const AddContentApi = () => {
        if (contentData && contentData.subjectName && contentData.Pdf) {
            let formData = new FormData();
            formData.append("title", contentData.subjectName);
            setError("")
            setshowReloadButtons(true)
            setopenPopup(false)
            var token = JSON.parse(Cookies.get("auth"));
            axios({
                url: api.baseURL + `/store_free_trial_first`,
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Accept: "application/json",
                    Authorization: "BEARER " + token.data.token
                },
                data: formData
            })
                .then(res => {
                    if (res.data.status === "success") {
                        console.log("nvksjdghsdfgg", res.data);
                        setlistData(res.data.data)
                        sendContent(res.data.freeTrialContentId)
                    }
                })
                .catch(error => {
                    setshowReloadButtons(false)
                    HandleError(error);
                });
        }
        else {
            setError("All fields are required*")
        }
    }

    const sendContent = (id) => {
        let formData = new FormData();
        formData.append("content", contentData.Pdf);
        var token = JSON.parse(Cookies.get("auth"));
        axios({
            url: api.baseURL + `/store_free_trial_next/${id}`,
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token
            },
            data: formData
        })
            .then(res => {
                if (res.data.status === "success") {
                    console.log("fsfsdsjdlkfjsdfklds", res.data.status);
                    setshowReloadButtons(false)
                    setReloadList(!ReloadList)
                    toast.success(res.data.message)
                }
            })
            .catch(error => {
                setshowReloadButtons(false)
                HandleError(error);
            });
    }
    return (
        <>
            <Container fluid className="main-content-container px-4">
                <PageHeader
                    title={"Free Trial Content"}
                    subtitle={"MsingiPACK Cloud"}
                    name={"Add Free Trial Content"}
                    onsubmit={addContent}
                    Status={true}
                />
                <PopUp
                    title={"Add Free Trial Content"}
                    open={openPopup}
                    hideModal={hideModal}
                    body={AddContentpopUpbody}
                    callApi={AddContentApi}
                    showButton={true}
                />
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="border-bottom d-flex justify-content-between">
                                <h6 className="m-0">Free Trial Content</h6>
                                <h6 className="m-0" style={{ fontSize: "30px" }}>
                                    <i onClick={() => {
                                        setshowReloadButtons(!showReloadButtons)
                                        setReloadList(!ReloadList)
                                    }} className={"material-icons " + (showReloadButtons ? "m-spin" : "")}>refresh</i>
                                </h6>
                            </CardHeader>
                            <CardBody className="p-0 pb-3">
                                {loading === true ?
                                    <Loader />
                                    :
                                    <>
                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <thead className="bg-light">
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th style={{ textAlign: "center" }}>Title</th>
                                                        {/* <th style={{ textAlign: "center" }}>Content Url</th> */}
                                                        <th style={{ textAlign: "center" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <FreeTrailListRowurlcopy
                                                    listData={listData}
                                                    setReloadList={setReloadList}
                                                    ReloadList={ReloadList}
                                                    setshowReloadButtons={setshowReloadButtons}
                                                    setUpdateTopic={setUpdateTopic}
                                                    updateTopic={updateTopic}
                                                //sendSubject={sendSubject}
                                                // title={" View Subject"}
                                                // setPaymentStatus={setPaymentStatus} PaymentStatus={PaymentStatus}
                                                // pageNo={currentPage}
                                                // setshowButtons={setshowButtons}
                                                />
                                            </table>
                                        </div>
                                    </>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default FreeTrailurlcopy

