import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { OtherUserListRow } from "../../components/common/ListRow/OtherUserListRow";
import PaginatedItems from "../../components/pagination";
import Loader from '../../components/common/loader';
import HandleError from "../../utils/HandleError";
import api from "../../api";
import Cookies from "js-cookie";
import axios from "axios";
const UsersDetail = () => {
    let { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [userList, setUserList] = useState([]);
    const [currentPosts, setcurrentPosts] = useState();
    const [currentPage, setcurrentPage] = useState(1);

    useEffect(() => {
        var token = JSON.parse(Cookies.get("auth"));
        let Data = { "page_no": currentPage, "user_id": parseInt(id) }
        axios({
            url: api.baseURL + `/users/other_users?page_no=${currentPage}&user_id=${parseInt(id)}`,
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token
            },
        })
            .then(
                res => (
                    // console.log(res)
                    setUserList(res.data.data.users),
                    setcurrentPosts(res.data.data.tot_users),
                    setLoading(false)
                )
            )
            .catch(error => {
                console.log("res", error.response);
                HandleError(error);
            });
    }, [currentPage]);


    const HandlePageClick = data => {
        setcurrentPage(data.selected + 1);
    };


    return (
        <>
            <Container fluid className="main-content-container px-4">
                <PageHeader
                    title={"Users"}
                    subtitle={"MsingiPACK"}
                    name={"Add users"}
                    Status={false}
                />
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="border-bottom">
                                <h6 className="m-0">Users</h6>
                            </CardHeader>
                            <CardBody className="p-0 pb-3">
                                {loading === true ?
                                    <Loader />
                                    :
                                    <>
                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <thead className="bg-light">
                                                    <tr>
                                                        <th scope="col" className="border-0"> S.No </th>
                                                        <th scope="col" className="border-0">
                                                            Name
                                                        </th>
                                                        <th scope="col" className="border-0">
                                                            Email
                                                        </th>

                                                        <th scope="col" className="border-0">
                                                            Phone
                                                        </th>
                                                        {/* <th scope="col" className="border-0">
                                                        Status
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="border-0"
                                                        style={{ textAlign: "center" }}
                                                    >
                                                        Action
                                                    </th> */}
                                                    </tr>
                                                </thead>
                                                {console.log(userList, "currenr")}
                                                <OtherUserListRow
                                                    currentPosts={userList}
                                                    // sendSubject={sendSubject}
                                                    pageNo={currentPage}
                                                    title={" View Subject"}
                                                // userStatus={userStatus}
                                                // updateUser={updateUser}
                                                // ShowUsers={ShowUsers}
                                                />
                                            </table>
                                        </div>
                                        <PaginatedItems
                                            List={currentPosts}
                                            HandlePageClick={HandlePageClick}
                                        />
                                    </>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default UsersDetail;