import axios from "axios";
import Cookies from "js-cookie";
import React from "react";
import { Button, ButtonGroup } from "shards-react";
import api from "../../../api";
import HandleError from "../../../utils/HandleError";

function GradeListRow({
    currentPosts,
    setcurrentPosts,
    sendGrade,
    title,
    deleteGrade,
    updateGrade,
    pageNo

}) {



    let sourceElement = null

    /* change opacity for the dragged item. 
    remember the source item for the drop later */
    const handleDragStart = (event) => {
        event.target.style.opacity = 0.5
        sourceElement = event.target
        event.dataTransfer.effectAllowed = 'move'
    }

    /* do not trigger default event of item while passing (e.g. a link) */
    const handleDragOver = (event) => {
        event.preventDefault()
        event.dataTransfer.dropEffect = 'move'
    }

    /* add class .over while hovering other items */
    const handleDragEnter = (event) => {
        event.target.classList.add('over')
    }

    /* remove class .over when not hovering over an item anymore*/
    const handleDragLeave = (event) => {
        event.target.classList.remove('over')
    }

    const handleDrop = (event) => {
        /* prevent redirect in some browsers*/
        event.stopPropagation()

        /* only do something if the dropped on item is 
        different to the dragged item*/
        console.log("firstChildfirstChildfirstChildfirstChild", event.target.firstChild);
        if (sourceElement !== event.target) {
            /* remove dragged item from list */
            const list = currentPosts.filter((item, i) =>
                i.toString() !== sourceElement.id)

            /* this is the removed item */
            const removed = currentPosts.filter((item, i) =>
                i.toString() === sourceElement.id)[0]

            /* insert removed item after this number. */
            let insertAt = Number(event.target.parentNode.id)

            let tempList = []

            /* if dropped at last item, don't increase target id by +1. 
               max-index is arr.length */
            if (insertAt >= list.length) {

                tempList = list.slice(0).concat(removed)
                setcurrentPosts(tempList)
                event.target.classList.remove('over')
            } else if ((insertAt < list.length)) {

                /* original list without removed item until the index it was removed at */
                const newList = list.slice(0, insertAt).concat(removed)
                // console.log('nothing happened', tempList)
                // console.log('tempList', tempList)
                console.log('insert the rest: ', list.slice(insertAt))

                /* add the remaining items to the list */
                tempList = newList.concat(list.slice(insertAt))

                /* set state to display on page */
                console.log('tempListtempListtempListtempListtempList', tempList)
                setcurrentPosts(tempList)
                event.target.classList.remove('over')
            }
            sendUpdatedList(tempList);
        }
        else console.log('nothing happened')
        event.target.classList.remove('over')
    }

    const handleDragEnd = (event) => {
        event.target.style.opacity = 1
        console.log('-------------------------------------------------------------')
    }

    const sendUpdatedList = async (tempList) => {
        let data = await tempList.map(function (ele) {
            return ele.id
        });
        console.log("datadatadatadatadata", data);
        var token = JSON.parse(Cookies.get("auth"));
        axios({
            url: api.baseURL + `/grade_arrange_order`,
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token
            },
            data: JSON.stringify({ "arranged_grade_ids": data })
        })
            .then(res => {
                if (res) {

                }
            })
            .catch(error => {
                console.log("res", error.response);
                HandleError(error);
            });
    }


    /* create list of items */
    const listItems = () => {
        console.log("i m listdata", currentPosts);
        return currentPosts.map((Obj, i) => (

            <tr id={i}
                className='dnd-list'
                draggable='true'
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onDragEnd={handleDragEnd}>
                <td id={i}>{i + 1 + (pageNo - 1) * 10}</td>
                <td>{Obj.grade_category_name}</td>
                <td>{Obj.grade_name}</td>
                <td><img src={Obj.icon} alt="" style={{ maxWidth: "50px" }} /></td>  <td style={{ textAlign: "center" }}>

                    <Button className="action-btn" title="View" onClick={() => sendGrade(Obj.id, Obj.grade_category_name)}>
                        {" "}
                        <i class="material-icons">
                            visibility
                        </i>
                    </Button>
                    &nbsp;
                    <Button className="action-btn"
                        theme="info"
                        title="Edit"
                        onClick={() => {
                            updateGrade(Obj.id);
                        }}
                    >
                        <i class="material-icons">
                            edit
                        </i>
                    </Button>
                    &nbsp;
                    <Button className="action-btn" title="Delete" theme="danger" onClick={() => deleteGrade(Obj.id)}>
                        <i class="material-icons">
                            delete
                        </i>
                    </Button>
                </td>
            </tr>

        )
        )
    }

    const renderRow = (Obj, i) => {
        console.log("123456789", Obj);
        return (
            <tr key={i}>
                <td>{i + 1 + (pageNo - 1) * 10}</td>
                <td>{Obj.grade_category_name}</td>
                <td>{Obj.grade_name}</td>
                <td><img src={Obj.icon} alt="" style={{ maxWidth: "50px" }} /></td>
                <td style={{ textAlign: "center" }}>

                    <Button className="action-btn" title="View" onClick={() => sendGrade(Obj.id, Obj.grade_category_name)}>
                        {" "}
                        <i class="material-icons">
                            visibility
                        </i>
                    </Button>
                    &nbsp;
                    <Button className="action-btn"
                        theme="info"
                        title="Edit"
                        onClick={() => {
                            updateGrade(Obj.id);
                        }}
                    >
                        <i class="material-icons">
                            edit
                        </i>
                    </Button>
                    &nbsp;
                    <Button className="action-btn" title="Delete" theme="danger" onClick={() => deleteGrade(Obj.id)}>
                        <i class="material-icons">
                            delete
                        </i>
                    </Button>
                </td>
            </tr>
        );
    };
    return (
        <tbody>
            {listItems ?
                listItems()
                : <tr>

                    <td colSpan="15" className="text-center noRec-found"> No record found</td>
                </tr>}
        </tbody>
    );
}
export default GradeListRow;
