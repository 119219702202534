import React, { useEffect, useState } from "react";
import {
    Container, Row, Col, Card, CardHeader, CardBody, FormSelect
} from "shards-react";
import PageHeader from "../../components/PageHeader";
import { Store } from "../../flux";
import { UserListRow } from "../../components/common/ListRow/ListRow";
import PaginatedItems from "../../components/pagination";
import Loader from '../../components/common/loader';
import { useHistory } from "react-router-dom";
import {
    UserStatusAction,
    UserUpdateAction,
    usersAction
} from "../../flux/Action/UsersAction";
import Cookies from "js-cookie";
import axios from "axios";
import HandleError from "../../utils/HandleError";
import api from "../../api";
import { toast } from "react-toastify";
import PopUp from "../../components/Popup/PopUpGrade";
const SubjectList = () => {
    const [loading, setLoading] = useState(true);
    const [userList, setUserList] = useState([]);
    const [currentPage, setcurrentPage] = useState(1);
    const [currentPosts, setcurrentPosts] = useState();
    const [isopen, setisopen] = useState(false);
    // ACTIVE/ INACTIVE STATUS DROPDOWN
    const [dropdown, setdropDown] = useState()
    const navigate = useHistory();
    // change user status
    const [userStatus, setuserStatus] = useState(false)
    const [userStatusID, setuserStatusID] = useState(false)
    const [userStatuss, setuserStatuss] = useState("")
    const [isOpenssssss, setisopenssssss] = useState(false)
    useEffect(() => {
        Store.addChangeListener(onChange);
        console.log("user_status");
        let formData = new FormData();
        formData.append("page_no", currentPage);
        formData.append("user_status", dropdown);
        usersAction(formData);
    }, [isOpenssssss, isopen, currentPage, dropdown]);

    // useEffect(() => {
    //     Store.addChangeListener(onChange);
    // }, [isOpenssssss, isopen, currentPage, dropdown]);

    const onChange = () => {
        if (Store.userList().status === 200) {
            console.log("onChange");
            setLoading(false);
            setUserList(Store.userList().data.data.users);
            setcurrentPosts(Store.userList().data.data.tot_users);
        }
    };

    const HandlePageClick = data => {
        setcurrentPage(data.selected + 1);
    };

    const ShowUsers = (id) => {
        navigate.push(`/user-details/${id}`)
    }
    const updateUser = async (id, ...userValue) => {
        let formData = new FormData();
        formData.append("user_id", id);
        formData.append("email", userValue[0].email);
        formData.append("name", userValue[0].name);
        formData.append("website_url", userValue[0].website_url);
        formData.append("mob_no", userValue[0].mob_no);
        formData.append("company_name", userValue[0].company_name);
        formData.append("physical_address", userValue[0].physical_address);
        formData.append("postal_address", userValue[0].postal_address);
        formData.append("company_email_address", userValue[0].company_email_address);
        formData.append("company_mob_no", userValue[0].company_mob_no);
        UserUpdateAction(formData);
        setisopen(!isopen);
    };

    let UserStatus = (id, status) => {
        console.log("Obj.status", status);
        setuserStatuss(status)
        setuserStatusID(id)
        setuserStatus(true)
    }
    // change status pop 
    let statyuspopup = () => {
        return (
            <>
                {userStatuss === "Active" ? <p style={{ margin: "0" }}>Are you sure you want to deactivate  this user account?</p> : <p style={{ margin: "0" }}>Are you sure you want to activate this user account?</p>}
            </>
        )
    }
    let body = statyuspopup()

    let HideModals = () => {
        setuserStatus(false)
    }
    let callApi = () => {
        setuserStatus(false)
        setLoading(true);
        let formData = new FormData();
        formData.append("user_id", userStatusID);
        var token = JSON.parse(Cookies.get("auth"));
        axios({
            url: api.baseURL + "/users/update_user_status",
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token
            },
            data: formData
        })
            .then(res => {
                if (res.data.status == "success") {
                    setisopenssssss(!isOpenssssss)
                    toast.success("User Status Change Successfully")
                } else if (res.data.status == "success") {
                    HandleError(res.data)
                }
            }
            )
    }
    return (
        <>
            <Container fluid className="main-content-container px-4">
                <PopUp
                    title={"User account status"}
                    open={userStatus}
                    hideModal={HideModals}
                    body={body}
                    callApi={callApi}
                    showButton={true}
                    ButtonNmae={"Yes"}
                />
                <PageHeader
                    title={"Users"}
                    subtitle={"MsingiPACK Cloud"}
                    name={"Add users"}
                    Status={false}
                />
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="border-bottom d-flex justify-content-between">
                                <h6 className="m-0">Users</h6>
                                <h6 className="m-0">
                                    <FormSelect onChange={e => {
                                        setdropDown(e.target.value);
                                    }}>
                                        <option value="" >
                                            All
                                        </option>
                                        <option value="active">Active Users</option>
                                        <option value="inactive">Inactive Users</option>

                                    </FormSelect>
                                </h6>
                            </CardHeader>
                            <CardBody className="p-0 pb-3">
                                {loading === true ?
                                    <Loader />
                                    :
                                    <>
                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <thead className="bg-light">
                                                    <tr>
                                                        <th scope="col" className="border-0"> S.No </th>
                                                        <th scope="col" className="border-0">
                                                            Name
                                                        </th>
                                                        <th scope="col" className="border-0">
                                                            Email
                                                        </th>

                                                        <th scope="col" className="border-0">
                                                            Phone
                                                        </th>
                                                        <th scope="col" className="border-0">
                                                            Status
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="border-0"
                                                            style={{ textAlign: "center" }}
                                                        >
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <UserListRow
                                                    currentPosts={userList}
                                                    //sendSubject={sendSubject}
                                                    pageNo={currentPage}
                                                    title={" View Subject"}
                                                    userStatus={UserStatus}
                                                    updateUser={updateUser}
                                                    ShowUsers={ShowUsers}
                                                />
                                            </table>
                                        </div>
                                        {currentPosts > 10 &&
                                            <PaginatedItems
                                                List={currentPosts}
                                                HandlePageClick={HandlePageClick}
                                            />}
                                    </>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default SubjectList;
