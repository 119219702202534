import React, { useState } from "react";
import { Button, Col, Row } from "shards-react";
import PageTitle from "./common/PageTitle";

const PageHeader = ({ title, subtitle, name, onsubmit, Status }) => {
    const [status, setStatus] = useState(Status);
    return (
        <Row noGutters className="page-header py-4 align-items-center">
            <Col md="8" sm="7" className="pl-md-0">
                <PageTitle
                    title={title}
                    subtitle={subtitle}
                    className="text-sm-left"
                />
            </Col>

            {status == true && (
                <Col md="4" sm="5" className="text-right">
                    <Button
                        outline
                        theme="primary"
                        className="mr-1"
                        onClick={() => {
                            onsubmit();
                        }}
                    >
                        {name}
                    </Button>
                </Col>
            )}
        </Row>
    );
};

export default PageHeader;
