import React, { useEffect, useState } from "react";
import { Button, Container, FormInput } from "shards-react";
import PopUp from "../../Popup/PopUpGrade";
import axios from "axios";
import Cookies from "js-cookie";
import Loader from "../loader";
import api from "../../../api";
import HandleError from "../../../utils/HandleError";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { localeData } from "moment";
import PageHeader from "../../PageHeader";
import { Beforeunload, useBeforeunload } from 'react-beforeunload';
export const FreeTrailListRowurl = ({
    currentPosts,
    sendSubject,
    title,
    deleteSubject,
    updateSubject,
    pageNo,
    setPaymentStatus,
    PaymentStatus,
    setshowButtons,
    setUpdateTopic,
    updateTopic
}) => {

    const history = useHistory();
    const [isDeleteOpen, setisDeleteOpen] = useState(false);
    const [UserId, setUserId] = useState()
    const [List, setList] = useState()
    const [pdf, setPdf] = useState()
    const [pdfValidate, setpdfValidate] = useState(true);
    const [SubjectName, setSubjectName] = useState()
    const [contentId, setcontentId] = useState()
    const [error, seterror] = useState();
    const [loading, setLoading] = useState(false)
    // name of subject to be edited

    const [editedSubjectName, seteditedSubjectName] = useState()
    // delete content 
    const [isdeletepopup, setisdeletepopup] = useState(false);

    const openPopup = (id) => {
        setUserId(id)
        setisDeleteOpen(!isDeleteOpen);
    };


    const body2 = () => {
        return (
            <>
                <p size="sm" className="mb-1 text-danger" value={error}>{error}</p>
                <FormInput
                    size="sm"
                    type="text"
                    value={editedSubjectName ? editedSubjectName : ""}
                    placeholder="Subject Name"
                    className="mb-2"
                    onChange={e => {
                        seteditedSubjectName(e.target.value);
                    }}
                />
                <FormInput type="file" accept=".zip" onChange={(e) => {
                    setPdf(e.target.files[0])
                    setpdfValidate(false)
                }} />

            </>
        );
    };
    const popChangebody = body2();

    const HideModals = () => {
        setisDeleteOpen(!isDeleteOpen);
        seterror()
    };

    const callApi = () => {
        ChangeSatus()
        setisDeleteOpen(!isDeleteOpen);
    };

    const ChangeSatus = () => {
        let formData = new FormData();
        formData.append("content", pdf);
        formData.append("title", editedSubjectName);
        seterror("")
        setpdfValidate(true)
        console.log("DAAAA", loading)
        // setLoading(true)
        setshowButtons(true)
        if(pdf){
            setUpdateTopic(true)
        }
        var token = JSON.parse(Cookies.get("auth"));
        axios({
            url: api.baseURL + `/free_trial_update/${contentId}`,
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token
            },
            data: formData
        })
            .then(res => {
                if (res.data.status === "success") {
                    console.log("DAAAA", loading, res.data.status)
                    setLoading(false)
                    setPaymentStatus(!PaymentStatus)
                    setshowButtons(false)
                    setUpdateTopic(false)
                    setPdf()
                    toast.success(res.data.message)
                } if (res.data.status === "error") {
                    console.log("DAAAA", loading, res.data.status)
                    console.log("dsgfksdfgsdf");
                    setUpdateTopic(false)
                    setPdf()
                    setshowButtons(false)
                }
            })
            .catch(error => {
                setLoading(false)
                setshowButtons(false)
                setUpdateTopic(false)
                setPdf()
                HandleError(error);
            });
    }

    const viewContent = (obj) => {
        console.log(error, "error");
        setcontentId(obj.id)
        seteditedSubjectName(obj.title)
        setisDeleteOpen(true)
    }

    // delete free trails content
    const Opendeletepopup = (id) => {
        setisdeletepopup(true)
        setUserId(id)

    }

    const HideDelteModals = () => {
        setisdeletepopup(false)
    }

    const callDeleteApi = () => {
        setisdeletepopup(false)
        var token = JSON.parse(Cookies.get("auth"));
        axios({
            url: api.baseURL + `/free_trial/${UserId}`,
            method: "DELETE",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token
            },

        })
            .then(res => {
                if (res.data.status === "success") {
                    console.log("DFGFDGFDGDFGDFGDF");
                    setLoading(false)
                    setPaymentStatus(!PaymentStatus)
                    toast.success(res.data.message)
                }
            })
            .catch(error => {
                setLoading(false)
                HandleError(error);
            });
    }

    const body1 = () => {
        return (
            <>
                <Container>
                    Are you sure you want to delete this content
                </Container>
            </>
        );
    };
    const popChangebody1 = body1();

    // view content button
    const sendGrade = (content_id) => {
        history.push({
            pathname: `/free-trail-content-view/` + content_id,
        });
    };

    useBeforeunload((event) => {
        if (loading) {
            event.preventDefault();
        }
    });

    if (loading) {
        return <tbody>
            <tr>
                {/* <td colSpan={5}><Loader message="Please wait, While content is uploading" /></td> */}
            </tr>
        </tbody>
    } else {
        var renderRow = (Obj, i) => {
            return (
                <>
                    <PopUp
                        title={"Upload Free Trial Content"}
                        open={isDeleteOpen}
                        hideModal={HideModals}
                        body={popChangebody}
                        callApi={() => {
                            pdfValidate ?
                                seterror("All fields are required*") : callApi()
                        }}
                        showButton={true}
                        ButtonNmae={"Yes"}
                    />

                    <PopUp
                        title={"Delete Free Trial Content"}
                        open={isdeletepopup}
                        hideModal={HideDelteModals}
                        body={popChangebody1}
                        callApi={callDeleteApi}
                        showButton={true}
                        ButtonNmae={"Yes"}
                    />
                    <tr key={i}>
                        <td>{i + 1 + (pageNo - 1) * 10}</td>
                        <td style={{ textAlign: "center" }}>{Obj.title}</td>
                        <td style={{ textAlign: "center" }}>{!updateTopic ? Obj.content_url ? Obj.content_url :
                        Obj.upload_status == 0 ? "Failed..." : 
                        <img className="Header-logo" src="/progressloader.gif" style={{ width: "10%" }} alt="Logo" /> : <img className="Header-logo" src="/progressloader.gif" style={{ width: "10%" }} alt="Logo" />}
                        </td>
                        <td style={{ textAlign: "center" }}>
                            <Button
                                className="action-btn"

                                title="Edit"
                                onClick={() => sendGrade(Obj.id)}
                            >
                                <i class="material-icons">
                                    visibility
                                </i>
                            </Button>
                            &nbsp;
                            <Button
                                className="action-btn"
                                theme="danger"
                                title="Edit"
                                onClick={() => Opendeletepopup(Obj.id)}
                            >
                                <i class="material-icons">
                                    delete
                                </i>
                            </Button>
                            &nbsp;
                            <Button
                                className="action-btn"
                                theme="info"
                                title="Edit"
                                onClick={() => viewContent(Obj)}
                            >
                                <i class="material-icons">
                                    edit
                                </i>
                            </Button>
                        </td>
                        {/* <td align="right">
                    <Button
                        onClick={() => {
                            deleteSubject(Obj.id);
                        }}
                        theme="danger"
                    >
                        <img
                            src="/images/action-icon/trash.svg"
                            alt="User Avatar"
                            width="15"
                            height="15"
                        />
                    </Button>
                    &nbsp;
                    <Button
                        theme="info"
                        onClick={() => {
                            updateSubject(Obj.subject_name, Obj.id, Obj.content_url);
                        }}
                    >
                        {" "}
                        <img
                            src="/images/action-icon/edit.svg"
                            alt="User Avatar"
                            width="15"
                        />
                    </Button>
                </td> */}
                    </tr>
                </>);
        }

    };
    const tableBody = !loading && currentPosts.length && currentPosts.map(renderRow);
    return (
        <>
            <tbody>
                {currentPosts.length !== undefined ?
                    tableBody
                    : <tr>
                        <td colSpan="15" className="text-center noRec-found"> No record found</td>
                    </tr>}
            </tbody>
        </>
    );
};
