import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, FormInput } from "shards-react";
import { UserDetailsAction } from "../../../flux/Action/UsersAction";
import { Store } from "../../../flux";
import Loader from "../loader";
import PopUp from "../../Popup/PopUpGrade";
import axios from "axios";
import api from "../../../api";
import Cookies from "js-cookie";
import { useHistory, useParams } from "react-router-dom";
import HandleError from "../../../utils/HandleError";
import { toast } from "react-toastify";
export const OtherTelecomUserListRow = ({
    currentPosts,
    userStatus,
    updateUser,
    pageNo,
    clientId,
    ShowUsers
}) => {
    const navigate = useHistory()
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [inputType, setInputType] = useState(true);
    const [userDetails, setUserDetails] = useState();
    const [isHide, setisHide] = useState(true);

    const sendMessage = (id) => {
        var token = JSON.parse(Cookies.get("auth"));
        let Data = { "user_id": parseInt(id) }
        axios({
            url: api.baseURL + `/send_user_login_details_SMS`,
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            },
            data: Data
        })
        .then(res => {
                console.log("resresres", res)
                // toast.success("Telecom user added Successfully")
                toast.success(res.data.message)
                setLoading(false)

        })
        .catch(error => {
            console.log("resresres", error);
            toast.error(error.data.message)
            HandleError(error);
        });
    }

    const renderRow = (Obj, i) => {
        return (
            <tr key={i}>
                <td>{i + 1 + (pageNo - 1) * 10}</td>
                <td>{Obj.email}</td>
                <td>{Obj.mob_no}</td>
                <td>{Obj.recharge_count}</td>
                <td style={{ textAlign: "center" }}>
                    {/* <Button className="action-btn" title="Verify Email" theme="success"
                        onClick={() => {
                            setisValidationPopup(true)
                            setUserid(Obj.id)
                        }}
                    >
                        <i class="material-icons">
                            domain_verification
                        </i>
                    </Button> */}
                    &nbsp;
                    <Button className="action-btn"
                        title="Send SMS"
                        theme="info"
                        onClick={() => sendMessage(Obj.id)}
                    >
                        <i class="material-icons">
                            send
                        </i>
                    </Button>
                    &nbsp;
                    <Button className="action-btn"
                        title="View recharge list"
                        onClick={() => navigate.push({
                            pathname: `/telecom-user-recharge-list/${Obj.id}`,
                            state: clientId
                        })}
                    >
                        <i class="material-icons">
                            visibility
                        </i>
                    </Button>
                </td>
            </tr>
        );
    };
    console.log("tableBody", tableBody);
    const tableBody = currentPosts.length && currentPosts.map(renderRow);

    return (
        <>

            <tbody>
                {
                    tableBody.length !== undefined ?
                        tableBody
                        : <tr>
                            <td colSpan="15" className="text-center noRec-found"> No record found</td>
                        </tr>
                }
            </tbody>
        </>
    );
};
