import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Card, CardBody } from "shards-react";

class SmallStats extends React.Component {
  constructor(props) {
    super(props);

  }

  render() {
    const { variation, label, value } = this.props;

    const cardClasses = classNames(
      "stats-small",
      variation && `stats-small--${variation}`
    );

    const dataFieldClasses = classNames(
      "stats-small__data text-center mw-100"
    );

    const labelClasses = classNames(
      "stats-small__label",
      "text-uppercase",
      variation !== "1" && "mb-1"
    );

    const valueClasses = classNames(
      "stats-small__value",
      "count mb-0"
    );

    return (
      <Card small className={cardClasses}>
        <div className={dataFieldClasses} >
          <span className={labelClasses}>{label}</span>
          <h6 className={valueClasses}>{value}</h6>
        </div>
      </Card>
    );
  }
}

SmallStats.propTypes = {
  /**
   * The Small Stats variation.
   */
  label: PropTypes.string,
  /**
   * The value.
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SmallStats.defaultProps = {
  value: 0,
  label: "Label",
};

export default SmallStats;
