import dispatcher from "../dispatcher";
import axios from "axios";
import Constants from "../constants";
import api from "../../api";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HandleError from "../../utils/HandleError";
import moment from "moment";

export const dashboardAction = (value, key) => {
  // console.log("update workinh", moment(data.year).format('YYYY'));
  // var date = moment(data.date).format('YYYY-MM-DD')
  // console.log(date);
  // console.log(data.month);
  console.log(value.year, key);
  let data;
  if (key === 0) {
    data = value
    localStorage.setItem("GraphData", JSON.stringify(data))
    localStorage.setItem("GraphFiltterData", JSON.stringify({}))
  }
  else if (key === 1) {
    let GraphData = localStorage.getItem("GraphFiltterData")
    console.log(value.year, "update workfsdfinh");
    let data0 = {
      ...(value.date && {
        date: moment(value.date).format('YYYY-MM-DD')
      }),
      ...((value.month && value.month != "0") && {
        month: value.month
      }),
      ...(value.year && {
        year: moment(value.year).format('YYYY')
      })
    }
    console.log("GraphData", GraphData);
    data = {
      ...data0, ...JSON.parse(GraphData)
    }
    localStorage.setItem("GraphData", JSON.stringify(data0))
  }
  else if (key === 2) {
    let GraphData = localStorage.getItem("GraphData")
    data = { ...value, ...JSON.parse(GraphData) }
    console.log(data, "valuesssss");
    localStorage.setItem("GraphFiltterData", JSON.stringify(value))
  }
  // localStorage.setItem("GraphData", JSON.stringify(data))
  // console.log("update workinh year", moment(value.year).format('YYYY'));
  // console.log("update workinh month", value.month);
  // console.log("update workinh date", value.date);
  // console.log("update workinh date", moment(value.date).format('YYYY-MM-DD'));

  var token = JSON.parse(Cookies.get("auth"));
  axios({
    url: api.baseURL + "/get_dashboard_stats",
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    },
    data: data
  })
    .then(res => {
      // console.log(JSON.str(localStorage.getItem("GraphData")), "im res");
      dispatcher.dispatch({
        type: Constants.DASHBOARD_STATS_SUCCESS,
        payload: res
      });
    })
    .catch(error => {
      HandleError(error);
    });
};
