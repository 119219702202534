import React from "react";
import { ForcelogoutAction } from "../flux/Action/AuthAction";
import { toast } from "react-toastify";

const HandleError = error => {
  if (error.response) {
    if (error.response.data.message === "Session Expired.") {
      console.log(error.response);
      ForcelogoutAction();
      // window.location.replace("http://localhost:3000/login");
    } else {
      toast.error(error.response.data.message);
    }
  }
  if (error === undefined) {
    window.location.replace("login");
  }
};
export default HandleError;
