import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import HandleError from "../../../utils/HandleError";
import PopUp from "../../Popup/PopUpGrade";
import api from "../../../api";
import { useHistory } from "react-router-dom";
const SubscriptionsList = ({ SubscriptionList, setPaymentStatus, PaymentStatus, pageNo }) => {
    const [isDeleteOpen, setisDeleteOpen] = useState(false);
    const [UserId, setUserId] = useState()
    const [List, setList] = useState()
    let history = useHistory()
    useEffect(() => {
        setList(SubscriptionList.subs)
    }, [SubscriptionList]);

    const ChangeSatus = (id) => {
        const data = { "subscription_id": id }
        console.log(id);
        var token = JSON.parse(Cookies.get("auth"));
        axios({
            url: api.baseURL + "/toggle_payment_status",
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token
            },
            data: data
        })
            .then(res => {
                if (res.data.status === "success") {
                    setPaymentStatus(!PaymentStatus)
                }
            })
            .catch(error => {
                HandleError(error);
            });
    }


    const HideModals = () => {

        setisDeleteOpen(!isDeleteOpen);
    };

    const body2 = () => {
        return (
            <>
                <div>Do you want to change the payment status?</div>
            </>
        );
    };

    const popDeletebody = body2();


    const openPopup = (id) => {
        setUserId(id)
        setisDeleteOpen(!isDeleteOpen);
    };

    const callApi = () => {
        ChangeSatus(UserId)
        setisDeleteOpen(!isDeleteOpen);
    };
    console.log(List, "list");
    if (List) {
        return (
            <>
                {List.map((ele, i) => {
                    return (
                        <>
                            <PopUp
                                title={"Change Payment Status"}
                                open={isDeleteOpen}
                                hideModal={HideModals}
                                body={popDeletebody}
                                callApi={callApi}
                                showButton={true}
                                ButtonNmae={"Yes"}
                            />

                            <tr>
                                <td scope="row">{i + 1 + (pageNo - 1) * 10}</td>
                                <td>
                                    {ele.user_name}
                                </td>
                                <td>
                                    {ele.invoice_id}
                                </td>
                                <td>{ele.email}</td>
                                <td style={{ textAlign: 'center' }}>{ele.edited_tot_users}</td>
                                <td style={{ textAlign: 'center' }}>{ele.plan_tenure}</td>
                                <td style={{ textAlign: 'center' }}>{ele.plan_name}</td>
                                <td style={{ textAlign: 'center' }}>{ele.total}</td>
                                <td style={{ textAlign: 'center' }}>{ele.apply_date}</td>
                                <td style={{ textAlign: 'center' }}>{ele.start_date}</td>
                                <td style={{ textAlign: 'center' }}>{ele.end_date}</td>
                                {ele.is_payment_done == "Unpaid" ? <td style={{ textAlign: 'center' }}>
                                    <span style={{ "cursor": "pointer" }} className="badge bg-warning" onClick={() => openPopup(ele.id)}>{ele.is_payment_done}</span>
                                </td> : ele.is_plan_expired == "1" ?
                                    <td style={{ textAlign: 'center' }}>    <span className="badge bg-danger"> Expired </span>  </td> : ele.is_payment_done == "Paid" ?
                                        <td style={{ textAlign: 'center' }}>    <span className="badge bg-success">{ele.is_payment_done}</span>  </td> :
                                        <td style={{ textAlign: 'center' }}>    <span className="badge bg-warning">{ele.is_payment_done}</span>  </td>
                                }
                                <td style={{ textAlign: 'center' }}>
                                    {ele.is_incremented_subscription != 0 ?
                                        <>     <button type="button" className="action-btn btn btn-success" onClick={() => { history.push(`/upgrade-user/${ele.id}`) }}>
                                            <i className="material-icons">grid_view</i>
                                        </button></> :
                                        <><button type="button" className="action-btn btn btn-danger">
                                            <a
                                                href={api.baseURL + `/download_invoice/${ele.id}`}
                                                theme="info"
                                                style={{ color: "white" }}
                                                title="Download Invoice"
                                                target="_blank"
                                            >
                                                <span className="material-icons">file_download</span>
                                            </a>
                                        </button>
                                        </>}
                                </td>
                            </tr>
                        </>
                    )
                })
                }
            </>
        );
    } else {
        return (<>
            <td colSpan="15" className="text-center noRec-found"> No record found</td>
        </>)
    }
}

export default SubscriptionsList;
