import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Navbar } from "shards-react";
import NavbarNav from "./NavbarNav/NavbarNav";
import { Link } from "react-router-dom";
import { NotificationCounts } from "../../../flux/Action/Notification";
import { Store } from "../../../flux";

const MainNavbar = ({ layout, stickyTop }) => {
  const [IsClass, setisClass] = useState(false)
  const [NotificationCount, setNotificationCount] = useState(false)
  const count = useRef();
  useEffect(() => {
    if (IsClass === false) {
      document.body.className = '';
    } else {
      document.body.className = 'sidebar-sticky';
    }

  }, [IsClass]);

  useEffect(() => {
    // console.log("hfjsdfhssdfdjf");
    Store.addChangeListener(onChange);
    NotificationCounts()
    return () => {
      count.current = NotificationCount;
    }
  }, []);

  const onChange = () => {
    if (Store.Notification_Count().data && Store.Notification_Count().data.status == "success") {
      setNotificationCount(Store.Notification_Count().data.data.notification_count);
    }
    ;
  };

  const classes = classNames(
    "main-navbar",
    "bg-white",
    stickyTop && "sticky-top",

  );


  const AddClass = () => {
    setisClass(!IsClass)
    console.log(document.body.className);

  }
  return (
    <div className={classes}>
      <div className="p-0 d-flex align-items-center">
        <div className="toggle-btn" onClick={AddClass}>
          {/* <NavbarToggle /> */}
          <span className="material-icons">
            menu
          </span>
        </div>
        <Navbar type="light" className="align-items-stretch flex-md-nowrap p-0 justify-content-end ml-auto">
          <Link to="/your-notification" className="notification d-flex align-items-center justify-content-center m-4">
            <div className="notifications p-1" >
              <span className="material-icons" style={{ fontSize: 30 }}>notifications_none</span>
              <span className="notifications-count">{NotificationCount ? NotificationCount
                : count.current ? count.current : 0}</span>
            </div>
          </Link>
          <NavbarNav />
        </Navbar>
      </div>
    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool
};

MainNavbar.defaultProps = {
  stickyTop: true
};

export default MainNavbar;
