import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Container,
    FormInput,
} from "shards-react";
import PageHeader from "../../components/PageHeader";
import PopUp from "../../components/Popup/PopUpGrade";
import Loader from '../../components/common/loader';
import Cookies from "js-cookie";
import api from "../../api";
import HandleError from "../../utils/HandleError";
import { FreeTrailListRowurl } from "../../components/common/ListRow/FreeTrailListRowurl";
import axios from "axios";
import PaginatedItems from "../../components/pagination";
import { toast } from "react-toastify";
import { Beforeunload, useBeforeunload } from 'react-beforeunload';

const FreeTrailurl = () => {
    const [loading, setLoading] = useState(true)
    const [listData, setlistData] = useState([])
    const [currentPage, setcurrentPage] = useState(1)
    const [TotalReuest, setTotalReuest] = useState()
    const [PaymentStatus, setPaymentStatus] = useState()
    // popup for add free trails content
    const [openPopup, setopenPopup] = useState(false)
    const [contentData, setContentData] = useState()
    const [error, setError] = useState("")
    const [loadingMsg, setloadingMsg] = useState(false)
    //state for show/hide "Add Free Trial Content" button 
    const [showButton, setshowButton] = useState(true)
    const [showButtons, setshowButtons] = useState(false)
    const [updateTopic, setUpdateTopic] = useState(false)
    useEffect(() => {
        var token = JSON.parse(Cookies.get("auth"));
        axios({
            url: api.baseURL + "/free_trial",
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token
            },
            params: { page_no: currentPage }
        })
            .then(res => {
                console.log("res", res);
                if (res) {
                    setshowButtons(false)
                    setLoading(false)
                    setlistData(res.data.data)
                }
            })
            .catch(error => {
                console.log("res", error.response);
                HandleError(error);
            });
    }, [PaymentStatus, currentPage, loadingMsg])


    const HandlePageClick = data => {
        setcurrentPage(data.selected + 1);
    };

    // add free trail content
    const addContent = () => {
        setopenPopup(true)
    }

    const body1 = () => {
        return (
            <Container>
                <>
                    <p size="sm" className="mb-1 text-danger" value={error}>{error}</p>
                    <FormInput
                        size="sm"
                        type="text"
                        placeholder="Content Name"
                        className="mb-2"
                        onChange={e => {
                            setContentData({ ...contentData, "subjectName": e.target.value });
                        }}
                    />
                    <FormInput type="file" accept=".zip" onChange={(e) => {
                        setContentData({ ...contentData, "Pdf": e.target.files[0] });
                        // setpdfValidate(false)
                    }} />
                </>
            </Container>
        );
    };
    const popUpbody = openPopup && body1();

    const hideModal = () => {
        setopenPopup(false)
    }

    const callSubjectApi = () => {
        if (contentData && contentData.subjectName && contentData.Pdf) {
            let formData = new FormData();
            formData.append("content", contentData.Pdf);
            formData.append("title", contentData.subjectName);
            setError("")
            // setLoading(true)
            setopenPopup(false)
            // setloadingMsg(true)
            setshowButtons(true)
            // setTimeout(() => {
            //     setLoading(false)
            // }, 500);
            var token = JSON.parse(Cookies.get("auth"));
            axios({
                url: api.baseURL + `/store_free_trial`,
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Accept: "application/json",
                    Authorization: "BEARER " + token.data.token
                },
                data: formData
            })
                .then(res => {
                    if (res.data.status === "success") {
                        setLoading(false)
                        setshowButtons(!showButtons)
                        setPaymentStatus(!PaymentStatus)
                        toast.success(res.data.message)
                    }
                })
                .catch(error => {
                    setshowButtons(false)
                    setLoading(false)
                    HandleError(error);
                });
        }
        else {
            setError("All fields are required*")
        }
    }

    useBeforeunload((event) => {
        if (loading) {
            event.preventDefault();
        }
    });


    return (
        <>
            <Container fluid className="main-content-container px-4">
                <PageHeader
                    title={"Free Trial Content"}
                    subtitle={"MsingiPACK Cloud"}
                    name={"Add Free Trial Content"}
                    onsubmit={addContent}
                    Status={true}
                />
                <PopUp
                    title={"Add Free Trial Content"}
                    open={openPopup}
                    hideModal={hideModal}
                    body={popUpbody}
                    callApi={callSubjectApi}
                    showButton={true}
                />
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="border-bottom d-flex justify-content-between">
                                <h6 className="m-0">Free Trial Content</h6>
                                <h6 className="m-0" style={{ fontSize: "30px" }}>
                                    <i onClick={() => {
                                        setshowButtons(!showButtons)
                                        setPaymentStatus(!PaymentStatus)
                                    }} className={"material-icons " + (showButtons ? "m-spin" : "")}>refresh</i></h6>
                            </CardHeader>
                            <CardBody className="p-0 pb-3">
                                {loading === true ?
                                    <Loader message={loadingMsg && "Please wait, While content is uploading"} />
                                    :
                                    <>
                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <thead className="bg-light">
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th style={{ textAlign: "center" }}>Title</th>
                                                        <th style={{ textAlign: "center" }}>Content Url</th>
                                                        <th style={{ textAlign: "center" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <FreeTrailListRowurl
                                                    currentPosts={listData}
                                                    //sendSubject={sendSubject}
                                                    title={" View Subject"}
                                                    setUpdateTopic={setUpdateTopic}
                                                    updateTopic={updateTopic}
                                                    setPaymentStatus={setPaymentStatus} PaymentStatus={PaymentStatus}
                                                    pageNo={currentPage}
                                                />
                                            </table>
                                        </div>
                                        {TotalReuest > 10 &&
                                            <PaginatedItems
                                                List={TotalReuest}
                                                HandlePageClick={HandlePageClick}
                                            />}
                                    </>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default FreeTrailurl

