import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row } from "shards-react";
import api from "../../api";
import PageHeader from "../../components/PageHeader";
import HandleError from "../../utils/HandleError";

const Contentview = () => {
    const [content, setContent] = useState()
    const { id } = useParams();
    const history = useHistory();
    useEffect(() => {
        console.log("i m the best log");
        var token = JSON.parse(Cookies.get("auth"));
        axios({
            url: api.baseURL + `/view_topic/${id}`,
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token
            },
        })
            .then(res => {
                if (res) {
                    console.log("res", res);
                    setContent(res.data.data)
                }
            })
            .catch(error => {
                HandleError(error);
            });
    }, [])

    // view content button
    const sendGrade = () => {
        history.goBack()
    };
    return (
        <>
            <Container fluid className="main-content-container px-4">
                <PageHeader
                    title={"Content View"}
                    subtitle={"MsingiPACK Cloud"}
                    name={"Back"}
                    onsubmit={sendGrade}
                    Status={true}
                />
                <Card small className="mb-4">
                    <CardHeader className="border-bottom">
                        <h6 className="m-0">Content View</h6>
                    </CardHeader>
                    <CardBody className="p-0 pb-3">
                        <div className="mpc-dashboard">
                            {console.log(id, "useParams")}
                            <div className="dashboard-right-content view-content-page">
                                {/* <div className={fullScreen ? "iframe-sec active" : "iframe-sec"}> */}
                                <iframe className="trialcontent_iframe" src={content && content.content_url} align="center" frameborder="0" allow="fullscreen" width="100%"></iframe>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Container>

            {/* </div> */}
        </>
    );
};
export default Contentview

