export default {
  /*actions constants for calling emit event*/
  CHANGE: "CHANGE",

  /* action constants for send data to store from actions */
  TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",

  LOGIN_SUCCESS: "LOGINSUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",

  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",

  FORCE_LOGOUT: "FORCE_LOGOUT",
  // user constant
  USERLIST_SUCCESS: "USER_SUCCESS",
  USERLIST_ERROR: "USER_ERROR",

  TELECOM_USERLIST_SUCCESS: "TELECOM_USER_SUCCESS",
  TELECOM_USERLIST_ERROR: "TELECOM_USER_ERROR",

  ADD_TELECOM_USERLIST_SUCCESS: "ADD_TELECOM_USER_SUCCESS",
  ADD_TELECOM_USERLIST_ERROR: "ADD_TELECOM_USER_ERROR",

  USERDETAILS_SUCCESS: "USERDETAILS_SUCCESS",

  USERSTATUS_SUCCESS: "USERSTATUS_SUCCESS",
  // subject constant
  SUBJECTLIST_SUCCESS: "SUBJECT_SUCCESS",
  SUBJECTLIST_ERROR: "SUBJECT_ERROR",

  SUBJECT_UPDATE: "SUBJECT_UPDATE",
  // grade constant
  GRADELIST_SUCCESS: "GRADE_SUCCESS",
  GRADELIST_ERROR: "GRADE_ERROR",

  GRADECATEGORIES_SUCCESS: "GRADECATEGORIES_SUCCESS",
  GRADECATEGORIES_ERROR: "GRADECATEGORIES_ERROR",

  SHOWGRADE_SUCCESS: "SHOWGRADE_SUCCESS",

  DASHBOARD_STATS_SUCCESS: "DASHBORAD_STATS_SUCCESS",

  SUBSCRIPTIONLIST_SUCCESS: "SUBSCRIPTIONLIST_SUCCESS",

  PLANSLIST: "PLANSLIST",

  // CMS CONSTANT
  CMS_LIST: "CMS_LIST",

  CMS_STORE_LIST: "CMS_STORE_LIST",

  CMS_UPDATE_LIST: "CMS_UPDATE_LIST",

  CONTACT_US_SUCCESS: "CONTACT_US_SUCCESS",

  // clear subject subjectUpdate()
  CLEAR_SUBJECT: "CLEAR_SUBJECT",

  // notification count
  NOTIFICATION_UPDATE: "NOTIFICATION_UPDATE"
};
