import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody, Button, string } from "shards-react";

import RangeDatePicker from "../common/RangeDatePicker";
import Chart from "../../utils/chart";
import { Store } from "../../flux";


class UsersOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: Store.DashBoard_Stats().data

    }
    this.canvasRef = React.createRef();
  }

  componentDidUpdate(prevState) {

    if (this.state.chartData.labels != prevState.data.chartData.labels) {
      console.log("hhhhh------------>>>>>", prevState.data.chartData.labels, "thisss");

      let { data } = Store.DashBoard_Stats();
      data = (data.data) ? data.data : [];
      const data3 = {
        title: "Users Overview",
        chartData: {
          labels: data.graphxaxisData,
          datasets: [
            {
              label: "Current Month",
              fill: "start",
              data: data.GraphData,
              backgroundColor: "rgba(0,123,255,0.1)",
              borderColor: "rgba(0,123,255,1)",
              pointBackgroundColor: "#ffffff",
              pointHoverBackgroundColor: "rgb(0,123,255)",
              borderWidth: 1.5,
              pointRadius: 0,
              pointHoverRadius: 3
            }
          ]
        }
      };


      const chartOptions = {
        ...{
          responsive: true,
          legend: {
            position: "top"
          },
          elements: {
            line: {
              // A higher value makes the line look skewed at this ratio.
              tension: 0.3
            },
            point: {
              radius: 0
            }
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: 'Duration'
                },
                display: true,
                gridLines: false,
                ticks: {
                  callback(tick, index) {
                    console.log("update workinh ", typeof tick);
                    if (typeof tick === "string") {
                      return tick;
                    } else {
                      return index + 1;
                    }
                    // Jump every 7 values on the X axis labels to avoid clutter.
                  }
                }
              }
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: '# User access times'
                },
                display: true,
                ticks: {
                  suggestedMax: 45,
                  callback(tick) {
                    if (tick === 0) {
                      return tick;
                    }
                    // Format the amounts using Ks for thousands.
                    return tick > 999 ? `${(tick / 1000).toFixed(1)}K` : tick;
                  }
                }
              }
            ]
          },
        },
        ...this.props.chartOptions
      };

      const BlogUsersOverview = new Chart(this.canvasRef.current, {
        type: "LineWithLine",
        data: this.props.data.chartData,
        options: chartOptions
      });

      // They can still be triggered on hover.
      // const buoMeta = BlogUsersOverview.getDatasetMeta(0);
      // buoMeta.data[0]._model.radius = 0;
      // buoMeta.data[
      //   this.props.chartData.datasets[0].data.length - 1
      // ]._model.radius = 0;

      // Render the chart.
      BlogUsersOverview.render();
    }
  }



  componentDidMount() {


    let { data } = Store.DashBoard_Stats();
    data = (data.data) ? data.data : [];
    const data3 = {
      title: "Users Overview",
      chartData: {
        labels: data.graphxaxisData,
        datasets: [
          {
            label: "Current Month",
            fill: "start",
            data: data.GraphData,
            backgroundColor: "rgba(0,123,255,0.1)",
            borderColor: "rgba(0,123,255,1)",
            pointBackgroundColor: "#ffffff",
            pointHoverBackgroundColor: "rgb(0,123,255)",
            borderWidth: 1.5,
            pointRadius: 0,
            pointHoverRadius: 3
          }
        ]
      }
    };


    const chartOptions = {
      ...{
        responsive: true,
        legend: {
          position: "top"
        },
        elements: {
          line: {
            // A higher value makes the line look skewed at this ratio.
            tension: 0.3
          },
          point: {
            radius: 0
          }
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Duration'
              },
              display: true,
              gridLines: false,
              ticks: {
                callback(tick, index) {
                  console.log("update workinh ", typeof tick);
                  if (typeof tick === "string") {
                    return tick;
                  } else {
                    return index + 1;
                  }
                  // Jump every 7 values on the X axis labels to avoid clutter.
                }
              }
            }
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: ' No Of Users'
              },
              display: true,
              ticks: {
                suggestedMax: 45,
                callback(tick) {
                  if (tick === 0) {
                    return tick;
                  }
                  // Format the amounts using Ks for thousands.
                  return tick > 999 ? `${(tick / 1000).toFixed(1)}K` : tick;
                }
              }
            }
          ]
        },
      },
      ...this.props.chartOptions
    };

    const BlogUsersOverview = new Chart(this.canvasRef.current, {
      type: "LineWithLine",
      data: this.props.data.chartData,
      options: chartOptions
    });

    // They can still be triggered on hover.
    // console.log("dataset" , );
    // const buoMeta = BlogUsersOverview.getDatasetMeta(0);
    // buoMeta.data[0]._model.radius = 0;
    // buoMeta.data[
    //   this.props.chartData.datasets.data.length - 1
    // ]._model.radius = 0;

    // Render the chart.
    BlogUsersOverview.render();
  }

  render() {
    console.log("DATA", Store.DashBoard_Stats().data)
    const { title } = this.props;
    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{title}</h6>
        </CardHeader>
        <CardBody className="pt-0">
          <Row className="border-bottom py-2 bg-light">
            <Col sm="6" className="d-flex mobile-block mb-2 mb-sm-0">
              <RangeDatePicker />
            </Col>
            <Col>
            </Col>
          </Row>
          <canvas
            height="200vh"
            ref={this.canvasRef}
            style={{ maxWidth: "100% !important" }}
          />
        </CardBody>
      </Card>
    );
  }
}

UsersOverview.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The chart dataset.
   */
  chartData: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object
};

UsersOverview.defaultProps = {
  title: "Users Overview",
  chartData: {
    labels: Array.from(new Array(40), (_, i) => (i === 0 ? 1 : i)),
    datasets: [
      {
        label: "Current Month",
        fill: "start",
        data: [
          500,
          800,
          320,
          180,
          240,
          320,
          230,
          650,
          590,
          1200,
          750,
          940,
          1420,
          1200,
          960,
          1450,
          1820,
          2800,
          2102,
          1920,
          3920,
          3202,
          3140,
          2800,
          3200,
          3200,
          3400,
          2910,
          3100,
          4250
        ],
        backgroundColor: "rgba(0,123,255,0.1)",
        borderColor: "rgba(0,123,255,1)",
        pointBackgroundColor: "#ffffff",
        pointHoverBackgroundColor: "rgb(0,123,255)",
        borderWidth: 1.5,
        pointRadius: 0,
        pointHoverRadius: 3
      },
      {
        label: "Past Month",
        fill: "start",
        data: [
          380,
          430,
          120,
          230,
          410,
          740,
          472,
          219,
          391,
          229,
          400,
          203,
          301,
          380,
          291,
          620,
          700,
          300,
          630,
          402,
          320,
          380,
          289,
          410,
          300,
          530,
          630,
          720,
          780,
          1200
        ],
        backgroundColor: "rgba(255,65,105,0.1)",
        borderColor: "rgba(255,65,105,1)",
        pointBackgroundColor: "#ffffff",
        pointHoverBackgroundColor: "rgba(255,65,105,1)",
        borderDash: [3, 3],
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
        pointBorderColor: "rgba(255,65,105,1)"
      }
    ]
  }
};

export default UsersOverview;
