import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { Upgradeuserlist } from "../../components/common/ListRow/Upgradeuser";
import PaginatedItems from "../../components/pagination";
import Loader from '../../components/common/loader';
import HandleError from "../../utils/HandleError";
import api from "../../api";
import Cookies from "js-cookie";
import axios from "axios";
const UpgradeuserList = () => {
    let { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [userList, setUserList] = useState([]);
    const [currentPosts, setcurrentPosts] = useState();
    const [currentPage, setcurrentPage] = useState(1);
    const [open, setOpen] = useState(false);
    const [PaymentStatus, setPaymentStatus] = useState()

    useEffect(() => {
        var token = JSON.parse(Cookies.get("auth"));
        axios({
            url: api.baseURL + `/list_incremented_subscriptions/${parseInt(id)}`,
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token
            },
            body: JSON.stringify({ "page_no": currentPage })
        })
            .then(
                res => (
                    setUserList(res.data.data.subs),
                    setcurrentPosts(res.data.data.tot_subs),
                    setLoading(false)
                )
            )
            .catch(error => {
                console.log("res", error.response);
                HandleError(error);
            });
    }, [currentPage, open, PaymentStatus]);


    const HandlePageClick = data => {
        setcurrentPage(data.selected + 1);
    };


    return (
        <>
            <Container fluid className="main-content-container px-4">
                <PageHeader
                    title={"Users"}
                    subtitle={"MsingiPACK Cloud"}
                    name={"Add users"}
                    Status={false}
                />
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="border-bottom">
                                <h6 className="m-0">Users</h6>
                            </CardHeader>
                            <CardBody className="p-0 pb-3">
                                {loading === true ?
                                    <Loader />
                                    :
                                    <>
                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <thead className="bg-light">
                                                    <tr>
                                                        <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                                                            S.No
                                                        </th>
                                                        <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                                                            User Name
                                                        </th>
                                                        <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                                                            Invoice Id
                                                        </th>
                                                        <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                                                            Email
                                                        </th>
                                                        <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                                                            Total User
                                                        </th>
                                                        <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                                                            Plan Tenure
                                                        </th>
                                                        <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                                                            Plan Name
                                                        </th>
                                                        <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                                                            Amount
                                                        </th>
                                                        <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                                                            Apply Date
                                                        </th>
                                                        <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                                                            Payment Status
                                                        </th>
                                                        <th scope="col" className="border-0" style={{ textAlign: 'center' }}>
                                                            Action
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <Upgradeuserlist
                                                    currentPosts={userList}
                                                    pageNo={currentPage}
                                                    currentPage={currentPage}
                                                    setOpen={setOpen}
                                                    open={open}
                                                    title={" View Subject"}
                                                    setPaymentStatus={setPaymentStatus}
                                                    PaymentStatus={PaymentStatus}
                                                />
                                            </table>
                                        </div>
                                        {currentPosts > 10 && <PaginatedItems
                                            List={currentPosts}
                                            HandlePageClick={HandlePageClick}
                                        />}

                                    </>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default UpgradeuserList;