import React from "react";
import classNames from "classnames";
import {
  InputGroup,

  InputGroupAddon,
  InputGroupText
} from "shards-react";
import DatePicker from "react-datepicker";
import "../../assets/range-date-picker.css";
import moment from "moment";
import { dashboardAction } from "../../flux/Action/Dashborad";

import "react-datepicker/dist/react-datepicker.css";


class RangeDatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: "",
      month: null,
      defaultMonthSet: 1,
      year: new Date()
    };

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.date !== this.state.date || prevState.month !== this.state.month || prevState.year !== this.state.year) {
      console.log(this.state, "update workinh ");
      dashboardAction(this.state, 1);
    }
  }
  handleStartDateChange(value) {
    console.log('aaaaaaa ' + value)
    if (value[1] === "date") {
      this.setState({
        ...this.state,
        ...{ date: value[0], month: null, defaultMonthSet: 1, year: "" }
      });
    }
    else if (value[1] === "month") {
      this.setState({
        ...this.state,
        ...{ month: value[0], date: "", defaultMonthSet: 0 }
      });
    }
    else if (value[1] === "year") {
      var date = value[0]
      // var date = moment(value[0]).format('YYYY')
      console.log("update workinh", date);
      this.setState({
        ...this.state,
        ...{ year: date, date: "", defaultMonthSet: 0 }
      });
    }
  }

  handleEndDateChange(value) {
    this.setState({
      ...this.state,
      endDate: new Date(value)
    });
  }


  monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  render() {
    const { className } = this.props;
    const classes = classNames(className, "d-flex", "my-auto", "date-range");

    return (
      <>
        {/* <InputGroup className={classes}> */}
        <DatePicker
          selected={this.state.date}
          onChange={(date) => this.handleStartDateChange([date, "date"])}
          placeholderText="Select Date"
        />
        <select
          className="form-control custom-select"
          onChange={(e) => this.handleStartDateChange([e.target.value, "month"])}>
          <option className="mb-2 form-control text-center" selected={this.state.defaultMonthSet == 1 ? true : false} value={`0`}>Select Month</option>
          {this.monthNames.map((obj, i) => {
            return (
              <>
                <option className="mb-2 form-control text-center" value={i + 1} key={i}>
                  {obj}
                </option>
              </>
            );
          })}
        </select>
        <DatePicker
          selected={this.state.year}
          // value={`${this.state.year}`}
          onChange={(date) => this.handleStartDateChange([date, "year"])}
          showYearPicker
          dateFormat="yyyy"
          placeholderText="Select Year"
        />
        {/* <InputGroupAddon type="append">
          <InputGroupText>  
            <i className="material-icons">&#xE916;</i>
          </InputGroupText>
        </InputGroupAddon> */}
        {/* </InputGroup> */}
      </>
    );
  }
}

export default RangeDatePicker;
