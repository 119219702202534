import dispatcher from "../dispatcher";
import axios from "axios";
import Constants from "../constants";
import Cookies from "js-cookie";
import api from "../../api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HandleError from "../../utils/HandleError";

export const authAction = async ({ formData, e }) => {
  e.preventDefault();
  axios({
    url: api.baseURL + "/login",
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json"
    },
    data: formData
  })
    .then(
      res => (
        console.log(res, "fsdfdfsdfsd"),
        Cookies.set("auth", JSON.stringify(res.data)),
        dispatcher.dispatch({
          type: Constants.LOGIN_SUCCESS,
          payload: res
        })
      )
    )
    .catch(error => {
      console.log("hell", error.response);
      HandleError(error);
    });
};
export const logoutAction = () => {
  var token = JSON.parse(Cookies.get("auth"));
  console.log(token.data.token);
  axios({
    url: api.baseURL + "/logout",
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    }
  }).then(
    res => (
      Cookies.remove("auth"),
      console.log(res.data.message),
      toast.success(res.data.message),
      dispatcher.dispatch({
        type: Constants.LOGOUT_SUCCESS,
        payload: res
      }),
      error => {
        console.log(error.response.data.message);
      }
    )
  );
};

export const ForcelogoutAction = () => {
  var token = JSON.parse(Cookies.get("auth"));
  console.log(token.data.token);
  axios({
    url: "http://192.168.2.80:3334/api/v1/admin/force_logout",
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    }
  })
    .then(
      res => (
        console.log("force"),
        console.log(token.data.token),
        toast.success(res.data.message),
        window.location.replace("login"),
        Cookies.remove("auth"),
        dispatcher.dispatch({
          type: Constants.FORCE_LOGOUT,
          payload: res
        })
      )
    )
    .catch(error => {
      if (error.response) {
        console.log("error", error.response);
        JSON.parse(Cookies.remove("auth"));
        window.location.replace("login");
      }
    });
};
