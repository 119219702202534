import React from "react";
import { Container, Row, Col, Card, CardBody} from "shards-react";
import PageHeader from "../../components/PageHeader";
import logoSlide from "../../images/safaricom.png"
import { useHistory } from "react-router-dom";

const PromotionDetail = () => {
  const history = useHistory();
  const PromotionList = () => {
    history.push({
        pathname: `/promotion-slider-management`,
    });
  };
  return (
    <>
      <Container fluid className="main-content-container">
      <PageHeader
          title={"Promotion Slider Management Detail"}
          subtitle={"MsingiPACK Cloud"}
          name={"Back"}
          onsubmit={PromotionList}
          Status={true}
        />
        <Row>
            <Col>
                <Card small className="mb-4">
                    <CardBody className="p-3">
                        <div className="form-group">
                            <h5 className="mb-3">Logo Image</h5>
                            <img src={logoSlide} alt="Banner Image" className="img-fluid" />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
      </Container>
    </>
  );
};
export default PromotionDetail;
