import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import PopUp from "../../../Popup/PopUpGrade";
import { logoutAction } from "../../../../flux/Action/AuthAction";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Store } from "../../../../flux";
import Cookies from "js-cookie";

const UserActions = () => {
  const [visible, setVisible] = useState(false);
  const [adminData, setAdmindata] = useState(Cookies.get("auth"));
  const [isopen, setisOpen] = useState(false);
  const [DeletedGradeName, setDeletedGradeName] = useState();
  const [isDeleteOpen, setisDeleteOpen] = useState(false);
  //
  const navigate = useHistory();
  useEffect(() => {
    if (adminData === undefined) {
      navigate.push("/login");
    } else {
      setAdmindata(JSON.parse(Cookies.get("auth")).data.name);
    }
    // console.log(adminData);
    // Store.addChangeListener(onChange);

    //console.log(JSON.parse(adminData).data.name);
  }, []);

  const hideModal = () => {
    setisOpen(!isopen);
  };

  const onChange = () => {
    // setAdmindata(Store.addLogin().data.data);
  };
  const toggleUserActions = () => {
    setVisible(!visible);
  };
  const handleLogout = e => {
    e.preventDefault();
    logoutAction();
    setTimeout(() => navigate.push("/dashboard"), Cookies.remove("auth"), 1000);
  };

  const HideModals = () => {
    setisDeleteOpen(!isDeleteOpen);
  };

  const body2 = () => {
    return (
      <>
        <div>Are you sure you want to Logout the Admin?</div>
      </>
    );
  };

  const popDeletebody = body2();

  const deleteGradePopUp = id => {
    setisDeleteOpen(!isDeleteOpen);
    setDeletedGradeName(id);
  };

  return (
    <>
      <PopUp
        title={"Logout"}
        open={isDeleteOpen}
        hideModal={HideModals}
        body={popDeletebody}
        callApi={handleLogout}
        showButton={true}
        ButtonNmae={"Logout"}
      />
      <NavItem
        tag={props => <Dropdown {...props} />}
        caret
        toggle={toggleUserActions}
      >
        {/* {console.log(adminData.data.data.email)} */}
        <DropdownToggle
          caret
          tag={props => <NavLink {...props} />}
          className="text-nowrap px-3"
        >
          <img
            className="user-avatar rounded-circle mr-2"
            src={require("./../../../../images/avatar2.svg")}
            alt="User Avatar"
          />
          {/* src={require("./../../../../images/avatars/0.jpg")} */}
          {
            <span className="d-none d-md-inline-block">
              {adminData === undefined ? "MsingiPACK" : adminData}
            </span>
          }
        </DropdownToggle>
        <Collapse
          tag={props => <DropdownMenu {...props} />}
          right
          small
          open={visible}
        >
          <DropdownItem
            onClick={deleteGradePopUp}

            className="text-danger"
          >
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
        <ToastContainer />
      </NavItem>
    </>
  );
};

export default UserActions;
