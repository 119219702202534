import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, LoginLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import LoginContainer from "./views/Login";
import ComponentsOverview from "./views/ComponentsOverview";
import SubjectList from "./views/subjects/List";
import UserList from "./views/Users/List";
import UserList2 from "./views/Users/TelecomUserList";
import GradeList from "./views/Grades/List";
import ClassList from "./views/Class/List";
import BusinessList from "./views/Business/List";
import SettingsList from "./views/Settings/List";
import SubscritionList from "./views/Subscription/List";
import UserDetail from "./views/Business/Detail";
import TelecomUserDetail from "./views/Users/TelecomUserDetails";
import TelcoUserOverview from "./views/Users/TelcoUserOverview";
import TelecomUserRechargeList from "./views/Users/TelecomUserRechargeList";
import { useLocation } from "react-router-dom";
import ContactUs from "./views/ContactUs";
import Plan from "./views/Plan";
import { from } from "form-data";
import CMSlist from "./views/CMS/CMS";
import NewCMSDraft from "./views/CMS/EditCMS";
import Subscritions from "./views/Subscription/subscriptions";
import ForgetPassword from "./views/ForgetPassword";
import ResetPassword from "./views/ResetPasswrod";
import Notification from "./views/Notification/notification";
import { subjectAction } from "./flux/Action/SubjectAction";
import UsersDetail from "./views/Users/UserDetails";
import GradeCategory from "./views/Grade Category/GradeCategoty";
import FreeTrail from "./views/FreeTrailRequest/freetrail";
import LoginLogs from "./views/LoginLogs";
import UpgradeuserList from "./views/Subscription/UpgradeuserList";
import FreeTrailurl from "./views/FreeTrailurl/freetrail";
import FreeTrailontentview from "./views/FreeTrailcontentview/freetrail";
import Contentview from "./views/subjects/freetrail";
import YourNotification from "./views/Notification your/notification";
import FreeTrailurlcopy from "./views/FreeTrailurl copy/freetrail";
import Topics from "./views/Topic/List";
import RSSPDashbaord from "./views/RSSPDashbaord";
// import BlogPosts from "./views/BlogPosts";

import BannerManagementList from "./views/BannerManagement/List";
import BannerManagementDetail from "./views/BannerManagement/Detail";
import PromotionSliderManagement from "./views/PromotionSliderManagement/List";
import PromotionDetail from "./views/PromotionSliderManagement/Detail";

export default [
  {
    path: ".",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/dashboard" />
  },
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/dashboard" />
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: BlogOverview,
    hello: "hello"
  },
  {
    path: "/user",
    layout: DefaultLayout,
    component: UserList
  },
  {
    path: "/rssp-dashboard",
    layout: DefaultLayout,
    component: RSSPDashbaord,
    hello: "hello"
  },
  {
    path: "/telecom-users",
    layout: DefaultLayout,
    component: UserList2
  },
  {
    path: "/businesses/user-detail",
    layout: DefaultLayout,
    component: UserDetail
  },
  {
    path: "/telecom-user-detail/:id",
    layout: DefaultLayout,
    component: TelecomUserDetail
  },
  {
    path: "/telecom-user-recharge-list/:id",
    layout: DefaultLayout,
    component: TelecomUserRechargeList
  },
  {
    path: "/telecom-user-overview/:id",
    layout: DefaultLayout,
    component: TelcoUserOverview
  },
  {
    path: "/subscription",
    layout: DefaultLayout,
    component: SubscritionList
  },
  {
    path: "/setting",
    layout: DefaultLayout,
    component: SettingsList
  },
  {
    path: "/businesses",
    layout: DefaultLayout,
    component: BusinessList
  },
  {
    path: "/subjects/:id",
    layout: DefaultLayout,
    component: SubjectList
  },
  {
    path: "/grades",
    layout: DefaultLayout,
    component: GradeList
  },

  {
    path: "/addnewpost",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/classes",
    layout: DefaultLayout,
    component: ClassList
  },
  {
    path: "/contactus",
    layout: DefaultLayout,
    component: ContactUs
  },
  {
    path: "/plans",
    layout: DefaultLayout,
    component: Plan
  },
  {
    path: "/cms",
    layout: DefaultLayout,
    component: CMSlist
  },
  {
    path: "/editcms/:id",
    layout: DefaultLayout,
    component: NewCMSDraft
  },
  {
    path: "/Subscritions",
    layout: DefaultLayout,
    component: Subscritions
  },
  {
    path: "/forget-password",
    layout: LoginLayout,
    component: ForgetPassword
  },
  {
    path: "/reset-password",
    layout: LoginLayout,
    component: ResetPassword
  },
  {
    path: "/user-details/:id",
    layout: DefaultLayout,
    component: UsersDetail
  },
  {
    path: "/grade-category",
    layout: DefaultLayout,
    component: GradeCategory
  },
  {
    path: "/notification",
    layout: DefaultLayout,
    component: Notification
  },
  {
    path: "/free-trail-request",
    layout: DefaultLayout,
    component: FreeTrail
  },
  {
    path: "/login-log",
    layout: DefaultLayout,
    component: LoginLogs
  },
  {
    path: "/upgrade-user/:id",
    layout: DefaultLayout,
    component: UpgradeuserList
  },
  {
    path: "/free-trail-content1",
    layout: DefaultLayout,
    component: FreeTrailurl
  },
  {
    path: "/free-trail-content",
    layout: DefaultLayout,
    component: FreeTrailurlcopy
  },
  {
    path: "/free-trail-content-view/:id",
    layout: DefaultLayout,
    component: FreeTrailontentview
  },
  {
    path: "/content-view/:id",
    layout: DefaultLayout,
    component: Contentview
  },
  {
    path: "/your-notification",
    layout: DefaultLayout,
    component: YourNotification
  },
  {
    path: "/topics/:id",
    layout: DefaultLayout,
    component: Topics
  },
  {
    path: "/banner-management",
    layout: DefaultLayout,
    component: BannerManagementList
  },
  {
    path: "/banner-management-detail",
    layout: DefaultLayout,
    component: BannerManagementDetail
  },
  {
    path: "/promotion-slider-management",
    layout: DefaultLayout,
    component: PromotionSliderManagement
  },
  {
    path: "/promotion-detail",
    layout: DefaultLayout,
    component: PromotionDetail
  }
];
