import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row
} from "shards-react";
import api from "../../api";
import { TelecomdashboardAction } from "../../flux/Action/TelecomDashborad";
import HandleError from "../../utils/HandleError";
import { RSSPDashboardAction } from "../../flux/Action/RSSPDashboard";

const TelecomGraphFillter = ({ data }) => {
  const [open, setOpen] = useState()
  const [open1, setOpen1] = useState()
  const [open2, setOpen2] = useState()

  // state for get api data
  const [GradeId, setGradeId] = useState()
  const [ClassIds, setClassIds] = useState()

  // state for get dropdown data
  const [ClassId, setClassId] = useState()
  const [SubjectId, setSubjectId] = useState()
  const [SubjectIds, setSubjectIds] = useState()

  useEffect(() => {
    if (ClassId) {
      RSSPDashboardAction({
        ...(ClassId && {
          grade_category_id: ClassId
        }),
        ...(SubjectId && {
          grade_id: SubjectId
        }),
        ...(SubjectIds && {
          subject_id: SubjectIds
        })
      }, 2)
    }
    if (ClassId) {
      var token = JSON.parse(Cookies.get("auth"));
      axios({
        url: api.baseURL + `/category_based_grades/${ClassId}`,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "BEARER " + token.data.token
        },
      })
        .then(
          res => (
            console.log("ressssssss", res.data.data),
            setGradeId(res.data.data)
          )
        )
        .catch(error => {
          console.log("resssssssssss", error.response);
          HandleError(error);
        });
    }
    if (SubjectId) {
      var token = JSON.parse(Cookies.get("auth"));
      axios({
        url: api.baseURL + `/grade_based_subjects/${SubjectId}`,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "BEARER " + token.data.token
        },
      })
        .then(
          res => (
            console.log("ressssssssssssss", res.data.data),
            setClassIds(res.data.data)
          )
        )
        .catch(error => {
          console.log("res", error.response);
          HandleError(error);
        });
    }
  }, [ClassId, SubjectId, SubjectIds])


  const toggle = () => {
    setOpen(!open)
  }

  const toggle1 = () => {
    setOpen1(!open1)
  }
  const toggle2 = () => {
    setOpen2(!open2)
  }
  return (
    <Card small style={{ maxHeight: "50%" }} className="mb-2">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Request Call Analysis</h6>
      </CardHeader>
      <CardBody className="pt-0 d-flx  align-items-center justify-content-center">
        <Row className="border-bottom py-2 px-3 bg-light">
          {/* <Dropdown open={open} toggle={toggle} className="w-100">{console.log("data.grade_categories_arr", ClassId, data.grade_categories_arr && data.grade_categories_arr[ClassId])}
            <DropdownToggle className="px-5 w-100" width="100%">{ClassId && data.grade_categories_arr ? data.grade_categories_arr[parseInt(ClassId) - 1].category_name : "By Class"}</DropdownToggle>
            <DropdownMenu className="w-100">
              {data.grade_categories_arr && data.grade_categories_arr.map((item, i) => {
                console.log("fsdfsdffsdf", item)
                return (
                  <>
                    <DropdownItem value={item.id} onClick={(e) => {
                      setClassId(e.target.value)
                      setSubjectId()
                      setSubjectIds()
                      setClassIds()
                      setGradeId()
                    }}>{item.category_name}</DropdownItem>
                  </>
                )
              })}
            </DropdownMenu>
          </Dropdown> */}
          <select
            className="mb-2 form-control"
            onChange={e => {
              setClassId(e.target.value)
              setSubjectId()
              setSubjectIds()
              setClassIds()
              setGradeId()
            }}
          >

            <>
              <option disabled selected>By Class</option>
              {data.grade_categories_arr && data.grade_categories_arr.map((obj, i) => {
                return (
                  <>
                    <option value={obj.id} key={i}>
                      {obj.category_name}
                    </option>
                  </>
                );
              })}
            </>
          </select>
        </Row>
        {console.log(ClassId, "ata.data.grade_catego")}
        {/* <Row className="border-bottom py-2 px-3 bg-light">
          <Dropdown open={open1} toggle={toggle1} className="w-100">
            <DropdownToggle className="px-5 w-100" width="100%">
              {SubjectId ? GradeId[parseInt(SubjectId) - 1].grade_name : "By Grade"}
              {console.log(SubjectId && GradeId[parseInt(SubjectId) + 1], "resssss")}
            </DropdownToggle>
            <DropdownMenu className="w-100">

              {GradeId ? GradeId.map((item, i) => {
                return (
                  <>
                    <DropdownItem value={item.id} onClick={(e) => {
                      setSubjectId(e.target.value)
                      setSubjectIds()
                      setClassIds()
                    }}>{item.grade_name}</DropdownItem>
                  </>
                )
              }) : <DropdownItem > No Data Found</DropdownItem>}
            </DropdownMenu>
          </Dropdown>
        </Row> */}
        <Row className="border-bottom py-2 px-3 bg-light">
          <select
            className="mb-2 form-control"
            onChange={e => {
              setSubjectId(e.target.value)
              setSubjectIds()
              setClassIds()
            }}
          >
            {GradeId ?
              <>
                <option disabled selected>By Grade</option>
                {GradeId.map((obj, i) => {
                  return (
                    <>
                      <option value={obj.id} key={i}>
                        {obj.grade_name}
                      </option>
                    </>
                  );
                })}
              </> : <> <option disabled selected style={{ display: "none" }}>By Grade</option> <option value="1" disabled> No Data Found</option></>}
          </select>
        </Row>
        <Row className="border-bottom py-2 px-3 bg-light">
          <select
            className="mb-2 form-control"
            onChange={e => {
              setSubjectIds(e.target.value)
            }}
          >
            {ClassIds ?
              <>
                <option disabled selected>By Subject</option>
                {ClassIds.map((obj, i) => {
                  return (
                    <>
                      <option value={obj.id} key={i}>
                        {obj.subject_name}
                      </option>
                    </>
                  );
                })}
              </> : <> <option disabled selected style={{ display: "none" }}>By Subject</option> <option value="1" disabled> No Data Found</option></>}
          </select>
        </Row>
      </CardBody>
    </Card>
  );
}



export default TelecomGraphFillter;
