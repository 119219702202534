export default function () {
  return [
    {
      title: "Dashboard",
      to: "/dashboard",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter: ""
    },
    {
      title: "User",
      htmlBefore: '<i class="material-icons">people</i>',
      to: "/user"
    },
    {
      title: "RSSP Dashboard",
      to: "/rssp-dashboard",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter: ""
    },
    {
      title: "RSSP users",
      htmlBefore: '<i class="material-icons">people</i>',
      to: "/telecom-users"
    },
    {
      title: "Session-log",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/login-log"
    },
    {
      title: "Grade Category",
      htmlBefore: '<i class="material-icons">category</i>',
      to: "/grade-category"
    },
    {
      title: "Grades / Classes",
      htmlBefore: '<i class="material-icons">grade</i>',
      to: "/grades"
    },
    {
      title: "Plans",
      htmlBefore: '<i class="material-icons">attach_money</i>',
      to: "/plans"
    },
    {
      title: "Contact Us",
      htmlBefore: '<i class="material-icons">contact_page</i>',
      to: "/contactus"
    },
    {
      title: "CMS",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/cms"
    },
    {
      title: "Subscription",
      htmlBefore: '<i class="material-icons">monetization_on</i>',
      to: "/Subscritions"
    },
    {
      title: "Send Bulk Notification",
      htmlBefore: '<i class="material-icons">local_atm</i>',
      to: "/notification"
    },
    {
      title: "Your Notification",
      htmlBefore: '<i class="material-icons">local_atm</i>',
      to: "/your-notification"
    },
    {
      title: "Free Trial Content",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/free-trail-content"
    },
    {
      title: "Free Trial Requests",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/free-trail-request"
    },
    {
      title: "Setting",
      htmlBefore: '<i class="material-icons">settings</i>',
      to: "/Setting"
    },
    {
      title: "Banner Management",
      htmlBefore: '<i class="material-icons">image</i>',
      to: "/banner-management"
    },
    {
      title: "Promotion Slider",
      htmlBefore: '<i class="material-icons">settings</i>',
      to: "/promotion-slider-management"
    }
    // {
    //   title: "Subjects",
    //   htmlBefore: '<i class="material-icons">people</i>',
    //   to: "/subjects"
    // },
    //{
    //   title: "Classes",
    //   htmlBefore: '<i class="material-icons">local_shipping</i>',
    //   to: "/classes"
    // },
    // {
    //   title: "Businesses",
    //   htmlBefore: '<i class="material-icons">local_atm</i>',
    //   to: "/businesses"
    // },
    // {
    //   title: "Subscription",
    //   htmlBefore: '<i class="material-icons">payment</i>',
    //   to: "/subscription"
    // },
    // {
    //   title: "Setting",
    //   htmlBefore: '<i class="material-icons">undo</i>',
    //   to: "/setting"
    // }
    // {
    //   title: "Partners",
    //   htmlBefore: '<i class="material-icons">business_center</i>',
    //   to: "/partners",
    // },

    // {
    //   title: "Admins",
    //   htmlBefore: '<i class="material-icons">people_outline</i>',
    //   to: "/admins",
    // },
    // {
    //   title: "Categories",
    //   htmlBefore: '<i class="material-icons">category</i>',
    //   to: "/categories",
    // },
    // {
    //   title: "Inventories",
    //   htmlBefore: '<i class="material-icons">article</i>',
    //   to: "/inventories",
    // },

    // {
    //   title: "Inventory Sizes",
    //   htmlBefore: '<i class="material-icons">sort</i>',
    //   to: "/sizes",
    // },

    // {
    //   title: "Cockpit",
    //   htmlBefore: '<i class="material-icons">settings</i>',
    //   to: "/cockpit",
    // },
    // {
    //   title: 'Blog Posts',
    //   htmlBefore: '<i class="material-icons">vertical_split</i>',
    //   to: '/blog-posts'
    // },
    // {
    //   title: 'Add New Post',
    //   htmlBefore: '<i class="material-icons">note_add</i>',
    //   to: '/add-new-post'
    // },
    // {
    // 	title: 'Forms & Components',
    // 	htmlBefore: '<i class="material-icons">view_module</i>',
    // 	to: '/components-overview'
    // }
    // {
    //   title: 'Tables',
    //   htmlBefore: '<i class="material-icons">table_chart</i>',
    //   to: '/tables'
    // },
    // {
    //   title: 'User Profile',
    //   htmlBefore: '<i class="material-icons">person</i>',
    //   to: '/user-profile-lite'
    // },
    // {
    //   title: 'Errors',
    //   htmlBefore: '<i class="material-icons">error</i>',
    //   to: '/errors'
    // }
  ];
}
