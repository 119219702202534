import React from "react";
import { Button, ButtonGroup } from "shards-react";

function GradeCategoryListRow({
    currentPosts,
    sendGrade,
    title,
    deleteGrade,
    updateGrade,
    pageNo
}) {
    const renderRow = (Obj, i) => {
        return (
            <tr key={i}>
                <td>{i + 1 + (pageNo - 1) * 10}</td>
                <td>{Obj.category_name}</td>

                <td style={{ textAlign: "center" }}>
                        <Button className="action-btn" title="Delete" theme="danger" onClick={() => deleteGrade(Obj.id)}>
                            <i class="material-icons">
                                delete
                            </i>
                        </Button>
                        &nbsp;

                        <Button className="action-btn"
                            theme="info"
                            title="Edit"
                            onClick={() => {
                                updateGrade(Obj.id, Obj.category_name);
                            }}
                        >
                            <i class="material-icons">
                                edit
                            </i>
                        </Button>
                </td>
            </tr>
        );
    };
    const tableBody = currentPosts.map(renderRow);
    return (
        <tbody>
            {tableBody.length !== undefined ?
                tableBody
                : <tr>
                    <td colSpan="15" className="text-center noRec-found"> No record found</td>
                </tr>}
        </tbody>
    );
}
export default GradeCategoryListRow;
