import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";
import { useParams } from "react-router-dom";
import { RSSPDashboardAction } from "../flux/Action/RSSPDashboard";
import TelcoUserGraph from "../components/blog/TelcoUserGraph";
import MultilevelStats from "../components/common/MultilevalStats";
import PageTitle from "../components/common/PageTitle";
import SmallStats from "../components/common/SmallStats";
import { Store } from "../flux";
import DatePicker from "react-datepicker";
import moment from "moment";
import { TelecomUsersAction } from "../flux/Action/UsersAction";
import TelecomGraphFillter from "../components/common/TelecomGraphFillter";

const RSSPDashbaord = () => {
    const { id } = useParams()
    const [DashboraStatsLists, setDashboraStatsLists] = useState({});
    const [reload, setReload] = useState();
    const [currentPage, setcurrentPage] = useState(1);
    const [userList, setUserList] = useState([]);
    const [TotalSession, setTotalSession] = useState();
    const [filterData, setFilterData] = useState({
        date: "",
        month: null,
        defaultMonthSet: 1,
        year: new Date(),
        user_id: '',
    });

    console.log("filterDatafilterData", filterData);
    console.log("userList", userList);

    const handleStartDateChange = (value) => {
        console.log("value[0]", value[0]);
        if (value[1] === "date") {
            setFilterData({
                ...filterData,
                user_id: '',
                date: value[0],
                month: null,
                defaultMonthSet: 1,
                year: ""
            });
        }
        else if (value[1] === "month") {
            setFilterData({
                ...filterData,
                user_id: '',
                month: value[0],
                date: "",
                defaultMonthSet: 0
            });
        }
        else if (value[1] === "year") {
            var date = value[0]
            setFilterData({
                ...filterData,
                user_id: '',
                year: date,
                date: "",
                defaultMonthSet: 0
            });
        }
        else if (value[1] === "user") {
            var date = value[0]
            setFilterData({
                ...filterData,
                user_id: date,
                date: "",
                defaultMonthSet: 0
            });
        }
    }

    useEffect(() => {
        Store.addChangeListener(onChange2);
        TelecomUsersAction({});
    }, []);

    const onChange2 = () => {
        if (Store.TelecomUserList().status === 200) {
            setUserList(Store.TelecomUserList().data.data.users);
        }
    };


    useEffect(() => {
        Store.addChangeListener(onChange);
        RSSPDashboardAction(filterData, 1)
    }, [filterData]);

    const onChange = () => {
        if (Store.DashBoard_Stats().status === 200) {
            console.log(Store.DashBoard_Stats().data.data.GraphData.length, "thisss");
            setReload(!reload)
            setDashboraStatsLists(Store.DashBoard_Stats().data.data);
        }
    };

    console.log("DashboraStatsListsDashboraStatsLists", Object.keys(DashboraStatsLists).length > 0 && DashboraStatsLists);
    console.log("DashboraStatsLists.GraphData", DashboraStatsLists && DashboraStatsLists);

    useEffect(() => {
        if(Object.keys(DashboraStatsLists).length > 0){
            let total = DashboraStatsLists.GraphData.reduce((previousValue, currentValue) => {
                return previousValue + currentValue
            }, 0);
            setTotalSession(total)
        }
    }, [DashboraStatsLists])

    useEffect(() => {
        document.title = "MsingiPACK Cloud - Admin Portal";


        return () => {
            document.title = "";
        }
    }, [])

    const data = [
        // {
        //   label: "Total Content",
        //   value:
        //     DashboraStatsLists !== undefined
        //       ? DashboraStatsLists.tot_content
        //       : "Posts",
        //   attrs: { md: "6", sm: "6" }
        // },
        {
            label: "Total Recharges",
            value:
                DashboraStatsLists !== undefined
                    ? DashboraStatsLists.tot_recharges
                    : "hell",
            attrs: { md: "3", sm: "6" }
        },
        {
            label: "Total Access Session Frequency",
            value:
                DashboraStatsLists !== undefined
                    ? TotalSession
                    : "hell",
            attrs: { md: "3", sm: "6" }
        },
        {
            label: "Total Student Users ",

            value:
                DashboraStatsLists !== undefined
                    ? DashboraStatsLists.total_student_users
                    : "hell",

            attrs: { md: "3", sm: "6" }
        },
        {
            label: "Total Amount ",

            value:
                DashboraStatsLists !== undefined
                    ? DashboraStatsLists.tot_amount
                    : "hell",

            attrs: { md: "3", sm: "6" }
        }
    ];
    // data for populer session
    const data1 = [
        {
            label: "Highly Viewed Grade",
            value:
                DashboraStatsLists.highViewedGrade !== undefined
                    ? DashboraStatsLists.highViewedGrade.grade_name
                    : "Posts",
            attrs: { md: "6", sm: "6" },
            label1: "Highly Viewed Subject",
            value1:
                DashboraStatsLists.highViewedSubject !== undefined
                    ? DashboraStatsLists.highViewedSubject.subject_name
                    : "Posts",
            attrs: { md: "6", sm: "6" }
        },
    ];
    // data for avg session duratuion 
    const data2 = [
        {
            label: "Average Viewed Grade",
            value:
                DashboraStatsLists.highViewedGrade !== undefined
                    ? DashboraStatsLists.avgViewedGrade.grade_name
                    : "Posts",
            attrs: { md: "6", sm: "6" },
            label1: "Average Viewed Subject",
            value1:
                DashboraStatsLists.highViewedSubject !== undefined
                    ? DashboraStatsLists.avgViewedSubject.subject_name
                    : "Posts",
            attrs: { md: "6", sm: "6" }
        },
    ];

    // data for avg session duratuion 
    const data02 = [
        {
            label: "Active Account",
            value:
                DashboraStatsLists.highViewedGrade !== undefined
                    ? DashboraStatsLists.student_active_users
                    : "Posts",
            attrs: { md: "6", sm: "6" },
            label1: "Inactive Account",
            value1:
                DashboraStatsLists.highViewedSubject !== undefined
                    ? DashboraStatsLists.student_inactive_users
                    : "Posts",
            attrs: { md: "6", sm: "6" }
        },
    ];


    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const data3 = {
        title: "Users Overview",
        chartData: {
            hello: console.log(DashboraStatsLists.GraphData && typeof DashboraStatsLists.GraphData.length, "update workinh"),
            labels: DashboraStatsLists.GraphData && DashboraStatsLists.GraphData.length === 12 ? monthNames
                : DashboraStatsLists.GraphData && DashboraStatsLists.GraphData.length > 12 ?
                    Array.from(new Array(DashboraStatsLists.GraphData ? DashboraStatsLists.GraphData.length : 12), (_, i) => (i === 0 ? 1 : i)) : "",
            datasets: [
                {
                    label: Object.keys(DashboraStatsLists).length > 0 && DashboraStatsLists.graphxaxisData.length === 24 ? "Daily" :
                        Object.keys(DashboraStatsLists).length > 0 && DashboraStatsLists.graphxaxisData.length >= 28 ? "Monthly" :
                            Object.keys(DashboraStatsLists).length > 0 && DashboraStatsLists.graphxaxisData.length === 12 ? "Yearly" : '',
                    // label: "adssa",
                    fill: "start",
                    data: DashboraStatsLists.GraphData,
                    backgroundColor: "rgba(0,123,255,0.1)",
                    borderColor: "rgba(0,123,255,1)",
                    pointBackgroundColor: "#ffffff",
                    pointHoverBackgroundColor: "rgb(0,123,255)",
                    borderWidth: 1.5,
                    pointRadius: 0,
                    pointHoverRadius: 3
                }
            ]
        }
    };

    return (
        <>
            {DashboraStatsLists &&
                <Container fluid className="main-content-container px-4">
                    {/* Page Header */}
                    <Row noGutters className="page-header py-4">
                        <Col className="col-lg-6 mb-4">
                            <PageTitle
                                title="Overview"
                                subtitle="Dashboard"
                                className="text-sm-left mb-3"
                            />
                        </Col>
                        <Col className="col-lg-6">
                            <Row className="page-header">
                                <Col className="col-lg-3">
                                    <select
                                        className="form-control custom-select"
                                        onChange={(e) => handleStartDateChange([e.target.value, "user"])}>
                                        <option className="mb-2 form-control text-center" value="">Select RSSP User</option>
                                        {userList.map((obj, i) => {
                                            return (
                                                <>
                                                    <option className="mb-2 form-control text-center" value={obj.id}>
                                                        {obj.email}
                                                    </option>
                                                </>
                                            );
                                        })}
                                    </select>
                                </Col>
                                <Col className="col-lg-3">
                                    <DatePicker
                                        selected={filterData.date}
                                        onChange={(date) => handleStartDateChange([date, "date"])}
                                        placeholderText="Select Date"
                                    />
                                </Col>
                                <Col className="col-lg-3">
                                    <select
                                        className="form-control custom-select"
                                        onChange={(e) => handleStartDateChange([e.target.value, "month"])}>
                                        <option className="mb-2 form-control text-center" selected={filterData.defaultMonthSet == 1 ? true : false} value={`0`}>Select Month</option>
                                        {monthNames.map((obj, i) => {
                                            return (
                                                <>
                                                    <option className="mb-2 form-control text-center" value={i + 1} key={i}>
                                                        {obj}
                                                    </option>
                                                </>
                                            );
                                        })}
                                    </select>
                                </Col>
                                <Col className="col-lg-3">
                                    <DatePicker
                                        selected={filterData.year}
                                        onChange={(date) => handleStartDateChange([date, "year"])}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        placeholderText="Select Year"
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* Small Stats Blocks */}
                    <Row>
                        {DashboraStatsLists
                            ? data.map((stats, idx) => (
                                <Col className="col-lg-3 mb-4" key={idx} {...stats.attrs}>
                                    <SmallStats
                                        id={`small-stats-${idx}`}
                                        label={stats.label}
                                        value={stats.value && stats.value.toLocaleString()}
                                    />
                                </Col>
                            ))
                            : "hello"}
                    </Row>
                    <Row>
                        {DashboraStatsLists.GraphData && data3.chartData.labels &&

                            <Col lg="8" md="12" sm="12" className="mb-4">
                                {console.log("dddddd", data3.chartData.labels)}
                                <TelcoUserGraph data={data3} title="User Overview" reload={reload} filterDisable={true} />
                            </Col>
                        }
                        {console.log("DashboraStatsLists", DashboraStatsLists)}

                        <Col lg="4" md="12" sm="12" className="mb-4">
                            <Col lg="12" md="12" sm="12" className="mb-4">
                                {DashboraStatsLists &&
                                    <>
                                        <TelecomGraphFillter className="my-2" data={DashboraStatsLists} />
                                        {/* <TopReferrals className="mb-4" /> */}
                                    </>
                                }
                            </Col>
                            {DashboraStatsLists
                                && data1.map((stats, idx) => (
                                    <Col lg="12" md="12" sm="12" className="mb-4">
                                        <MultilevelStats id={`small-stats-${idx}`}
                                            label={stats.label}
                                            value={stats.value}
                                            label1={stats.label1}
                                            value1={stats.value1}
                                            heading="Popular Session"
                                        />
                                    </Col>
                                ))
                            }

                            {/* Users by Device */}
                            {DashboraStatsLists
                                && data2.map((stats, idx) => (
                                    <Col lg="12" md="12" sm="12" className="mb-4">
                                        <MultilevelStats id={`small-stats-${idx}`}
                                            label={stats.label}
                                            value={stats.value}
                                            label1={stats.label1}
                                            value1={stats.value1}
                                            heading="Average Session Duration"
                                        />
                                    </Col>
                                ))
                            }

                            {/* Users by Device */}
                            {/* {DashboraStatsLists
                  && data02.map((stats, idx) => (
                    <Col lg="12" md="12" sm="12" className="mb-4">
                      <MultilevelStats id={`small-stats-${idx}`}
                        label={stats.label}
                        value={stats.value}
                        label1={stats.label1}
                        value1={stats.value1}
                        heading="Student Account Analysis"
                      />
                    </Col>
                  ))
                } */}
                        </Col>
                    </Row>
                    <Row>
                        {/* Users by Device */}
                    </Row>
                </Container >}
        </>
    );
};
RSSPDashbaord.propTypes = {
    /**
     * The small stats dataset.
     */
    smallStats: PropTypes.array
};

export default RSSPDashbaord;