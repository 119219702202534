import React, { useRef, useState } from "react";
import { Button, Container, FormInput } from "shards-react";
import PopUp from "../../Popup/PopUpGrade";
import axios from "axios";
import Cookies from "js-cookie";
import api from "../../../api";
import HandleError from "../../../utils/HandleError";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
export const TopicsListRow = ({
    listData,
    setListData,
    setReloadList,
    ReloadList,
    setPaymentStatus,
    PaymentStatus,
    setshowButtons,
    setshowReloadButtons,
    showReloadButtons,
    setUpdateTopic,
    updateTopic
}) => {
    const history = useHistory();
    let { id } = useParams();
    // delete free trails content
    // const [listData, setListData] = React.useState(listDatas)
    const [isdeletepopup, setisdeletepopup] = useState(false);
    const [UserId, setUserId] = useState([])
    // update free trial content
    const [updateContent, setUpdateContent] = useState(false)
    // const [openPopup, setopenPopup] = useState(false)
    const [contentData, setContentData] = useState()
    const [error, setError] = useState("")

    // console.log("chooseFile.currentchooseFile.current", chooseFile.current);

    let sourceElement = null

    /* change opacity for the dragged item. 
    remember the source item for the drop later */
    const handleDragStart = (event) => {
        event.target.style.opacity = 0.5
        sourceElement = event.target
        event.dataTransfer.effectAllowed = 'move'
    }

    /* do not trigger default event of item while passing (e.g. a link) */
    const handleDragOver = (event) => {
        event.preventDefault()
        event.dataTransfer.dropEffect = 'move'
    }

    /* add class .over while hovering other items */
    const handleDragEnter = (event) => {
        event.target.classList.add('over')
    }

    /* remove class .over when not hovering over an item anymore*/
    const handleDragLeave = (event) => {
        event.target.classList.remove('over')
    }

    const handleDrop = (event) => {
        /* prevent redirect in some browsers*/
        event.stopPropagation()

        /* only do something if the dropped on item is 
        different to the dragged item*/
        console.log("firstChildfirstChildfirstChildfirstChild", event.target.firstChild);
        if (sourceElement !== event.target) {
            /* remove dragged item from list */
            const list = listData.filter((item, i) =>
                i.toString() !== sourceElement.id)

            /* this is the removed item */
            const removed = listData.filter((item, i) =>
                i.toString() === sourceElement.id)[0]

            /* insert removed item after this number. */
            let insertAt = Number(event.target.parentNode.id)
            console.log();

            console.log("removedremovedremovedremoved", event.target);
            console.log('list with item removed', list)
            console.log('removed:  line', removed)
            console.log('insertAt index', insertAt)

            let tempList = []

            /* if dropped at last item, don't increase target id by +1. 
               max-index is arr.length */
            if (insertAt >= list.length) {
                tempList = list.slice(0).concat(removed)
                setListData(tempList)
                event.target.classList.remove('over')
            } else if ((insertAt < list.length)) {
                /* original list without removed item until the index it was removed at */
                const newList = list.slice(0, insertAt).concat(removed)
                // console.log('nothing happened', tempList)
                // console.log('tempList', tempList)
                console.log('insert the rest: ', list.slice(insertAt))

                /* add the remaining items to the list */
                tempList = newList.concat(list.slice(
                    insertAt))

                /* set state to display on page */
                setListData(tempList)
                event.target.classList.remove('over')
            }
            sendUpdatedList(tempList);
        }
        else console.log('nothing happened')
        event.target.classList.remove('over')
    }

    const handleDragEnd = (event) => {
        event.target.style.opacity = 1
        console.log('-------------------------------------------------------------')
    }

    const sendUpdatedList = async (tempList) => {
        let data = await tempList.map(function (ele) {
            return ele.id
        });
        console.log("datadatadatadatadata", data);
        var token = JSON.parse(Cookies.get("auth"));
        axios({
            url: api.baseURL + `/topic_arrange_order`,
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token
            },
            data: JSON.stringify({ "arranged_topic_ids": data })
        })
            .then(res => {
                if (res) {

                }
            })
            .catch(error => {
                console.log("res", error.response);
                HandleError(error);
            });
    }


    /* create list of items */
    const listItems = () => {
        console.log("i m listdata", listData);
        return listData.map((Obj, i) => (

            <tr id={i}
                className='dnd-list'
                draggable='true'
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onDragEnd={handleDragEnd}>
                <td id={i}>{i + 1}</td>
                <td id={i} style={{ textAlign: "center" }}>{Obj.topic_name}</td>
                <td style={{ textAlign: "center" }}>{Obj.subject_name}</td>
                <td style={{ textAlign: "center" }}>{(updateTopic && UserId == Obj.id) ? <img className="Header-logo" src="/progressloader.gif" style={{ width: "10%" }} alt="Logo" /> : Obj.content_url ? Obj.content_url.slice(0, 25) + "......." : Obj.upload_status == 0 ? "Failed..." : <img className="Header-logo" src="/progressloader.gif" style={{ width: "10%" }} alt="Logo" />}</td>
                <td style={{ textAlign: "center" }}>
                    <Button
                        className="action-btn"
                        title="View"
                        onClick={() => {
                            Obj.content_url && sendGrade(Obj.id)
                        }}
                        disabled={!Obj.content_url ? "disabled" : ""}
                    >
                        <i class="material-icons">
                            visibility
                        </i>
                    </Button>
                    &nbsp;
                    <Button
                        className="action-btn"
                        theme="danger"
                        title="Delete"
                        onClick={() => OpenDeletePopup(Obj.id)}
                        disabled={UserId == Obj.id ? "disabled" : ""}
                    >
                        <i class="material-icons">
                            delete
                        </i>
                    </Button>
                    &nbsp;
                    <Button
                        className="action-btn"
                        theme="info"
                        title="Edit"
                        onClick={() => OpenUpdatePopup(Obj)}
                    >
                        <i class="material-icons">
                            edit
                        </i>
                    </Button>
                </td>
            </tr>

        )
        )
    }
    const OpenDeletePopup = (id) => {
        setisdeletepopup(true)
        setUserId(id)
    }

    const HideDelteModals = () => {
        setisdeletepopup(false)
        setUserId()
    }
    const body1 = () => {
        return (
            <>
                <Container>
                    Are you sure you want to delete this Topic
                </Container>
            </>
        );
    };
    const popChangebody1 = body1();

    const callDeleteApi = () => {
        var token = JSON.parse(Cookies.get("auth"));
        let data1 = { "topic_id": UserId }
        setisdeletepopup(false)
        setshowReloadButtons(true)
        axios({
            url: api.baseURL + `/delete_topic`,
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token
            },
            data: JSON.stringify(data1)
        })
            .then(res => {
                console.log("DFGFDGFDGDFGDFGDF");
                if (res.data.status === "success") {
                    setshowReloadButtons(false)
                    setReloadList(!ReloadList)
                    // setLoading(false)
                    // setPaymentStatus(!PaymentStatus)
                    toast.success(res.data.message)
                }
            })
            .catch(error => {
                // setLoading(false)
                HandleError(error);
            });
    }

    // view content button
    const sendGrade = (content_id) => {
        history.push({
            pathname: `/content-view/` + content_id,
        });
    };

    // update
    const OpenUpdatePopup = (data) => {
        console.log(data, "dataaaa")
        setContentData({ ...contentData, subjectName: data.topic_name })
        setUpdateContent(true)
        setUserId(data.id)
    }

    const HideUpdatePopup = () => {
        setUpdateContent(false)
        setUserId()
    }

    const updatepopUpFunction = () => {
        return (
            <>
                <Container>
                    <>
                        <p size="sm" className="mb-1 text-danger" value={error}>{error}</p>
                        <FormInput
                            size="sm"
                            type="text"
                            placeholder="Topic Name"
                            className="mb-2"
                            value={contentData && contentData.subjectName}
                            onChange={e => {
                                setContentData({ ...contentData, "subjectName": e.target.value });
                            }}
                        />
                        <FormInput type="file" accept=".zip" onChange={(e) => {
                            setContentData({ ...contentData, "Pdf": e.target.files[0] });
                            // setpdfValidate(false)
                        }} />
                    </>
                </Container>
            </>
        );
    };
    const updatepopUpbody = updatepopUpFunction();


    const AddContentApi = () => {
        console.log("fhusdgfhsdf");
        if (contentData && contentData.subjectName) {
            let formData = new FormData();
            if (contentData && contentData.Pdf) {
                formData.append("topic_content", contentData.Pdf);
                setUpdateTopic(true)
            }
            formData.append("topic_name", contentData.subjectName);
            formData.append("topic_id", UserId);
            setError("")
            console.log("DFGFDGFDGDFGDFGDF");
            var token = JSON.parse(Cookies.get("auth"));
            setUpdateContent(false)
            setshowReloadButtons(true)
            axios({
                url: api.baseURL + `/update_topic`,
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Accept: "application/json",
                    Authorization: "BEARER " + token.data.token
                },
                data: formData
            })
                .then(res => {
                    console.log("DFGFDGFDGDFGDFGDF");
                    if (res.data.status === "success") {
                        setshowReloadButtons(false)
                        setUpdateTopic(false)
                        setPaymentStatus(!PaymentStatus)
                        setContentData()
                        // setshowButtons(false)
                        toast.success(res.data.message)
                        setUserId()
                    } if (res.data.status === "error") {
                        setshowButtons(false)
                        setshowReloadButtons(false)
                        setUpdateTopic(false)
                        setContentData()
                        setUserId()
                    }
                })
                .catch(error => {
                    // setshowButtons(false)
                    setUpdateTopic(false)
                    setContentData()
                    setshowReloadButtons(false)
                    HandleError(error);
                    setUserId()
                });
        }
        else {
            setError("All fields are required*")
        }
    }

    return (
        <>

            <PopUp
                title={"Delete Topic"}
                open={isdeletepopup}
                hideModal={HideDelteModals}
                body={popChangebody1}
                callApi={callDeleteApi}
                showButton={true}
                ButtonNmae={"Yes"}
            />
            <PopUp
                title={"Update Topic"}
                open={updateContent}
                hideModal={HideUpdatePopup}
                body={updatepopUpbody}
                callApi={AddContentApi}
                showButton={true}
                ButtonNmae={"Yes"}
            />


            {listData && listItems()}


        </>
    )
}

