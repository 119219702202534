import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody
} from "shards-react";

const PopUp = ({
  open,
  hideModal,
  body,
  title,
  callApi,
  showButton,
  ButtonNmae
}) => {
  return (
    <div>
      <Modal open={open} toggle={hideModal}>
        <ModalHeader>{title}</ModalHeader>
        {/* <Button onClick={props.hideModal}>Click Me!</Button> */}
        <ModalBody> {body}</ModalBody>

        <ModalFooter>
          {showButton && (
            <Button theme="success" onClick={callApi}>
              {ButtonNmae ? ButtonNmae : "Add"}
            </Button>
          )}
          <Button theme="danger" onClick={hideModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PopUp;
