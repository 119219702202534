import React from 'react';

const Loader = ({ message }) => {
  return (
    <>
      <div className="text-center justify-content-center">
        <div className="spinner-border m-5" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        {message &&
          <div>
            <h5>{message}</h5>
          </div>}
      </div>
    </>
  );
};

export default Loader;
