import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container,
  Form,
  FormGroup,
  FormInput,
  FormTextarea,
  Button
} from "shards-react";
import Store from "../../flux/store";
import PageHeader from "../../components/PageHeader";
import { useParams, useHistory } from "react-router-dom";
import ReactQuill, { Quill, editor } from "react-quill";
import { CMSStoreAction, CMSUpdateAction } from "../../flux/Action/CMS";

const NewCMSDraft = ({ title }) => {
  const [CMSList, setCMSList] = useState([]);
  const [CMSListUpdatvalue, setCMSUpdatevalue] = useState({
    title: "",
    content: ""
  });
  const [CMSListUpdateResp, setCMSUpdateResp] = useState([]);

  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    Store.addChangeListener(onChange);
    CMSStoreAction(id);
  }, [CMSListUpdateResp]);

  const onChange = () => {
    if (Store.CMS_Store_Lists().status === 200) {
      setCMSList(Store.CMS_Store_Lists().data.data);
      setCMSUpdatevalue({
        ...CMSListUpdatvalue,
        content: Store.CMS_Store_Lists().data.data.content
      });
    }
    // Store.removeChangeListener(onChange);
  };

  const onUpdate = () => {
    if (Store.CMS_Update_Lists().status === 201) {
      setCMSUpdateResp(Store.CMS_Update_Lists());
    }
  };

  const udapate = () => {
    Store.addChangeListener(onUpdate);
    const value = {
      id: CMSList.id,
      title: CMSListUpdatvalue.title ? CMSListUpdatvalue.title : CMSList.title,
      content: CMSListUpdatvalue.content
        ? CMSListUpdatvalue.content
        : CMSList.title
    };
    CMSUpdateAction(value);
  };

  const handleChange = html => {
    console.log(html, " E.target");
    setCMSUpdatevalue({
      ...CMSListUpdatvalue,
      content: html
    });
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      ['clean']
    ]
  };

  const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image'];

  const _handleChange = (value) => {
    console.log("value", value)
    setCMSUpdatevalue({
      ...CMSListUpdatvalue,
      content: value
    })
  }

  const GoBack = () => {
    history.push("/cms")
  }
  return (
    <>
      {console.log(CMSListUpdatvalue, "CMSListUpdatvalueCMSListUpdatvalue")}
      <Container fluid className="main-content-container">
        <PageHeader
          title={"Edit CMS"}
          subtitle={"MsingiPACK Cloud"}
          name={"Back"}
          Status={true}
          onsubmit={GoBack}
        />
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 ">
                <div>
                  <Card small className="h-100">
                    {/* Card Header */}
                    <CardHeader className="border-bottom">
                      <h6 className="m-0">{title}</h6>
                    </CardHeader>
                    <CardBody className="d-flex flex-column">
                      <Form className="quick-post-form">
                        {/* Title */}
                        <CardBody className="p-0 ">
                          <h6 className="m-0">Title</h6>
                        </CardBody>
                        <FormGroup>
                          <FormInput
                            placeholder="Brave New World"
                            defaultValue={CMSList.title}
                            onChange={e =>
                              setCMSUpdatevalue({
                                ...CMSListUpdatvalue,
                                title: e.target.value
                              })
                            }
                          />
                        </FormGroup>
                        {/* Body */}
                        <CardBody className="p-0 ">
                          <h6 className="m-0">Contant</h6>
                        </CardBody>
                        <FormGroup>
                          {/* <FormTextarea
                            placeholder="Words can be like X-rays if you use them properly..."
                            defaultValue={CMSList.content}
                            onChange={e =>
                              setCMSUpdatevalue({
                                ...CMSListUpdatvalue,
                                content: e.target.value
                              })
                            }
                          /> */}
                          <ReactQuill
                            className="add-new-post__editor mb-1"
                            name="text"
                            modules={modules}
                            formats={formats}
                            value={CMSListUpdatvalue.content}
                            onChange={_handleChange}

                          />
                          {/* <ReactQuill
                            className="add-new-post__editor mb-1"
                            placeholder="Words can be like X-rays if you use them properly..."
                            value={CMSListUpdatvalue.content}
                            onChange={handleChange}
                          /> */}
                        </FormGroup>
                        {/* Create Draft */}
                        <FormGroup className="mb-0">
                          <Button theme="accent" onClick={udapate}>
                            Update Draft
                          </Button>
                        </FormGroup>
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
NewCMSDraft.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

NewCMSDraft.defaultProps = {
  title: "New Draft"
};

export default NewCMSDraft;
