import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Nav, NavItem, NavLink } from "shards-react";
import { Link } from "react-router-dom";
import "./MainSidebar.css"

var date = new Date()
const MainFooter = ({ contained, menuItems, copyright }) => {
  return (
    <>
    <footer className="main-footer d-flex p-2 px-3 bg-white border-top">
      <Container fluid={contained}>
        <Row>
          {/* <Nav>
            {menuItems.map((item, idx) => (
              <NavItem key={idx}>
                <NavLink tag={props => <Link {...props} />} to={item.to}>
                  {item.title}
                </NavLink>
              </NavItem>
            ))}
          </Nav> */}
          <div className="col-12">
          <span className="copyright text-center d-block">{copyright}</span>
          </div>
        </Row>
      </Container>
    </footer>
    </>
  )
}

MainFooter.propTypes = {
  /**
   * Whether the content is contained, or not.
   */
  contained: PropTypes.bool,
  /**
   * The menu items array.
   */
  menuItems: PropTypes.array,
  /**
   * The copyright info.
   */
  copyright: PropTypes.string
};

MainFooter.defaultProps = {
  contained: false,
  copyright: `Copyright © ${date.getFullYear()} MsingiPACK`,
  menuItems: [
    {
      title: "Home",
      to: "#"
    },
    {
      title: "Services",
      to: "#"
    },
    {
      title: "About",
      to: "#"
    },
    {
      title: "Products",
      to: "#"
    },
    {
      title: "Blog",
      to: "#"
    }
  ]
};

export default MainFooter;
