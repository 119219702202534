import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import PageHeader from "../components/PageHeader";
import { Store } from "../flux";
import { ContactListRow } from "../components/common/ListRow/ContactListrow";
import PaginatedItems from "../components/pagination";
import { ContactAction } from "../flux/Action/ContactusAction";
const ContactUs = () => {
  const [userList, setUserList] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [currentPosts, setcurrentPosts] = useState();
  const [isopen, setisopen] = useState(false);

  useEffect(() => {
    Store.addChangeListener(onChange);
    ContactAction(currentPage);
  }, [isopen, setcurrentPage]);

  // useEffect(() => {
  //   //to get 10 current posts
  //   console.log(userList, "userList");
  //   const indexOfLastPost = currentPage * postsPerPage;
  //   const indexOfFirstPost = indexOfLastPost - postsPerPage;
  //   const currentPosts = userList.slice(indexOfFirstPost, indexOfLastPost);
  //   setcurrentPosts(currentPosts);
  // }, [currentPage, userList]);

  const onChange = () => {
    console.log(Store.ListContactUs(), "delete");
    if (Store.ListContactUs().status === 200) {
      setUserList(Store.ListContactUs().data.data);
      setcurrentPosts(Store.ListContactUs().data.data);
    }
  };

  const HandlePageClick = data => {
    setcurrentPage(data.selected + 1);
  };

  const deleteContact = () => {
    console.log("contact");
  };
  return (
    <>
      <Container fluid className="main-content-container px-4">
        <PageHeader
          title={"Contact Us"}
          subtitle={"MsingiPACK Cloud"}
          name={"Add users"}
          Status={false}
        />
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Contact Us</h6>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <div className="table-responsive">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          S.No
                        </th>
                        <th scope="col" className="border-0">
                          Name
                        </th>
                        <th scope="col" className="border-0">
                          Email
                        </th>
                        <th scope="col" className="border-0">
                          Message
                        </th>
                        <th scope="col" className="border-0">
                          Question
                        </th>
                        {/* <th
                          scope="col"
                          className="border-0"
                          style={{ textAlign: "center" }}
                        >
                          action
                        </th> */}
                      </tr>
                    </thead>
                    <ContactListRow
                      currentPosts={userList}
                      deleteContact={deleteContact}
                      title={" View Users"}
                    />
                  </table>
                </div>
                {userList > 10 &&
                  <PaginatedItems
                    List={currentPosts}
                    HandlePageClick={HandlePageClick}
                  />}

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactUs;
