import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button,
    ButtonGroup
} from "shards-react";
import PageHeader from "../../components/PageHeader";

const listData = [
    {
        id: 1,
        name: "Tester1",
        email: "test@yopmail.com",
        phone: "0909090909",
        created_at: "02/10/2020"
    },
    {
        id: 2,
        name: "Tester",
        email: "test@yopmail.com",
        phone: "0909090909",
        created_at: "02/10/2020"
    },
    {
        id: 7,
        name: "Tester",
        email: "test@yopmail.com",
        phone: "0909090909",
        created_at: "02/10/2020"
    },
    {
        id: 3,
        name: "Tester",
        email: "test@yopmail.com",
        phone: "0909090909",
        created_at: "02/10/2020"
    },
    {
        id: 4,
        name: "Tester",
        email: "test@yopmail.com",
        phone: "0909090909",
        created_at: "02/10/2020"
    },
    {
        id: 5,
        name: "Tester",
        email: "test@yopmail.com",
        phone: "0909090909",
        created_at: "02/10/2020"
    },
    {
        id: 6,
        name: "Tester",
        email: "test@yopmail.com",
        phone: "0909090909",
        created_at: "02/10/2020"
    }
];
const ClassList = () => {
    //   useEffect(() => {
    //     let { loginStatus } = this.state;
    //     if (loginStatus === undefined) {
    //       userLoginStatus().then(
    //         (value) => {
    //           this._fetchListData();
    //         },
    //         (reason) => {
    //           this.setState({ loginStatus: false });
    //         }
    //       );
    //     }
    //   })

    //   _dismissAlert() {
    //     this.setState({ visible: false });
    //   }

    //   _fetchListData = () => {
    //     APIService.fetchAllUsers().then(
    //       (data) => {
    //         this.setState({
    //           loginStatus: true,
    //           loading: false,
    //           listData: data,
    //         });
    //       },
    //       (error) => {
    //         this.setState({
    //           loading: false,
    //           errorMessage:
    //             error.errorMessage ||
    //             "Cannot connect to server. Either there's a problem with your internet connection or the server is down.",
    //           errorCode: error.errorStatus || "NOT CONNECTED",
    //         });
    //       }
    //     );
    //   };

    const renderRow = (Obj, i) => {
        return (
            <tr key={i}>
                <td>{Obj.id}</td>
                {/* <td onClick={() => this.setState({redirect:true, redirectPath: "/users/" + Obj.id + "/detail"})}>{Obj.id}</td> */}

                {/* {Obj.name ? Obj.name : "NA"} */}
                <td>{Obj.name}</td>
                <td>{Obj.email ? Obj.email : "NA"}</td>
                <td>{Obj.phone}</td>
                <td>{Obj.created_at}</td>
                {/* <td>{Obj.created_at}</td> */}
                <td align="right">
                    <ButtonGroup size="sm">
                        <Button onClick={() => { }}>Details</Button>
                    </ButtonGroup>
                </td>
            </tr>
        );
    };
    const tableBody = listData.length && listData.map(renderRow);

    // if (this.state.redirect) {
    //   return (
    //     <Redirect
    //       to={{
    //         pathname: redirectPath,
    //         state: redirectData,
    //       }}
    //     />
    //   );
    // }
    // if (errorMessage && errorCode) {
    //   return <APIError code={errorCode}>{errorMessage}</APIError>;
    // }
    // if (loading || loginStatus === undefined) {
    //   return <Loader />;
    // } else if (loginStatus) {
    return (
        <>
            {/* <Alert
            theme={alertStyle || "primary"}
            dismissible={this._dismissAlert}
            open={visible}
          >
            <i className={alertIcon} /> {alertMessage}
          </Alert> */}
            <Container fluid className="main-content-container px-4">
                <PageHeader
                    title={"Classs"}
                    subtitle={"MsingiPACK Cloud"}
                    name={"Add Class"}
                />
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="border-bottom">
                                <h6 className="m-0">Classes</h6>
                            </CardHeader>
                            <CardBody className="p-0 pb-3">
                                <div className="table-responsive">
                                    <table className="table mb-0">
                                        <thead className="bg-light">
                                            <tr>
                                                <th scope="col" className="border-0">
                                                    id
                                                </th>
                                                <th scope="col" className="border-0">
                                                    Name
                                                </th>
                                                <th scope="col" className="border-0">
                                                    Email
                                                </th>
                                                <th scope="col" className="border-0">
                                                    Phone
                                                </th>
                                                <th scope="col" className="border-0">
                                                    Created
                                                </th>
                                                <th scope="col" className="border-0" align="right" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableBody.length !== undefined ?
                                                tableBody
                                                : <tr>
                                                    <td colSpan="15" className="text-center noRec-found"> No record found</td>
                                                </tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
    //     } else {
    //       return <Redirect to="/login" />;
    //     }
};

export default ClassList;
