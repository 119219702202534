import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, FormInput, Container } from "shards-react";
import CommonModal from "../CommonModal";
import { TelecomUserDetailsAction, UserDetailsAction } from "../../../flux/Action/UsersAction";
import { Store } from "../../../flux";
import Loader from "../loader";
import PopUp from "../../Popup/PopUpGrade";
import { useHistory } from "react-router-dom";
export const TelecomUserListRow = ({
    currentPosts,
    userStatus,
    updateUser,
    pageNo,
    ShowUsers,
    callApi,
    regenerateToken
}) => {

    const navigate = useHistory();
    const [open, setOpen] = useState(false);
    const [inputType, setInputType] = useState(true);
    const [userDetails, setUserDetails] = useState();
    const [isHide, setisHide] = useState(true);
    const [isValidationPopup, setisValidationPopup] = useState(false);
    const [Userid, setUserid] = useState();
    const [userValue, setUserValue] = useState({
        name: "",
        email: "",
        mob_no: "",
        company_name: "",
        physical_address: "",
        postal_address: "",
        city_town: ""
    });

    useEffect(() => {
        if (userDetails) {
            console.log("Fsdfs", userDetails);
            setUserValue({
                ...userValue,
                name: userDetails.name,
                email: userDetails.email,
                mob_no: userDetails.mob_no,
                company_name: userDetails.company_name,
                physical_address: userDetails.physical_address,
                postal_address: userDetails.postal_address,
                city_town: userDetails.city_town,
                subs_amount: userDetails.subs_amount,
            });
        }
    }, [userDetails]);

    const onChange = () => {
        setUserDetails(Store.UserDetails().data.data);
    };

    const ShowInputModal = id => {
        setInputType(true);
        if (!open) {
            Store.addChangeListener(onChange);
            let formData = new FormData();
            formData.append("user_id", id);
            TelecomUserDetailsAction(formData);
        }
        setOpen(!open);
        console.log("userValue", userValue);
    };
    
    const ShowModal = id => {
        setInputType(false);
        if (!open) {
            Store.addChangeListener(onChange);
            let formData = new FormData();
            formData.append("user_id", id);
            TelecomUserDetailsAction(formData);
        }
        setOpen(!open);
        setUserDetails();
    };

    const HideModals = id => {
        setisValidationPopup(false)
    };

    const body2 = () => {
        return (

            <p size="sm" className="mb-1 text" >Are You Sure You Want To Approve This Email</p>

        );
    };
    const popChangebody = body2();



    const renderRow = (Obj, i) => {
        return (
            <tr key={i}>
                <td>{i + 1 + (pageNo - 1) * 10}</td>
                <td>{Obj.company_name}</td>
                <td>{Obj.email}</td>
                <td>{Obj.mob_no ? Obj.mob_no : "-"}</td>
                <td>{Obj.subs_amount ? Obj.subs_amount : "-"}</td>
                <td>{Obj.user_unq_identity} <button onClick={() => regenerateToken(Obj.id)} style={{display: "inline-flex", justifyContent: "center", alignItems: "center", padding: "3px", border: "none"}}><i class="material-icons"> loop </i></button></td>
                <td>
                    {" "}
                    <Button
                        onClick={() => {
                            userStatus(Obj.id, Obj.status)

                        }}
                        theme={Obj.status === "Active" ? "success" : "danger"}
                    >
                        {Obj.status}
                    </Button>{" "}
                </td>

                <td style={{ textAlign: "center" }}>
                    {/* <Button className="action-btn" title="Verify Email" theme="success"
                        onClick={() => {
                            setisValidationPopup(true)
                            setUserid(Obj.id)
                        }}
                    >
                        <i class="material-icons">
                            domain_verification
                        </i>
                    </Button> */}
                    &nbsp;
                    <Button className="action-btn"
                        title="Edit"
                        theme="info"
                        onClick={() => {
                            ShowInputModal(Obj.id);
                            setisHide(true);
                        }}
                    >
                        {" "}
                        <i class="material-icons">
                            edit
                        </i>
                    </Button>
                    &nbsp;
                    <Button className="action-btn"
                        title="View"
                        onClick={() => navigate.push(`telecom-user-overview/${Obj.id}`)}
                        // onClick={() => {
                        //     ShowModal(Obj.id);
                        //     setisHide(false);
                        // }}
                    >
                        <i class="material-icons">
                            visibility
                        </i>
                    </Button>
                    &nbsp;
                    <Button className="action-btn" title="View/manage users" theme="success"
                        onClick={() => {
                            ShowUsers(Obj.id)
                        }}
                    >
                        <i class="material-icons">
                            grid_view
                        </i>
                    </Button>
                </td>
            </tr >
        );
    };
    const tableBody = currentPosts.length && currentPosts.map(renderRow);

    return (
        <>
            <tbody>
                {tableBody.length !== undefined ?
                    tableBody
                    : <tr>
                        <td colSpan="15" className="text-center noRec-found"> No record found</td>
                    </tr>}
            </tbody>

            <PopUp
                title={"Email Verification"}
                open={isValidationPopup}
                hideModal={HideModals}
                body={popChangebody}
                callApi={() => {
                    callApi(Userid)
                    setisValidationPopup(false)
                }}
                showButton={true}
                ButtonNmae={"Yes"}
            />
            <PopUp
                title={inputType ? "Edit RSSP User" : "View User"}
                open={open}
                hideModal={ShowModal}
                showButton={isHide}
                callApi={() => {
                    updateUser(userDetails.id, userValue);
                    setOpen(false);
                    setUserDetails();
                }}
                ButtonNmae={"Update"}
                body={
                    userDetails ? (
                        <>
                            <div className="row mb-3">
                                <div className="col-6 mb-2">
                                    <strong>Full Name:</strong>
                                </div>
                                {inputType ? (
                                    <FormInput
                                        value={userValue.name}
                                        className="col-6 mb-2"
                                        onChange={e => {
                                            setUserValue({
                                                ...userValue,
                                                name: e.target.value
                                            });
                                        }}
                                    />
                                ) : (
                                    <div className="col-6 mb-2">{userDetails.name ? userDetails.name : "-"}</div>
                                )}
                                <div className="col-6 mb-2">
                                    <strong>Email Address:</strong>
                                </div>
                                {inputType ? (
                                    <FormInput
                                        value={userValue.email}
                                        className="col-6 mb-2"
                                        disabled={true}
                                        // onChange={e => {
                                        //     setUserValue({
                                        //         ...userValue,
                                        //         email: e.target.value
                                        //     });
                                        // }}
                                    />
                                ) : (
                                    <div className="col-6 mb-2">{userDetails.email ? userDetails.email : "-"}</div>
                                )}
                                <div className="col-6 mb-2">
                                    <strong>Mobile No:</strong>
                                </div>
                                {inputType ? (
                                    <FormInput
                                        value={userValue.mob_no}
                                        className="col-6 mb-2"
                                        onChange={e => {
                                            setUserValue({
                                                ...userValue,
                                                mob_no: e.target.value
                                            });
                                        }}
                                    />
                                ) : (
                                    <div className="col-6 mb-2">{userDetails.mob_no ? userDetails.mob_no : "-"}</div>
                                )}
                            </div>
                            <div className="row">
                                <div className="col-6 mb-2">
                                    <strong>Company name:</strong>
                                </div>
                                {inputType ? (
                                    <FormInput
                                        value={userValue.company_name}
                                        className="col-6 mb-2"
                                        disabled={true}
                                        // onChange={e => {
                                        //     setUserValue({
                                        //         ...userValue,
                                        //         company_name: e.target.value
                                        //     });
                                        // }}
                                    />
                                ) : (
                                    <div className="col-6 mb-2">{userDetails.company_name ? userDetails.company_name : "-"}</div>
                                )}
                                <div className="col-6 mb-2">
                                    <strong>Physical Address:</strong>
                                </div>
                                {inputType ? (
                                    <FormInput
                                        value={userValue.physical_address}
                                        className="col-6 mb-2"
                                        onChange={e => {
                                            setUserValue({
                                                ...userValue,
                                                physical_address: e.target.value
                                            });
                                        }}
                                    />
                                ) : (
                                    <div className="col-6 mb-2">
                                        {userDetails.physical_address ? userDetails.physical_address : "-"}
                                    </div>
                                )}
                                <div className="col-6 mb-2">
                                    <strong>Postal Address:</strong>
                                </div>
                                {inputType ? (
                                    <FormInput
                                        value={userValue.postal_address}
                                        className="col-6 mb-2"
                                        onChange={e => {
                                            setUserValue({
                                                ...userValue,
                                                postal_address: e.target.value
                                            });
                                        }}
                                    />
                                ) : (
                                    <div className="col-6 mb-2">{userDetails.postal_address ? userDetails.postal_address : "-"}</div>
                                )}
                                <div className="col-6 mb-2">
                                    <strong>City / Town:</strong>
                                </div>
                                {inputType ? (
                                    <FormInput
                                        value={userValue.city_town}
                                        className="col-6 mb-2"
                                        onChange={e => {
                                            setUserValue({
                                                ...userValue,
                                                city_town: e.target.value
                                            });
                                        }}
                                    />
                                ) : (
                                    <div className="col-6 mb-2">{userDetails.city_town ? userDetails.city_town : "-"}</div>
                                )}
                                <div className="col-6 mb-2">
                                    <strong>Subscription Amount:</strong>
                                </div>
                                {inputType ? (
                                    <FormInput
                                        value={userValue.subs_amount}
                                        className="col-6 mb-2"
                                        onChange={e => {
                                            setUserValue({
                                                ...userValue,
                                                subs_amount: e.target.value
                                            });
                                        }}
                                    />
                                ) : (
                                    <div className="col-6 mb-2">{userDetails.subs_amount ? userDetails.subs_amount : "-"}</div>
                                )}
                            </div>
                        </>
                    ) : (
                        <Loader />
                    )
                }
            />
        </>
    );
};
