import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import HandleError from "../../../utils/HandleError";
import PopUp from "../../Popup/PopUpGrade";
import api from "../../../api";
const moment = require('moment-timezone');
const LoginLogsList = ({ SubscriptionList, setPaymentStatus, PaymentStatus, pageNo }) => {
    const [isDeleteOpen, setisDeleteOpen] = useState(false);
    const [UserId, setUserId] = useState()
    const [List, setList] = useState()
    const [local, setlocal] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)
    useEffect(() => {
        SubscriptionList && setList(SubscriptionList.logs)

    }, [SubscriptionList]);

    const ChangeSatus = (id) => {
        const data = { "subscription_id": id }
        console.log(id);
        var token = JSON.parse(Cookies.get("auth"));
        axios({
            url: api.baseURL + "/toggle_payment_status",
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token
            },
            data: data
        })
            .then(res => {
                if (res.data.status === "success") {
                    setPaymentStatus(!PaymentStatus)
                }
            })
            .catch(error => {
                HandleError(error);
            });
    }


    const HideModals = () => {

        setisDeleteOpen(!isDeleteOpen);
    };

    const body2 = () => {
        return (
            <>
                <div>Do you want to change the payment status?</div>
            </>
        );
    };

    const popDeletebody = body2();


    const openPopup = (id) => {
        setUserId(id)
        setisDeleteOpen(!isDeleteOpen);
    };

    const callApi = () => {
        ChangeSatus(UserId)
        setisDeleteOpen(!isDeleteOpen);
    };
    console.log(List, "list");
    if (List) {
        return (
            <>
                {List.map((ele, i) => {
                    return (
                        <>
                            <PopUp
                                title={"Change Payment Status"}
                                open={isDeleteOpen}
                                hideModal={HideModals}
                                body={popDeletebody}
                                callApi={callApi}
                                showButton={true}
                                ButtonNmae={"Yes"}
                            />
                            <tr>


                                <td scope="row">{i + 1 + (pageNo - 1) * 10}</td>
                                <td>
                                    {ele.name}
                                </td>
                                <td>{ele.email}</td>
                                <td style={{ textAlign: 'center' }}>{moment(moment.tz(ele.login_stamp, local).tz(local).format()).format("dddd, MMMM Do YYYY, h:mm:ss a")}</td>

                                <td style={{ textAlign: 'center' }}>{ele.logout_stamp ? moment(moment.tz(ele.logout_stamp, local).tz(local).format()).format("dddd, MMMM Do YYYY, h:mm:ss a") : ""}</td>
                            </tr>     </>)
                })}
            </>
        );
    } else {
        return (<><div>...Loading</div></>)
    }
}

export default LoginLogsList;
