import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Contant from "../src/layouts/Contant";
import "bootstrap/dist/css/bootstrap.min.css";
import LoginContainer from "./views/Login";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import "./assets/style.css";
import PrivateRoute from "./layouts/PrivateRoute";
import "./App.css"
import ForgetPassword from "./views/ForgetPassword";
import ResetPassword from "./views/ResetPasswrod";
function App() {

  //console.log("env", {})
  return (
    <Router>
      <Switch>
        <Route exact path="/" name="Login" component={LoginContainer} />
        <Route exact path="/login" name="Login" component={LoginContainer} />
        <Route exact path="/forget-password" name="Login" component={ForgetPassword} />
        <Route exact path="/reset-password/:id" name="Login" component={ResetPassword} />
        <Switch>
          <PrivateRoute
            path="/dashboard"
            name="Dashboard"
            hello="dsfs"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/user"
            name="UserList"
            component={props => <Contant {...props} />}
          />{" "}
          <PrivateRoute
            path="/rssp-dashboard"
            name="RSSPDashboard"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/telecom-users"
            name="TelecomUsers"
            component={props => <Contant {...props} />}
          />{" "}
          <PrivateRoute
            path="/telecom-user-detail/:id"
            name="TelecomUsersDetails"
            component={props => <Contant {...props} />}
          />{" "}
          <PrivateRoute
            path="/telecom-user-overview/:id"
            name="TelcoUserOverview"
            component={props => <Contant {...props} />}
          />{" "}
          <PrivateRoute
            path="/telecom-user-recharge-list/:id"
            name="TelecomUserRechargeList"
            component={props => <Contant {...props} />}
          />{" "}
          <PrivateRoute
            path="/businesses/user-detail"
            name="UserDetail"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/subscription"
            name="SubscritionList"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/setting"
            name="SettingsList"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/businesses"
            name="BusinessList"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/subjects"
            name="SubjectList"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/grades"
            name="GradeList"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/classes"
            name="ClassList"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/contactus"
            name="ContactUs"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/plans"
            name="Plans"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/cms"
            name="cms"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/editcms"
            name="editcms"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/addnewpost"
            name="addpost"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/Subscritions"
            name="Subscritions"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/user-details/:id"
            name="ShowUser"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/grade-category"
            name="grade-category"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/notification"
            name="Notification"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/free-trail-request"
            name="free-trail-request"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/login-log"
            name="login-log"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/upgrade-user/:id"
            name="/upgrade-user"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/free-trail-content"
            name="FreetrailRequestlist"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/free-trail-content-view"
            name="FreetrailRequestlist"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/content-view/:id"
            name="content"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/your-notification"
            name="Notification"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/free-trail-content1"
            name="Notification"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/topics"
            name="topics"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/banner-management"
            name="BannerManagementList"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/banner-management-detail"
            name="BannerManagementDetail"
            component={props => <Contant {...props} />}
          />
          <PrivateRoute
            path="/promotion-slider-management"
            name="PromotionSliderManagement"
            component={props => <Contant {...props} />}
          />
           <PrivateRoute
            path="/promotion-detail"
            name="PromotionDetail"
            component={props => <Contant {...props} />}
          />
        </Switch>{" "}
      </Switch>
    </Router>
  );
}
export default App;
