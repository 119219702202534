import React, { useEffect, useState } from "react";
import PopUp from "../../Popup/PopUpGrade";
import axios from "axios";
import Cookies from "js-cookie";
import api from "../../../api";
import HandleError from "../../../utils/HandleError";
import Multiselect from 'multiselect-react-dropdown';
import { Container } from "shards-react";
export const FreetrailRequestlist = ({
    currentPosts,
    pageNo,
    setPaymentStatus,
    PaymentStatus
}) => {
    const [isDeleteOpen, setisDeleteOpen] = useState(false);
    const [UserId, setUserId] = useState()
    const [isUserValid, setisUserValid] = useState()

    const [Dropdowndata, setDropdowndata] = useState({});
    const [SelectedValue, setSelectedValue] = useState();
    const [error, setError] = useState();

    const [listData, setlistData] = useState([])

    useEffect(() => {
        var token = JSON.parse(Cookies.get("auth"));
        axios({
            url: api.baseURL + "/free_trial",
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token
            },
        })
            .then(res => {
                console.log("res", res.data.data);
                if (res) {
                    let data = res.data.data;
                    let data1 = res.data.data.length;
                    let data2 = []
                    if (data !== undefined) {
                        for (let i = 0; i < data1; i++) {
                            const obj = Object.assign({}, data[i]);
                            obj.value = `${data[i].title}`
                            data2.push(obj);
                        }
                    }
                    setDropdowndata({ "options": res.data.data })
                }
                setlistData(res.data.data)
            })
    }, []);

    const openPopup = (id) => {
        setUserId(id)

        var token = JSON.parse(Cookies.get("auth"));
        axios({
            url: api.baseURL + `/check_free_trial/${id}`,
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token
            },
        })
            .then(res => {
                console.log("res", res.data.message);
                if (res) {
                    setisUserValid(res.data.message)
                    setisDeleteOpen(!isDeleteOpen);
                }
            })
    };

    const onSelect = (selectedList, selectedItem) => {
        console.log("fsd", selectedList, selectedItem.id);
        setSelectedValue(selectedList)
    }

    let onRemove = (selectedList, removedItem) => {
        console.log("SelectedValue");
        setSelectedValue(selectedList)
    }

    const body2 = () => {
        return (
            <Container>
                <p size="sm" className="mb-1 text-danger" value={error}>{error}</p>
                <p size="sm" className="mb-1 text-danger" value={isUserValid}>{isUserValid}</p>

                <Multiselect
                    options={Dropdowndata.options} // Options to display in the dropdown
                    selectedValues={SelectedValue} // Preselected value to persist in dropdown
                    onSelect={onSelect} // Function will trigger on select event
                    onRemove={onRemove} // Function will trigger on remove event
                    // Property name to display in the dropdown options
                    displayValue="title"
                    showCheckbox={true}
                />
            </Container>
        );
    };
    const popChangebody = body2();


    const HideModals = () => {
        setisDeleteOpen(!isDeleteOpen);
        setError()
    };

    const callApi = () => {

        var user = []
        if (SelectedValue) {
            setError()
            setisDeleteOpen(!isDeleteOpen);
            const result = SelectedValue.map(word => word.id);
            // console.log(id, "SelectedValue");
            var token = JSON.parse(Cookies.get("auth"));
            // SelectedValue.map((ele) => {
            //     user.push(ele.id)
            // })
            axios({
                url: api.baseURL + `/approve_free_trial/${UserId}`,
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Accept: "application/json",
                    Authorization: "BEARER " + token.data.token
                },
                data: { "contents": result }
            })
                .then(res => {
                    if (res.data.status === "success") {

                        setPaymentStatus(!PaymentStatus)
                    }
                })
                .catch(error => {
                    HandleError(error);
                });
            setSelectedValue()
        } else {
            setError("All fields are required*")
        }

    }

    const renderRow = (Obj, i) => {

        return (
            <>
                <PopUp
                    title={"Free Trial approval"}
                    open={isDeleteOpen}
                    hideModal={HideModals}
                    body={popChangebody}
                    callApi={callApi}
                    showButton={true}
                    ButtonNmae={"Yes"}
                />
                <tr key={i}>
                    <td>{i + 1 + (pageNo - 1) * 10}</td>
                    <td>{Obj.full_name}</td>
                    <td>{Obj.email_address}</td>
                    <td>{Obj.message}</td>
                    {Obj.is_approved == "No" ? <td >
                        <span className="badge bg-warning" style={{ cursor: "pointer" }} onClick={() => openPopup(Obj.id)}>Pending</span>
                    </td> :
                        <td >    <span className="badge bg-success">Approved</span>  </td>}
                    {/* <td align="right">
                    <Button
                        onClick={() => {
                            deleteSubject(Obj.id);
                        }}
                        theme="danger"
                    >
                        <img
                            src="/images/action-icon/trash.svg"
                            alt="User Avatar"
                            width="15"
                            height="15"
                        />
                    </Button>
                    &nbsp;
                    <Button
                        theme="info"
                        onClick={() => {
                            updateSubject(Obj.subject_name, Obj.id, Obj.content_url);
                        }}
                    >
                        {" "}
                        <img
                            src="/images/action-icon/edit.svg"
                            alt="User Avatar"
                            width="15"
                        />
                    </Button>
                </td> */}
                </tr>
            </>);
    };
    const tableBody = currentPosts.length && currentPosts.map(renderRow);
    return (
        <>
            <tbody>
                {currentPosts.length > 0 ?
                    tableBody
                    : <tr>
                        <td colSpan="15" className="text-center noRec-found"> No record found</td>
                    </tr>}
            </tbody>
        </>
    );
};
