import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Container,
} from "shards-react";
import api from "../../api";
import { YourNotificationListRow } from "../../components/common/ListRow/YourNotificationListRow";
import PaginatedItems from "../../components/pagination";
import PageHeader from "../../components/PageHeader";
import HandleError from "../../utils/HandleError";
import { NotificationCounts } from "../../flux/Action/Notification";
const YourNotification = () => {
    const [NotificationList, setNotificationList] = useState()
    const [Loading, setLoading] = useState(true)
    const [currentPage, setcurrentPage] = useState(1);
    useEffect(() => {
        var token = JSON.parse(Cookies.get("auth"));
        axios({
            url: api.baseURL + `/notification?page_no=${currentPage}`,
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token
            }
        })
            .then(res => {
                if (res.data.status === "success") {
                    setNotificationList(res.data.data)
                    setLoading(false)
                }
            })
            .catch(error => {
                HandleError(error);
            });
    }, [currentPage])

    useEffect(() => {
        console.log("hfjsdfhssdfdjf");
        NotificationCounts()
    }, [Loading]);

    const HandlePageClick = data => {
        setcurrentPage(data.selected + 1);
    };
    return (
        <>
            <Container fluid className="main-content-container px-4">
                <PageHeader
                    title={"Notification"}
                    subtitle={"MsingiPACK Cloud"}
                    name={"Add Notification"}
                    Status={false}
                />
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="border-bottom">
                                <h6 className="m-0"> Notification </h6>
                            </CardHeader>
                            <CardBody className="p-0 pb-3">
                                <>
                                    <div className="table-responsive">
                                        <table className="table mb-0">
                                            <thead className="bg-light">
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>Title</th>
                                                    <th>Message </th>
                                                    <th>Time </th>
                                                </tr>
                                            </thead>
                                            {!Loading ?
                                                <>
                                                    <YourNotificationListRow
                                                        currentPosts={NotificationList.notifications}
                                                        pageNo={currentPage}
                                                    />

                                                </>
                                                :
                                                <tbody className="bg-light"> <tr>
                                                    <td colSpan="15" className="text-center noRec-found"> No record found</td>
                                                </tr>
                                                </tbody>
                                            }
                                        </table>

                                    </div>
                                    {!Loading && NotificationList.tot_notifications > 10 &&
                                        <PaginatedItems
                                            List={NotificationList.tot_notifications}
                                            HandlePageClick={HandlePageClick}
                                        />}
                                </>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default YourNotification

