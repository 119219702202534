import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button,
    FormInput
} from "shards-react";
import PageHeader from "../../components/PageHeader";
import api from "../../api/index"
import Cookies from "js-cookie";
import { set } from "react-ga";
import Loader from '../../components/common/loader';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SettingsList = () => {
    const [loading, setLoading] = useState(true);

    const [Tax, setTax] = useState()
    const [bank_name, setbank_name] = useState()
    const [account_name, setaccount_name] = useState()
    const [account_no, setaccount_no] = useState()
    const [branch, setbranch] = useState()
    const [code, setcode] = useState()
    const [Address, setAddress] = useState()
    const [id, setid] = useState()
    const [Url, setUrl] = useState()


    const [isUpdate, setisUpdate] = useState(false)
    useEffect(() => {
        var token = JSON.parse(Cookies.get("auth"));

        const config = {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token,
            }
        };
        fetch(api.baseURL + `/fetch_settings`, config)
            .then((res) => res.json())
            .then((data) => {
                setLoading(false);
                setTax(data.data.tax_per)
                setbank_name(data.data.bank_name)
                setaccount_name(data.data.account_name)
                setaccount_no(data.data.account_no)
                setbranch(data.data.branch)
                setcode(data.data.code)
                setid(data.data.id)
                setAddress(data.data.address)
                setUrl(data.data.free_trial_content_url)
            })
            .catch((err) => {
                if (err) {
                    console.log("this is err", err);
                }
            });
    }, [isUpdate])

    const HandleSubmit = (ids) => {
        let data;
        if (ids === "0") {
            data = { id: id, tax_per: parseFloat(Tax) }
        } else if (ids === "1") {
            data = { id: id, bank_name: bank_name, account_name: account_name, account_no: account_no, branch: branch, code: code }
        }
        else if (ids === "2") {
            data = { id: id, address: Address }
        }
        else if (ids === "3") {
            data = { id: id, free_trial_content_url: Url }
        }
        // let data = { price_per_user: priceperuser, tax_per: Number(Tax) }
        var token = JSON.parse(Cookies.get("auth"));
        const config = {
            url: api.baseURL + "/settings",
            method: "PUT",
            headers: {
                "Content-type": "application/json",
                Accept: "application/json",
                Authorization: "BEARER " + token.data.token
            },
            body: JSON.stringify(data)
        }

        fetch(api.baseURL + `/settings`, config)
            .then((res) => res.json())
            .then((data) => {
                toast.success(data.message);
                if (data.data.status === "success") {
                    setisUpdate(!isUpdate)
                }
            })
            .catch((err) => {
                console.log("this is err", err);
            });
    }
    return (
        <Container fluid className="main-content-container px-4">
            <PageHeader
                title={"Setting"}
                subtitle={"MsingiPACK Cloud"}
                name={"Add users"}
                Status={false}
            />
            <Row>
                <Col>
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Tax</h6>
                        </CardHeader>
                        <CardBody>
                            <Row>

                                <Col md="4">
                                    <div className="form-group">
                                        <label> Tax </label>
                                        <FormInput type="number" value={Tax} className="form-control" onChange={(e) => setTax(e.target.value)} />
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="form-group">
                                        <label className="hide">&nbsp;</label>
                                        <Button onClick={(ids) => HandleSubmit(ids = "0")}>Submit &rarr;</Button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Account Details</h6>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="4" >
                                    <div>
                                        <div className="form-group">
                                            <label> Bank </label>
                                            <FormInput type="text" value={bank_name} className="form-control" onChange={(e) => setbank_name(e.target.value)} />
                                        </div>
                                    </div>
                                </Col>
                                <Col md="4" >
                                    <div>
                                        <div className="form-group">
                                            <label>A/c Name</label>
                                            <FormInput type="numbtexter" value={account_name} className="form-control" onChange={(e) => setaccount_name(e.target.value)} />
                                        </div>
                                    </div>
                                </Col>

                                <Col md="4" >
                                    <div>
                                        <div className="form-group">
                                            <label> A/c No. </label>
                                            <FormInput type="text" value={account_no} className="form-control" onChange={(e) => setaccount_no(e.target.value)} />
                                        </div>
                                    </div>
                                </Col>

                                <Col md="4" >
                                    <div>
                                        <div className="form-group">
                                            <label> Branch: </label>
                                            <FormInput type="text" value={branch} className="form-control" onChange={(e) => setbranch(e.target.value)} />
                                        </div>
                                    </div>
                                </Col>

                                <Col md="4" >
                                    <div>
                                        <div className="form-group">
                                            <label> Code: </label>
                                            <FormInput type="text" value={code} className="form-control" onChange={(e) => setcode(e.target.value)} />
                                        </div>
                                    </div>
                                </Col>

                                <Col md="4" >
                                    <div>
                                        <div className="form-group">
                                            <label className="hide">&nbsp;</label>
                                            <Button onClick={(ids) => HandleSubmit(ids = "1")}>Submit &rarr;</Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Company Address</h6>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="4">
                                    <div>
                                        <div className="form-group">
                                            <label> Address </label>
                                            <FormInput type="text" value={Address} className="form-control" onChange={(e) => setAddress(e.target.value)} />
                                        </div>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div>
                                        <div className="form-group">
                                            <label className="hide">&nbsp;</label>
                                            <Button onClick={(ids) => HandleSubmit(ids = "2")}>Submit &rarr;</Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    {/* <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Free Trial</h6>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="4">
                                    <div>
                                        <div className="form-group">
                                            <label> Url </label>
                                            <FormInput type="text" value={Url} className="form-control" onChange={(e) => setUrl(e.target.value)} />
                                        </div>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div>
                                        <div className="form-group">
                                            <label className="hide">&nbsp;</label>
                                            <Button onClick={(ids) => HandleSubmit(ids = "3")}>Submit &rarr;</Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card> */}
                </Col>
            </Row>
        </Container>
    );
};

export default SettingsList;
