import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import SmallStats from "../../components/common/SmallStats";
import UsersOverview from "../../components/blog/UsersOverview";
import TopReferrals from "../../components/common/TopReferrals";
import { toast } from "react-toastify";
import MultilevelStats from "../../components/common/MultilevalStats";
import { Store } from "../../flux";
import { TelecomdashboardAction } from "../../flux/Action/TelecomDashborad";
import { useParams } from "react-router-dom";
import TelecomGraphFillter from "../../components/common/TelecomGraphFillter";
import TelcoUserGraph from "../../components/blog/TelcoUserGraph";
const TelcoUserOverview = () => {
  const {id} = useParams()
  const [DashboraStatsLists, setDashboraStatsLists] = useState({});
  const [reload, setReload] = useState();

  useEffect(() => {
    Store.addChangeListener(onChange);
    const d = new Date();
    let year = d.getFullYear();
    TelecomdashboardAction({ "year": 2023, "user_id": id }, 0)
  }, []);
  const onChange = () => {
    if (Store.DashBoard_Stats().status === 200) {
      console.log(Store.DashBoard_Stats().data.data.GraphData.length, "thisss");
      setReload(!reload)
      setDashboraStatsLists(Store.DashBoard_Stats().data.data);
    }
  };

  console.log("DashboraStatsListsDashboraStatsLists", Object.keys(DashboraStatsLists).length > 0 && DashboraStatsLists);

  useEffect(() => {
    document.title = "MsingiPACK Cloud - Admin Portal";


    return () => {
      document.title = "";
    }
  }, [])

  const data = [
    // {
    //   label: "Total Content",
    //   value:
    //     DashboraStatsLists !== undefined
    //       ? DashboraStatsLists.tot_content
    //       : "Posts",
    //   attrs: { md: "6", sm: "6" }
    // },
    {
      label: "Total Recharges",
      value:
        DashboraStatsLists !== undefined
          ? DashboraStatsLists.tot_recharges
          : "hell",
      attrs: { md: "3", sm: "6" }
    },
    {
      label: "Total Subscriptions",
      value:
        DashboraStatsLists !== undefined
          ? DashboraStatsLists.tot_subs
          : "hell",
      attrs: { md: "3", sm: "6" }
    },
    {
      label: "Total Student Users ",

      value:
        DashboraStatsLists !== undefined
          ? DashboraStatsLists.total_student_users
          : "hell",

      attrs: { md: "3", sm: "6" }
    },
    {
      label: "Total Amount ",

      value:
        DashboraStatsLists !== undefined
          ? DashboraStatsLists.tot_amount
          : "hell",

      attrs: { md: "3", sm: "6" }
    }
  ];
  // data for populer session
  const data1 = [
    {
      label: "Highly Viewed Grade",
      value:
        DashboraStatsLists.highViewedGrade !== undefined
          ? DashboraStatsLists.highViewedGrade.grade_name
          : "Posts",
      attrs: { md: "6", sm: "6" },
      label1: "Highly Viewed Subject",
      value1:
        DashboraStatsLists.highViewedSubject !== undefined
          ? DashboraStatsLists.highViewedSubject.subject_name
          : "Posts",
      attrs: { md: "6", sm: "6" }
    },
  ];
  // data for avg session duratuion 
  const data2 = [
    {
      label: "Average Viewed Grade",
      value:
        DashboraStatsLists.highViewedGrade !== undefined
          ? DashboraStatsLists.avgViewedGrade.grade_name
          : "Posts",
      attrs: { md: "6", sm: "6" },
      label1: "Average Viewed Subject",
      value1:
        DashboraStatsLists.highViewedSubject !== undefined
          ? DashboraStatsLists.avgViewedSubject.subject_name
          : "Posts",
      attrs: { md: "6", sm: "6" }
    },
  ];

  // data for avg session duratuion 
  const data02 = [
    {
      label: "Active Account",
      value:
        DashboraStatsLists.highViewedGrade !== undefined
          ? DashboraStatsLists.student_active_users
          : "Posts",
      attrs: { md: "6", sm: "6" },
      label1: "Inactive Account",
      value1:
        DashboraStatsLists.highViewedSubject !== undefined
          ? DashboraStatsLists.student_inactive_users
          : "Posts",
      attrs: { md: "6", sm: "6" }
    },
  ];


  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const data3 = {
    title: "Users Overview",
    chartData: {
      hello: console.log(DashboraStatsLists.GraphData && typeof DashboraStatsLists.GraphData.length, "update workinh"),
      labels: DashboraStatsLists.GraphData && DashboraStatsLists.GraphData.length === 12 ? monthNames
        : DashboraStatsLists.GraphData && DashboraStatsLists.GraphData.length > 12 ?
          Array.from(new Array(DashboraStatsLists.GraphData ? DashboraStatsLists.GraphData.length : 12), (_, i) => (i === 0 ? 1 : i)) : "",
      datasets: [
        {
          label: Object.keys(DashboraStatsLists).length > 0 && DashboraStatsLists.graphxaxisData.length === 24 ? "Daily" : 
          Object.keys(DashboraStatsLists).length > 0 && DashboraStatsLists.graphxaxisData.length >= 28 ? "Monthly" : 
          Object.keys(DashboraStatsLists).length > 0 && DashboraStatsLists.graphxaxisData.length === 12 ? "Yearly" : '',
          // label: "adssa",
          fill: "start",
          data: DashboraStatsLists.GraphData,
          backgroundColor: "rgba(0,123,255,0.1)",
          borderColor: "rgba(0,123,255,1)",
          pointBackgroundColor: "#ffffff",
          pointHoverBackgroundColor: "rgb(0,123,255)",
          borderWidth: 1.5,
          pointRadius: 0,
          pointHoverRadius: 3
        }
      ]
    }
  };

  return (
    <>
      {DashboraStatsLists &&
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <PageTitle
              title="Overview"
              subtitle="Dashboard"
              className="text-sm-left mb-3"
            />
          </Row>
          {/* Small Stats Blocks */}
          <Row>
            {DashboraStatsLists
              ? data.map((stats, idx) => (
                <Col className="col-lg-3 mb-4" key={idx} {...stats.attrs}>
                  <SmallStats
                    id={`small-stats-${idx}`}
                    label={stats.label}
                    value={stats.value}
                  />
                </Col>
              ))
              : "hello"}
          </Row>
          <Row>
            {DashboraStatsLists.GraphData && data3.chartData.labels &&

              <Col lg="8" md="12" sm="12" className="mb-4">
                {console.log("dddddd", data3.chartData.labels)}
                <TelcoUserGraph data={data3} title="User Overview" reload={reload} />
              </Col>
            }
            {console.log("DashboraStatsLists", DashboraStatsLists)}
            {/* 
              {DashboraStatsLists &&
                <>
                  <TelecomGraphFillter className="my-2" data={DashboraStatsLists} />
                  <TopReferrals className="mb-4" /></>}
            </Col> */}
            <Col lg="4" md="12" sm="12" className="mb-4">
                {DashboraStatsLists
                  && data1.map((stats, idx) => (
                    <Col lg="12" md="12" sm="12" className="mb-4">
                      <MultilevelStats id={`small-stats-${idx}`}
                        label={stats.label}
                        value={stats.value}
                        label1={stats.label1}
                        value1={stats.value1}
                        heading="Popular Session"
                      />
                    </Col>
                  ))
                }

                {/* Users by Device */}
                {DashboraStatsLists
                  && data2.map((stats, idx) => (
                    <Col lg="12" md="12" sm="12" className="mb-4">
                      <MultilevelStats id={`small-stats-${idx}`}
                        label={stats.label}
                        value={stats.value}
                        label1={stats.label1}
                        value1={stats.value1}
                        heading="Average Session Duration"
                      />
                    </Col>
                  ))
                }

                {/* Users by Device */}
                {/* {DashboraStatsLists
                  && data02.map((stats, idx) => (
                    <Col lg="12" md="12" sm="12" className="mb-4">
                      <MultilevelStats id={`small-stats-${idx}`}
                        label={stats.label}
                        value={stats.value}
                        label1={stats.label1}
                        value1={stats.value1}
                        heading="Student Account Analysis"
                      />
                    </Col>
                  ))
                } */}
            </Col>
          </Row>
          <Row>
            {/* Users by Device */}
          </Row>
        </Container>}
    </>
  );
};
TelcoUserOverview.propTypes = {
  /**
   * The small stats dataset.
   */
  smallStats: PropTypes.array
};

export default TelcoUserOverview;