import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import PageHeader from "../../components/PageHeader";
import Loader from "../../components/common/loader";
import PopUp from "../../components/Popup/PopUpGrade";
import { listgradeCategoryAction, addgradeCategoryAction, deletegradeCategoryAction, updateGradeCategoryAction } from "../../flux/Action/listgradeCategoryAction";
import { FormInput, FormSelect } from "shards-react";
import GradeCategoryListRow from "../../components/common/ListRow/GradeCategoryListRow";
import { Store } from "../../flux";
import { useHistory } from "react-router-dom";
import PaginatedItems from "../../components/pagination";

const GradeCategory = () => {
    //api response
    const [gradeList, setGradeList] = useState([]);
    const [gradeDetails, setGradeDetails] = useState([]);
    const [gradeCategory, setgradeCategory] = useState([]);
    //state for toggle and toggle data
    const [open, setOpen] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [isOpen, setisOpen] = useState(false);
    const [isDeleteOpen, setisDeleteOpen] = useState(false);
    //onchange data
    const [GradeCategoryid, setGradeCategoryid] = useState();
    const [GradeCategoryName, setGradeCategoryName] = useState();
    const [DeletedGradeName, setDeletedGradeName] = useState();
    //pagination state
    const [currentPage, setcurrentPage] = useState(1);
    // const [postsPerPage, setpostsPerPage] = useState(10);
    const [currentPosts, setcurrentPosts] = useState();

    const history = useHistory();

    useEffect(() => {
        //data add to store for edit grade

        // setGradeCategoryid(gradeDetails.id);
        // setGradeCategoryName(gradeDetails.category_name);

        //grade list action

        let formData = new FormData();
        listgradeCategoryAction(formData);

        //add change listenerfor geting data from flux

        Store.addChangeListener(onChange);
        Store.addChangeListener(onAddgrade);

        return () => {
            Store.removeChangeListener(onChange);
            Store.removeChangeListener(onAddgrade);
        };
    }, [isLoading, gradeDetails, currentPage]);

    const onChange = () => {
        setGradeList(Store.gradeList().data.data.grades);
        setcurrentPosts(Store.gradeList().data.data.tot_grades);
    };

    const onAddgrade = () => {
        if (Store.gradeCategory().data && Store.gradeCategory().data.status == "success") {
            setgradeCategory(Store.gradeCategory().data.data);
        }
    };

    const onShowgrade = () => {
        setGradeDetails(Store.ShowGrade().data.data);
    };

    const sendGrade = (grade_id, grade_category_name) => {
        history.push({
            pathname: `/subjects/` + grade_id,
            state: {
                update: grade_category_name,
                grade_id: grade_id
            }
        });
    };

    const addgrade = () => {

        setOpen(true);
    };

    const updateGrade = (id, value) => {
        setGradeCategoryName(value)
        setGradeCategoryid(id)
        // let formData = new FormData();
        // formData.append("grade_id", id);
        // showGradeAction({ formData });
        // gradeCategoryAction();
        setisOpen(!isOpen);
    };

    const callGradeapi = () => {
        let formData = new FormData();
        formData.append("category_name", GradeCategoryName);
        addgradeCategoryAction({ formData });
        setOpen(!open);
        setGradeCategoryName("");
        setGradeCategoryid("");
    };

    const callUpdateapi = () => {
        let data = { "id": GradeCategoryid, "category_name": GradeCategoryName }
        updateGradeCategoryAction(data);
        setisOpen(!isOpen);
        setGradeCategoryName("");
        setGradeCategoryid("");
    };

    const hideModal = () => {
        setOpen(!open);
        setGradeCategoryName("");
        setGradeCategoryid("");
        setgradeCategory([]);
    };

    const HideModal = () => {
        setisOpen(!isOpen);
        setGradeCategoryName("");
        setGradeCategoryid("");
        setgradeCategory([]);
    };

    const HideModals = () => {
        setisDeleteOpen(!isDeleteOpen);
    };

    const body = () => {
        return (
            <>
                <Container>

                    <input
                        size="sm"
                        type="text"
                        placeholder="Grade Category"
                        className="mb-2 form-control"
                        onChange={e => {
                            setGradeCategoryName(e.target.value);
                        }}
                    />
                </Container>
            </>
        );
    };

    const popUpbody = body();

    const body1 = () => {
        return (
            <>
                <Container>
                    <FormInput
                        value={
                            GradeCategoryName ? GradeCategoryName : gradeDetails.category_name
                        }
                        size="sm"
                        type="text"
                        placeholder="Grades/Classes"
                        className="mb-2"
                        onChange={e => {
                            setGradeCategoryName(e.target.value);
                        }}
                    />
                </Container>
            </>
        );
    };
    const popShowbody = body1();

    const body2 = () => {
        return (
            <>
                <div>Are you sure you want to delete this Category?</div>
            </>
        );
    };

    const popDeletebody = body2();

    const HandlePageClick = data => {
        setcurrentPage(data.selected + 1);
    };

    const deleteGrade = () => {
        deletegradeCategoryAction(DeletedGradeName);
        setisDeleteOpen(!isDeleteOpen);
    };

    const deleteGradePopUp = id => {
        setisDeleteOpen(!isDeleteOpen);
        setDeletedGradeName(id);
    };
    return (
        <>
            <Container fluid className="main-content-container">
                <PageHeader
                    title={"Grades"}
                    subtitle={"MsingiPACK Cloud"}
                    name={"Add Category"}
                    onsubmit={addgrade}
                    Status={true}
                />
                <PopUp
                    title={"Add Categoty"}
                    open={open}
                    hideModal={hideModal}
                    body={popUpbody}
                    callApi={callGradeapi}
                    showButton={true}
                />
                <PopUp
                    title={"Update Category"}
                    open={isOpen}
                    hideModal={HideModal}
                    body={popShowbody}
                    callApi={callUpdateapi}
                    showButton={true}
                />
                <PopUp
                    title={"Delete Grade"}
                    open={isDeleteOpen}
                    hideModal={HideModals}
                    body={popDeletebody}
                    callApi={deleteGrade}
                    showButton={true}
                    ButtonNmae={"Delete"}
                />
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="border-bottom">
                                <h6 className="m-0">Grades Category</h6>
                            </CardHeader>
                            <CardBody className="p-0 pb-3">
                                <div className="table-responsive">
                                    <table className="table mb-0">
                                        <thead className="bg-light">
                                            <tr>
                                                <th scope="col" className="border-0">
                                                    S.No
                                                </th>
                                                <th scope="col" className="border-0">
                                                    Grade Category Name
                                                </th>

                                                <th
                                                    scope="col"
                                                    className="border-0"
                                                    style={{ textAlign: "center" }}
                                                >
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>{" "}
                                        <GradeCategoryListRow
                                            currentPosts={gradeList}
                                            sendGrade={sendGrade}
                                            title={" View Subject"}
                                            deleteGrade={deleteGradePopUp}
                                            updateGrade={updateGrade}
                                            pageNo={currentPage}
                                        />
                                        {console.log(gradeList, "gradeDetails")}
                                    </table>
                                    {currentPosts > 10 &&
                                        <PaginatedItems
                                            List={currentPosts}
                                            HandlePageClick={HandlePageClick}
                                        />}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default GradeCategory;
