import dispatcher from "../dispatcher";
import axios from "axios";
import Constants from "../constants";
import api from "../../api";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HandleError from "../../utils/HandleError";

export const plansAction = () => {
  var token = JSON.parse(Cookies.get("auth"));
  axios({
    url: api.baseURL + "/fetch_package_prices",
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    }
  })
    .then(
      res => (
        console.log("res", res),
        dispatcher.dispatch({
          type: Constants.PLANSLIST,
          payload: res
        })
      )
    )
    .catch(error => {
      console.log("res", error.response);
      HandleError(error);
    });
};

export const storePlansAction = formData => {
  var token = JSON.parse(Cookies.get("auth"));
  axios({
    url: api.baseURL + "/store_package_prices",
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    },
    data: formData
  })
    .then(res => {
      console.log("res", res);
      if (res) {
        toast.success(res.data.message);
        plansAction();
      }
    })
    .catch(error => {
      console.log("res", error.response);
      HandleError(error);
    });
};
