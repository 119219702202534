import React, { useState } from "react";
import { Button } from "shards-react";
import PopUp from "../../Popup/PopUpGrade";
const ContactListRow = ({ currentPosts, deleteContact, CurrentPage }) => {
    const [isDeleteOpen, setisDeleteOpen] = useState(false);
    // console.log(currentPosts);


    const HideModals = () => {
        setisDeleteOpen(!isDeleteOpen);
    };

    const body2 = () => {
        return (
            <>
                <div>Are you sure you want to delete this Grade?</div>
            </>
        );
    };

    const popDeletebody = body2();


    const deleteGrade = () => {
        // let formData = new FormData();
        // formData.append("grade_id", DeletedGradeName);
        // deletegradeAction({ formData });
        setisDeleteOpen(!isDeleteOpen);
    };

    const renderRow = (Obj, i) => {
        return (
            <tr key={i}>
                {/* + (CurrentPage - 1) * 10 */}
                <td>{i + 1}</td>
                <td>{Obj.name}</td>
                <td>{Obj.email}</td>
                <td>{Obj.message}</td>
                <td>{Obj.question}</td>
                {/* <td style={{ textAlign: "center" }}>
                    <Button className="action-btn" theme="info" onClick={() => deleteContact(Obj.id)}>
                        {" "}
                        <i className="material-icons">
                            edit
                        </i>
                    </Button>
                </td> */}
            </tr>
        );
    };
    const tableBody = currentPosts.length && currentPosts.map(renderRow);

    return (
        <>
            <PopUp
                title={"Delete Grade"}
                open={isDeleteOpen}
                hideModal={HideModals}
                body={popDeletebody}
                callApi={deleteGrade}
                showButton={true}
                ButtonNmae={"Delete"}
            />
            <tbody>
                {tableBody.length !== undefined ?
                    tableBody
                    : <tr>
                        <td colSpan="15" className="text-center noRec-found"> No record found</td>
                    </tr>}
            </tbody>
        </>
    );
}
export { ContactListRow }