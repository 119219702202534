import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody} from "shards-react";
import PageHeader from "../../components/PageHeader";
import bannerImage from "../../images/banner.jpg"
import { useHistory } from "react-router-dom";

const BannerManagementDetail = (props) => {
    console.log(props.history.location.state);
    const history = useHistory();
        const BannerManagement = () => {
        history.push({
            pathname: `/banner-management`,
        });
    };

    const [bannerDetail, setBannerDetail] = useState(props.history.location.state);
  return (
    <>
      <Container fluid className="main-content-container">
      <PageHeader
          title={"Banner Detail"}
          subtitle={"MsingiPACK Cloud"}
          name={"Back"}
          onsubmit={BannerManagement}
          Status={true}
        />
        <Row>
            <Col>
                <Card small className="mb-4">
                    <CardBody className="p-3">
                        <div className="form-group">
                            <h5>Content</h5>
                            <p className="mb-0">
                                {bannerDetail.content}                                
                            </p>
                        </div>
                        <div className="form-group">
                            <h5>URL</h5>
                            <p className="mb-0">
                                {bannerDetail.url}
                                
                            </p>
                        </div>
                        <div className="form-group">
                            <h5>Banner Image</h5>
                            <img src={bannerImage} alt="Banner Image" className="img-fluid" />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
      </Container>
    </>
  );
};
export default BannerManagementDetail;
