import React from "react";
import { Button } from "shards-react";

export const NotificationListRow = ({
    currentPosts,
    pageNo
}) => {
    console.log(currentPosts, "obj");
    const renderRow = (Obj, i) => {
        return (
            <tr key={i}>
                <td>{i + 1 + (pageNo - 1) * 10}</td>
                <td>{Obj.title}</td>
                <td className="max-width">{Obj.message.replace(/(<([^>]+)>)/ig, '')}</td>

                <td className="max-width">{Obj.time_diff}</td>
                {/* <td align="right">

                    <Button
                        onClick={() => {
                            deleteSubject(Obj.id);
                        }}
                        theme="danger"
                    >
                        <img
                            src="/images/action-icon/trash.svg"
                            alt="User Avatar"
                            width="15"
                            height="15"
                        />
                    </Button>
                    &nbsp;
                    <Button
                        theme="info"
                        onClick={() => {
                            updateSubject(Obj.subject_name, Obj.id, Obj.content_url);
                        }}
                    >
                        {" "}
                        <img
                            src="/images/action-icon/edit.svg"
                            alt="User Avatar"
                            width="15"
                        />
                    </Button>
                </td> */}
            </tr>
        );
    };
    const tableBody = currentPosts && currentPosts.length > 0 && currentPosts.map(renderRow);
    return (
        <>

            {tableBody ?
                tableBody
                : <tr>
                    <td colSpan="15" className="text-center noRec-found"> No record found</td>
                </tr>}
        </>
    );
};
