import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, FormInput } from "shards-react";
import { UserDetailsAction } from "../../../flux/Action/UsersAction";
import { Store } from "../../../flux";
import Loader from "../loader";
import PopUp from "../../Popup/PopUpGrade";
export const OtherUserListRow = ({
    currentPosts,
    userStatus,
    updateUser,
    pageNo,
    ShowUsers
}) => {
    const [open, setOpen] = useState(false);
    const [inputType, setInputType] = useState(true);
    const [userDetails, setUserDetails] = useState();
    const [isHide, setisHide] = useState(true);

    const renderRow = (Obj, i) => {
        return (
            <tr key={i}>
                <td>{i + 1 + (pageNo - 1) * 10}</td>
                <td>{Obj.name}</td>
                <td>{Obj.email}</td>
                <td>{Obj.mob_no}</td>
            </tr>
        );
    };
    console.log("tableBody", tableBody);
    const tableBody = currentPosts.length && currentPosts.map(renderRow);

    return (
        <>

            <tbody>
                {
                    tableBody.length !== undefined ?
                        tableBody
                        : <tr>
                            <td colSpan="15" className="text-center noRec-found"> No record found</td>
                        </tr>
                }
            </tbody>
        </>
    );
};
