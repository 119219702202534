import dispatcher from "../dispatcher";
import axios from "axios";
import Constants from "../constants";
import api from "../../api";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HandleError from "../../utils/HandleError";

export const subjectAction = formData => {
  var token = JSON.parse(Cookies.get("auth"));
  console.log("auth");
  axios({
    url: api.baseURL + "/list_subjects",
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    },
    data: formData
  })
    .then(res =>
      dispatcher.dispatch({
        type: Constants.SUBJECTLIST_SUCCESS,
        payload: res
      })
    )
    .catch(error => {
      console.log("res", error.response);
      HandleError(error);
    });
};

export const storeSubjectAction = ({ formData }) => {
  var token = JSON.parse(Cookies.get("auth"));
  axios({
    url: api.baseURL + "/store_subject",
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    },
    data: formData
  })
    .then(
      res => (
        console.log("subone", res),
        toast.success(res.data.message),
        dispatcher.dispatch({
          type: Constants.SUBJECT_UPDATE,
          payload: res
        })
      )
    )
    .catch(error => {
      console.log("subone", error);
      dispatcher.dispatch({
        type: Constants.SUBJECT_UPDATE,
        payload: error.response
      })
      HandleError(error);
    });
};

export const deleteSubjectAction = ({ formData }) => {
  var token = JSON.parse(Cookies.get("auth"));
  axios({
    url: api.baseURL + "/delete_subject",
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    },
    data: formData
  })
    .then(
      res => (
        console.log(res.data, "im res"),
        dispatcher.dispatch({
          type: Constants.SUBJECT_UPDATE,
          payload: res
        }),
        toast.success(res.data.message)
      )
    )
    .catch(error => {
      console.log("res", error.response);
      HandleError(error);
    });
};

export const updateSubjectAction = ({ formData }) => {
  var token = JSON.parse(Cookies.get("auth"));
  axios({
    url: api.baseURL + "/update_subject",
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "BEARER " + token.data.token
    },
    data: formData
  })
    .then(res => {
      toast.success(res.data.message)
      dispatcher.dispatch({
        type: Constants.SUBJECT_UPDATE,
        payload: res
      });
    })
    .catch(error => {
      console.log("res", error.response);
      dispatcher.dispatch({
        type: Constants.SUBJECT_UPDATE,
        payload: error.response
      })
      HandleError(error);
    });
};

export const ClearSubjectAction = () => {
  console.log("sdfjsdkfhjk");

  setTimeout(function () {
    dispatcher.dispatch({
      type: Constants.CLEAR_SUBJECT
    });
  }, 1);
};
