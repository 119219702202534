import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Container,
    Button,
    FormInput
} from "shards-react";
import PageHeader from "../../components/PageHeader";
import { Store } from "../../flux";
import { SubjectListRow } from "../../components/common/ListRow/SubjectListRow";
import PaginatedItems from "../../components/pagination";
import { useParams } from "react-router-dom";
import {
    subjectAction,
    storeSubjectAction,
    deleteSubjectAction,
    updateSubjectAction,
    ClearSubjectAction
} from "../../flux/Action/SubjectAction";
import Loader from '../../components/common/loader';
import PopUp from "../../components/Popup/PopUpGrade";
// import info from "../../../public/images"
import { Tooltip } from "shards-react";
import { Beforeunload, useBeforeunload } from 'react-beforeunload';
import store from "../../flux/store";
import { Prompt } from 'react-router'


const SubjectList = () => {
    const [loading, setLoading] = useState(true);
    const [subjectList, setSubjectList] = useState([]);
    const [currentPosts, setcurrentPosts] = useState();
    const [updateApiResp, setupdateApiResp] = useState([]);

    const [currentPage, setcurrentPage] = useState(1);

    const [SubjectName, setSubjectName] = useState();
    const [ContentUrl, setContentUrl] = useState("");
    const [Pdf, setpdf] = useState();
    const [subjectId, setSubjectId] = useState();
    const [gradeCategoryIcons, setgradeCategoryIcons] = useState("Upload Icon");

    const [open, setOpen] = useState(false);
    const [isopen, setisOpen] = useState(false);
    const [isDeleteopen, setisDeleteopen] = useState(false);
    const [isSubjectDelete, setSubjectDelete] = useState(false);

    const [tooltipopen, settooltipopen] = useState(false);

    const [loaderoprn, setloaderoprn] = useState(false);

    const [beforeunloads, setbeforeunload] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        // setloaderoprn(false)
        Store.addChangeListener(onChange);
        console.log(" location.state", id);
        let formData = new FormData();
        formData.append("grade_id", id);
        formData.append("page_no", currentPage);
        subjectAction(formData);
        console.log("sdfjsdkfhjk");

    }, [
        open,
        isopen,
        isSubjectDelete,
        updateApiResp,
        currentPage,
        updateApiResp
    ]);
    useEffect(() => {
        if (loaderoprn)
            ClearSubjectAction()
        return () => {
            setbeforeunload(true)
        }
    }, [beforeunloads,
        loaderoprn
    ]);

    const HandlePageClick = data => {
        setcurrentPage(data.selected + 1);
    };

    const onChange = () => {
        console.log("jfgdfg", Store.subjectList());
        if (Store.subjectList().status === 200) {
            setLoading(false);
            setSubjectList(Store.subjectList().data.data.subjects);
            setcurrentPosts(Store.subjectList().data.data.tot_subjects);
        }
    };

    const onUpdate = () => {
        console.log("subone", Store.subjectUpdate());
        if (Store.subjectUpdate() != undefined) {
            if (Store.subjectUpdate().data && Store.subjectUpdate().data.status == "success") {
                setupdateApiResp(Store.subjectUpdate().data.data)
                setloaderoprn(false)
            }
            else if (Store.subjectUpdate().data && Store.subjectUpdate().data.status === "error") {
                setloaderoprn(false)
            }
        }
    };

    const addSubject = () => {
        setisOpen(!isopen);
    };


    const deleteSubject = (id) => {
        setisDeleteopen(!isopen);
        setSubjectId(id);
    };
    const callSubjectApi = () => {
        Store.addChangeListener(onUpdate);
        let formData = new FormData();
        formData.append("subject_name", SubjectName);
        // formData.append("content_url", ContentUrl);
        // formData.append("subject_content", Pdf);
        formData.append("grade_id", id);
        formData.append("icon", gradeCategoryIcons);
        setloaderoprn(true)
        console.log("add", "loaderoprn", loaderoprn);
        storeSubjectAction({ formData });
        setisOpen(!isopen);
        setSubjectId("");
        setSubjectName("");
    };

    const callSubjectUpdateApi = () => {
        Store.addChangeListener(onUpdate);
        let formData = new FormData();
        formData.append("subject_name", SubjectName);
        // formData.append("content_url", ContentUrl);
        // formData.append("subject_content", Pdf);
        formData.append("subject_id", subjectId);
        formData.append("icon", gradeCategoryIcons);
        updateSubjectAction({ formData });
        setloaderoprn(true)
        console.log("update", "loaderoprn", loaderoprn);
        setOpen(!open);
        setSubjectId("");
        setSubjectName("");
        setContentUrl("");
        setpdf()
    };
    const body = () => {
        return (
            <Container>
                <FormInput
                    size="sm"
                    type="text"
                    value={SubjectName ? SubjectName : ""}
                    placeholder="Subject Name"
                    className="mb-2"
                    onChange={e => {
                        setSubjectName(e.target.value);
                    }}
                />
                {/* {
                    <>
                        <div className="d-flex">
                            <FormInput
                                size="sm"
                                // value={Pdf ? Pdf : ""}
                                type="file"
                                placeholder="choose file"
                                onChange={e => {
                                    setpdf(e.target.files[0])
                                    console.log(e.target.files[0], "e.target.files[0]")
                                }}
                                accept=".zip"
                                className="mb-2"
                            />
                            &nbsp;
                            <Button
                                className="action-btn"
                                theme="info"
                                title="info"
                                id="TooltipExample"
                            >
                                <Tooltip
                                    open={tooltipopen}
                                    target="#TooltipExample"
                                    toggle={() => settooltipopen(!tooltipopen)}>
                                    1. You should keep same name of folder & zip folder.
                                    2. Folder should contain index.html file in the root of the folder which will be the landing page of the content.

                                </Tooltip>
                                {" "}
                                <i class="material-icons">
                                    info
                                </i>
                            </Button>
                        </div>
                    </>
                } */}
                <label for="img" className="mb-2" >Upload Image</label>
                <input type="file" name="uploadfile" id="img" className="mb-2 form-control"
                    onChange={e => {
                        setgradeCategoryIcons(e.target.files[0])
                    }}
                    accept="image/*"
                />
            </Container>
        );
    };

    const body1 = () => {
        return (
            <Container>
                Are you sure you want to delete this subject
            </Container>
        );
    };


    const popUpbody = (open && body()) || (isopen && body()) || (isDeleteopen && body1());

    const hideModal = () => {
        setOpen(false);
        setisOpen(false);
        setisDeleteopen(false)
        setSubjectId("");
        setSubjectName("");
        setContentUrl("")
    };

    const callSubjectDeleteApi = () => {
        Store.addChangeListener(onUpdate);
        let formData = new FormData();
        formData.append("subject_id", subjectId);
        deleteSubjectAction({ formData });
        setSubjectDelete(!isSubjectDelete);
        setisDeleteopen(false)
    };

    const updateSubject = (name, id, url) => {
        setSubjectId(id);
        setContentUrl(url === null ? "" : url)
        setSubjectName(name);
        setOpen(!open);
    };

    // before unload
    useBeforeunload((event) => {
        console.log("before");
        if (loaderoprn || loaderoprn && beforeunloads) {
            event.preventDefault();
        }
    });
    return (
        <>
            <Container fluid className="main-content-container px-4">
                <PageHeader
                    title={"Subject"}
                    subtitle={"MsingiPACK Cloud"}
                    name={"Add Subjects"}
                    onsubmit={addSubject}
                    Status={true}
                />
                <PopUp
                    title={"Add Subjects"}
                    open={isopen}
                    hideModal={hideModal}
                    body={popUpbody}
                    callApi={callSubjectApi}
                    showButton={true}
                />
                <PopUp
                    title={"Update Subjects"}
                    open={open}
                    hideModal={hideModal}
                    body={popUpbody}
                    callApi={callSubjectUpdateApi}
                    showButton={true}
                />
                <PopUp
                    title={"Delete Subjects"}
                    open={isDeleteopen}
                    hideModal={hideModal}
                    body={popUpbody}
                    callApi={callSubjectDeleteApi}
                    showButton={true}
                    ButtonNmae="Delete"
                />

                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="border-bottom d-flex justify-content-between">
                                <h6 className="m-0">Subject</h6>
                                {/* <h6 className="m-0" style={{ fontSize: "30px" }}>
                                    <i onClick={() => {
                                        setupdateApiResp(!updateApiResp)
                                        setloaderoprn(false)
                                    }} class="material-icons">refresh</i></h6> */}
                            </CardHeader>
                            <CardBody className="p-0 pb-3">
                                {loaderoprn ?
                                    <>
                                        {console.log("add", "loaderoprn", loaderoprn)}
                                        {/* <Beforeunload onBeforeunload={(event) => event.preventDefault()} /> */}
                                        <Loader message={"Please wait, While content is uploading"} /></> :
                                    loading === true ?
                                        <Loader />
                                        :
                                        <>
                                            <div className="table-responsive">
                                                <table className="table mb-0">
                                                    <thead className="bg-light">
                                                        <tr>
                                                            <th>S.No</th>
                                                            <th>Grade Name</th>
                                                            <th>Subject Name</th>
                                                            <th>Icon</th>
                                                            <th className="text-right">Action</th>
                                                        </tr>

                                                    </thead>
                                                    <SubjectListRow
                                                        currentPosts={subjectList}
                                                        //sendSubject={sendSubject}
                                                        title={" View Subject"}
                                                        deleteSubject={deleteSubject}
                                                        updateSubject={updateSubject}
                                                        pageNo={currentPage}
                                                    />
                                                </table>
                                            </div>
                                            {currentPosts > 10 &&
                                                <PaginatedItems
                                                    List={currentPosts}
                                                    HandlePageClick={HandlePageClick}
                                                />}
                                        </>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default SubjectList;
