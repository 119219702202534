import React, { useEffect, useState } from "react";
import { Button } from "shards-react";

const CMSlistData = ({ currentPosts, CurrentPage, EditCMS }) => {
    console.log(currentPosts);
    const renderRow = (Obj, i) => {
        return (
            <tr key={i}>
                <td>{i + 1 + (CurrentPage - 1) * 10}</td>
                <td>{Obj.title}</td>
                {/* <td width={400} className="elipis-text"><p>{Obj.content.replace(/(<([^>]+)>)/ig, '')}</p></td> */}
                <td>{Obj.slug}</td>
                <td style={{ textAlign: "center" }}>
                    <Button className="action-btn" title="Edit" theme="info" onClick={() => EditCMS(Obj.id)}>
                        {" "}
                        <i class="material-icons">
                            edit
                        </i>
                    </Button>
                </td>
            </tr>
        );
    };
    const tableBody = currentPosts.length && currentPosts.map(renderRow);

    return (
        <>
            <tbody>
                {tableBody.length !== undefined ?
                    tableBody
                    : <tr>
                        <td colSpan="15" className="text-center noRec-found"> No record found</td>
                    </tr>}
            </tbody>
        </>
    );
};

export default CMSlistData;
