import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Container,
    FormInput
} from "shards-react";
import PageHeader from "../../components/PageHeader";
import { Store } from "../../flux";
import { NotificationListRow } from "../../components/common/ListRow/NotificationListRow";
import PaginatedItems from "../../components/pagination";
import { useParams } from "react-router-dom";
import {
    subjectAction,
    storeSubjectAction,
    deleteSubjectAction,
    updateSubjectAction
} from "../../flux/Action/SubjectAction";
import {
    usersAction
} from "../../flux/Action/UsersAction";
import { NotificationAction, NotificationCounts, storeNotificationAction } from "../../flux/Action/Notification";
import Loader from '../../components/common/loader';
import PopUp from "../../components/Popup/PopUpGrade";
import Multiselect from 'multiselect-react-dropdown';


const Notification = () => {
    const [loading, setLoading] = useState(true);
    const [subjectList, setSubjectList] = useState();
    const [currentPosts, setcurrentPosts] = useState();
    const [updateApiResp, setupdateApiResp] = useState([]);

    const [currentPage, setcurrentPage] = useState(1);

    const [NotificationTitle, setNotificationTitle] = useState();
    const [ContentUrl, setContentUrl] = useState();
    const [subjectId, setSubjectId] = useState();

    const [error, seterror] = useState();

    const [open, setOpen] = useState(false);
    const [isopen, setisOpen] = useState(false);
    const [isSubjectDelete, setSubjectDelete] = useState(false);

    const [Dropdowndata, setDropdowndata] = useState({});
    const [SelectedValue, setSelectedValue] = useState();
    const { id } = useParams();

    useEffect(() => {
        Store.addChangeListener(onUserSelect);
        Store.addChangeListener(onChange);
        NotificationAction(currentPage);
        let formData = new FormData();
        formData.append("user_status", "all");
        usersAction(formData);
    }, [
        open,
        isopen,
        isSubjectDelete,
        updateApiResp,
        currentPage,
        updateApiResp
    ]);


    const HandlePageClick = data => {
        setcurrentPage(data.selected + 1);
    };

    const onUserSelect = () => {
        if (Store.subjectList().status === 200) {
            setSubjectList(Store.subjectList().data.data.notifications);
            setcurrentPosts(Store.subjectList().data.data.tot_notifications);
            setLoading(false);
        }
    };

    const onUpdate = () => {
        setupdateApiResp(Store.subjectUpdate().data.data);
    };

    const onChange = () => {
        if (Store.userList().status === 200) {
            let data = Store.userList().data.data.users;
            let data1 = Store.userList().data.data.tot_users;
            let data2;
            if (data !== undefined) {
                // for (let i = 0; i < data1; i++) {
                //     const obj1 = Object.assign({}, data[i]);
                //     obj1.value = `${data[i].name} (${data[i].email})`
                //     data2.push(obj1);
                //     console.log("data2", data);
                // }
                data2 = data.map((obj, i) => {
                    obj.value = `${obj.name} (${obj.email})`
                    return obj
                })
            }
            setDropdowndata({ "options": data2 })
        }
    };

    const addSubject = () => {
        setisOpen(!isopen);
    };

    const callSubjectApi = () => {
        Store.addChangeListener(onUpdate);
        var user = []
        if (NotificationTitle && ContentUrl && SelectedValue) {
            console.log("SelectedValue", SelectedValue);
            SelectedValue.map((ele) => {
                if (ele.id) {
                    user.push(ele.id)
                }
            })
            storeNotificationAction(NotificationTitle, ContentUrl, user);
            setisOpen(!isopen);
            setSubjectId("");
            setNotificationTitle("");
            setContentUrl("")
            seterror()
            setSelectedValue()
        } else {
            seterror("All fields are required*")
        }
    };



    const hideModal = () => {
        setOpen(false);
        setisOpen(false);
        setSubjectId("");
        setNotificationTitle("");
        seterror()
    };



    const onSelect = (selectedList, selectedItem) => {
        if (selectedItem.label === "All") {
            setSelectedValue([{ label: "All", value: "Select All" }, ...Dropdowndata.options])
        } else {
            setSelectedValue(selectedList)
            console.log("SelectedValue", selectedItem);
        }
    }

    let onRemove = (selectedList, removedItem) => {
        setSelectedValue(selectedList)
        if (removedItem.label === "All") {
            setSelectedValue()
        } else {
            setSelectedValue(selectedList)
            // console.log("SelectedValue", selectedItem);
        }
        console.log("SelectedValue", selectedList);
    }

    const body = () => {
        return (
            <Container>
                <p size="sm" className="mb-1 text-danger" value={error}>{error}</p>
                <FormInput
                    size="sm"
                    type="text"
                    value={NotificationTitle ? NotificationTitle : null}
                    placeholder="Title*"
                    className="mb-2"
                    onChange={e => {
                        setNotificationTitle(e.target.value);
                    }}
                />
                <FormInput
                    size="sm"
                    type="text"
                    value={ContentUrl ? ContentUrl : null}
                    placeholder="Message*"
                    className="mb-2"
                    onChange={e => {
                        setContentUrl(e.target.value);
                    }}
                />
                <Multiselect
                    hideSelectedList
                    options={[{ label: "All", value: "Select All" }, ...Dropdowndata.options]} // Options to display in the dropdown
                    selectedValues={SelectedValue} // Preselected value to persist in dropdown
                    onSelect={onSelect} // Function will trigger on select event
                    onRemove={onRemove} // Function will trigger on remove event
                    // Property name to display in the dropdown options
                    displayValue="value"
                    showCheckbox
                />

            </Container>
        );
    };

    const popUpbody = (open && body()) || (isopen && body());


    return (
        <>
            <Container fluid className="main-content-container px-4">
                <PageHeader
                    title={"Notification"}
                    subtitle={"MsingiPACK Cloud"}
                    name={"Add Notification"}
                    onsubmit={addSubject}
                    Status={true}
                />
                <PopUp
                    title={"Add Notification"}
                    open={isopen}
                    hideModal={hideModal}
                    body={popUpbody}
                    callApi={callSubjectApi}
                    showButton={true}
                />
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="border-bottom">
                                <h6 className="m-0"> Notification </h6>
                            </CardHeader>
                            <CardBody className="p-0 pb-3">
                                {loading === true ?
                                    <Loader />
                                    :
                                    <>
                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <thead className="bg-light">
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Title</th>
                                                        <th>Message </th>
                                                        <th>Time </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <NotificationListRow
                                                        currentPosts={subjectList}
                                                        //sendSubject={sendSubject}
                                                        title={" View Subject"}
                                                        pageNo={currentPage}
                                                    />
                                                </tbody>
                                            </table>
                                        </div>
                                        {currentPosts > 10 &&
                                            <PaginatedItems
                                                List={currentPosts}
                                                HandlePageClick={HandlePageClick}
                                            />

                                        }
                                    </>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default Notification

