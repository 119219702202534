import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { Button } from "shards-react";
import { plansAction, storePlansAction } from "../flux/Action/PlansAction";
import { Store } from "../flux";
import PageHeader from "../components/PageHeader";
import "react-toastify/dist/ReactToastify.css";

function Plan() {
    const [plansList, setplansList] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [IsSubmit, setIsSubmit] = useState(true);
    const [inputValues, setinputValue] = useState({
    });

    useEffect(() => {
        Store.addChangeListener(onChange);
        plansAction();
    }, []);

    const onChange = () => {
        if (Store.ListPlans().status === 200) {
            setplansList(Store.ListPlans().data.data);
        }
    };

    const handleChange = e => {
        const { name, value } = e.target;
        console.log(name, value);
        // setFormErrors({...formErrors , [name] :  "Name is required!"})
        if (e.target.value === "") {
            // errors[name] = "Name is required!";
            setFormErrors({ ...formErrors, [name]: "Price is required!" })
        }
        if (e.target.value == 0) {
            // errors[name] = "Name is required!";
            setFormErrors({ ...formErrors, [name]: "Price is required!" })
        }
        else if (isNaN(e.target.value)) {
            // errors[name] = "Please enter Alphabet value";
            setFormErrors({ ...formErrors, [name]: "Please enter numeric value" })
        } else if (e.target.value.length < 1 || e.target.value.length > 6) {
            // errors[name] = "Number Length Should Be 1 To 6";
            setFormErrors({ ...formErrors, [name]: "Number Length Should Be 1 To 6" })
        } else {
            delete formErrors[name]
            setinputValue({
                ...inputValues,
                [name]: value
            });
        }
    };

    const updatePlan = e => {
        e.preventDefault();
        console.log(e.target.value, inputValues, "valueessss");
        const formData = {
            prices: [
                {
                    id: 1,
                    package_plan: "Bronze",

                    ...(inputValues.Bmonthly && {
                        monthly_price: inputValues.Bmonthly
                    }),
                    ...(inputValues.Btermly && {
                        termly_price: inputValues.Btermly
                    }),
                    ...(inputValues.Byearly && {
                        yearly_price: inputValues.Byearly
                    }),
                    ...(inputValues.Bmonthly_discount_per && {
                        monthly_discount_per: inputValues.Bmonthly_discount_per
                    }),
                    ...(inputValues.Btermly_discount_per && {
                        termly_discount_per: inputValues.Btermly_discount_per
                    }),
                    ...(inputValues.Byearly_discount_per && {
                        yearly_discount_per: inputValues.Byearly_discount_per
                    }),
                    packagePlanDetails: [
                    ]
                },
                {
                    id: 2,
                    package_plan: "Silver",
                    ...(inputValues.Smonthly && {
                        monthly_price: inputValues.Smonthly
                    }),
                    ...(inputValues.Stermly && {
                        termly_price: inputValues.Stermly
                    }),
                    ...(inputValues.Syearly && {
                        yearly_price: inputValues.Syearly
                    }),
                    ...(inputValues.Smonthly_discount_per && {
                        monthly_discount_per: inputValues.Smonthly_discount_per
                    }),
                    ...(inputValues.Stermly_discount_per && {
                        termly_discount_per: inputValues.Stermly_discount_per
                    }),
                    ...(inputValues.Syearly_discount_per && {
                        yearly_discount_per: inputValues.Syearly_discount_per
                    }),
                    packagePlanDetails:
                        plansList[1].packagePlanDetails.map((value, index) => {
                            console.log(value, "value")

                            return ({
                                // plan_id": 2,"grade_id": 1,"monthly_price": "044","termly_price": "0","yearly_price": "0"},
                                plan_id: 2,
                                grade_id: value.grade_id,
                                monthly_price: inputValues[`Mgrade${index}`] ? inputValues[`Mgrade${index}`] : value.monthly_price,
                                termly_price: inputValues[`Sgrade${index}`] ? inputValues[`Sgrade${index}`] : value.termly_price,
                                yearly_price: inputValues[`Ggrade${index}`] ? inputValues[`Ggrade${index}`] : value.yearly_price,
                            })
                        })
                },
                {
                    id: 3,
                    package_plan: "Gold",
                    ...(inputValues.Gmonthly && {
                        monthly_price: inputValues.Gmonthly
                    }),
                    ...(inputValues.Gtermly && {
                        termly_price: inputValues.Gtermly
                    }),
                    ...(inputValues.Gyearly && {
                        yearly_price: inputValues.Gyearly
                    }),
                    ...(inputValues.Gmonthly_discount_per && {
                        monthly_discount_per: inputValues.Gmonthly_discount_per
                    }),
                    ...(inputValues.Gtermly_discount_per && {
                        termly_discount_per: inputValues.Gtermly_discount_per
                    }),
                    ...(inputValues.Gyearly_discount_per && {
                        yearly_discount_per: inputValues.Gyearly_discount_per
                    }),
                    packagePlanDetails: []
                }
            ]
        };

        console.log(formData, "formdata");
        if (Object.keys(formErrors).length === 0) {
            storePlansAction(formData);
            console.log("im log");
        }
        else {
            setIsSubmit(false)
        }
        console.log(inputValues);
    };

    return (
        <>
            <Container fluid className="main-content-container">
                <PageHeader
                    title={"Plans"}
                    subtitle={"MsingiPACK Cloud"}
                    onsubmit={"addgrade"}
                    Status={false}
                />
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardHeader className="border-bottom">
                                <h6 className="m-0">Plans</h6>
                            </CardHeader>
                            <CardBody className="p-0 pb-3">
                                <form onSubmit={updatePlan}>
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-editable ">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Plan Type</th>
                                                    <th scope="row">Grade</th>
                                                    <th scope="col">Monthly Price</th>
                                                    <th scope="col">Termly Price</th>
                                                    <th scope="col">Yearly Price</th>
                                                </tr>{" "}
                                            </thead>{" "}
                                            <tbody>
                                                {
                                                    plansList.map((value, index) => {
                                                        if (Object.keys(value.packagePlanDetails).length === 0) {
                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <th scope="row">{value.package_name}</th>{" "}
                                                                        {/* <th scope="row">{index === 0 ? "Per Subject" : index === 1 ? "Per Grade" : "Complete Package"}</th> */}
                                                                        <th></th>
                                                                        <td>
                                                                            {console.log("inputValues", inputValues)}
                                                                            <input class="form-control" type="text" name={index === 0 ? "Bmonthly" : index === 1 ? "Smonthly" : "Gmonthly"}
                                                                                defaultValue={plansList[index] && plansList[index].monthly_price} onChange={handleChange} />
                                                                            <p className="input-errors">{index === 0 ? formErrors.Bmonthly : index === 1 ? formErrors.Smonthly : formErrors.Gmonthly}</p>
                                                                        </td>
                                                                        <td>
                                                                            <input class="form-control" type="text" defaultValue={plansList[index] && plansList[index].termly_price}
                                                                                name={index === 0 ? "Btermly" : index === 1 ? "Stermly" : "Gtermly"} onChange={handleChange}
                                                                            />
                                                                            <p className="input-errors">{index === 0 ? formErrors.Btermly : index === 1 ? formErrors.Stermly : formErrors.Gtermly}</p>
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                class="form-control" type="text" defaultValue={plansList[index] && plansList[index].yearly_price}
                                                                                name={index === 0 ? "Byearly" : index === 1 ? "Syearly" : "Gyearly"}
                                                                                onChange={handleChange}
                                                                            />
                                                                            <p className="input-errors">{index === 0 ? formErrors.Byearly : index === 1 ? formErrors.Syearly : formErrors.Gyearly}</p>
                                                                        </td>
                                                                        {/* <td>
                                                                            <input
                                                                                class="form-control"
                                                                                type="text"
                                                                                defaultValue={
                                                                                    plansList[index] && plansList[index].discount_per
                                                                                }
                                                                                name={index === 0 ? "Bdiscount" : index === 1 ? "Sdiscount" : "Gdiscount"}
                                                                                onChange={handleChange}
                                                                            />
                                                                            <p className="input-errors">{index === 0 ? formErrors.Bdiscount : index === 1 ? formErrors.Sdiscount : formErrors.Gdiscount}</p>
                                                                        </td> */}
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">{index === 0 ? "Bronze Discount" : index === 1 ?
                                                                            "Silver Discount" : "Gold Discount"}</th>{" "}
                                                                        {/* <th scope="row">{index === 0 ? "Per Subject" : index === 1 ? "Per Grade" : "Complete Package"}</th> */}
                                                                        <th></th>
                                                                        <td>
                                                                            {console.log("inputValues", inputValues)}
                                                                            <input class="form-control" type="text" name={index === 0 ? "Bmonthly_discount_per" : index === 1 ?
                                                                                "Smonthly_discount_per" : "Gmonthly_discount_per"}
                                                                                defaultValue={plansList[index] && plansList[index].monthly_discount_per} onChange={handleChange} />
                                                                            <p className="input-errors">{index === 0 ? formErrors.Bmonthly_discount_per : index === 1 ? formErrors.Smonthly_discount_per : formErrors.Gmonthly_discount_per}</p>
                                                                        </td>
                                                                        <td>
                                                                            <input class="form-control" type="text" defaultValue={plansList[index] && plansList[index].termly_discount_per}
                                                                                name={index === 0 ? "Btermly_discount_per" : index === 1 ? "Stermly_discount_per" : "Gtermly_discount_per"} onChange={handleChange}
                                                                            />
                                                                            <p className="input-errors">{index === 0 ? formErrors.Btermly_discount_per : index === 1 ? formErrors.Stermly_discount_per : formErrors.Gtermly_discount_per}</p>
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                class="form-control" type="text" defaultValue={plansList[index] && plansList[index].yearly_discount_per}
                                                                                name={index === 0 ? "Byearly_discount_per" : index === 1 ? "Syearly_discount_per" : "Gyearly_discount_per"}
                                                                                onChange={handleChange}
                                                                            />
                                                                            <p className="input-errors">{index === 0 ? formErrors.Byearly_discount_per : index === 1 ? formErrors.Syearly_discount_per : formErrors.Gyearly_discount_per}</p>
                                                                        </td>
                                                                    </tr></>)
                                                        } else {
                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <th rowspan={value.packagePlanDetails.length + 2}>{value.package_name}</th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan="4" style={{ padding: "0px", border: "0px" }}></td>
                                                                        {/* <td rowspan={value.packagePlanDetails.length + 1}>
                                                                            <input
                                                                                class="form-control"
                                                                                type="text"
                                                                                name="Sdiscount"
                                                                                defaultValue={value.discount_per}
                                                                                onChange={handleChange}
                                                                            />
                                                                            <p className="input-errors">{formErrors.Sdiscount} </p></td> */}
                                                                    </tr>
                                                                    {value.packagePlanDetails.map((value, index) => {
                                                                        let FormErrors = JSON.stringify(formErrors)
                                                                        console.log("kjgkdjgdkgd", value)
                                                                        return (
                                                                            <>
                                                                                <tr>
                                                                                    <td>{value.grade_name}</td>
                                                                                    <td>
                                                                                        <input
                                                                                            class="form-control"
                                                                                            type="text"
                                                                                            defaultValue={
                                                                                                value.monthly_price
                                                                                            }
                                                                                            name={`Mgrade${[index]}`}
                                                                                            onChange={handleChange}
                                                                                        />
                                                                                        <p className="input-errors">{formErrors[`Mgrade${index}`]}</p>
                                                                                    </td>

                                                                                    <td>
                                                                                        <input
                                                                                            class="form-control"
                                                                                            type="text"
                                                                                            defaultValue={
                                                                                                value.termly_price
                                                                                            }
                                                                                            name={`Sgrade${[index]}`}
                                                                                            onChange={handleChange}
                                                                                        />
                                                                                        <p className="input-errors">{formErrors[`Sgrade${index}`]}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <input
                                                                                            class="form-control"
                                                                                            type="text"
                                                                                            defaultValue={
                                                                                                value.yearly_price
                                                                                            }
                                                                                            name={`Ggrade${[index]}`}
                                                                                            onChange={handleChange}
                                                                                        />
                                                                                        <p className="input-errors">{formErrors[`Ggrade${index}`]}</p>
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    })}
                                                                    <>
                                                                        <tr>
                                                                            <th scope="row">{index === 0 ? "Bronze Discount" : index === 1 ?
                                                                                "Silver Discount" : "Gold Discount"}</th>{" "}
                                                                            {/* <th scope="row">{index === 0 ? "Per Subject" : index === 1 ? "Per Grade" : "Complete Package"}</th> */}
                                                                            <th></th>
                                                                            <td>
                                                                                {console.log("inputValues", inputValues)}
                                                                                <input class="form-control" type="text" name={index === 0 ? "Bmonthly_discount_per" : index === 1 ?
                                                                                    "Smonthly_discount_per" : "Gmonthly_discount_per"}
                                                                                    defaultValue={plansList[index] && plansList[index].monthly_discount_per} onChange={handleChange} />
                                                                                <p className="input-errors">{index === 0 ? formErrors.Bmonthly_discount_per : index === 1 ? formErrors.Smonthly_discount_per : formErrors.Gmonthly_discount_per}</p>
                                                                            </td>
                                                                            <td>
                                                                                <input class="form-control" type="text" defaultValue={plansList[index] && plansList[index].termly_discount_per}
                                                                                    name={index === 0 ? "Btermly_discount_per" : index === 1 ? "Stermly_discount_per" : "Gtermly_discount_per"} onChange={handleChange}
                                                                                />
                                                                                <p className="input-errors">{index === 0 ? formErrors.Btermly_discount_per : index === 1 ? formErrors.Stermly_discount_per : formErrors.Gtermly_discount_per}</p>
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    class="form-control" type="text" defaultValue={plansList[index] && plansList[index].yearly_discount_per}
                                                                                    name={index === 0 ? "Byearly_discount_per" : index === 1 ? "Syearly_discount_per" : "Gyearly_discount_per"}
                                                                                    onChange={handleChange}
                                                                                />
                                                                                <p className="input-errors">{index === 0 ? formErrors.Byearly_discount_per : index === 1 ? formErrors.Syearly_discount_per : formErrors.Gyearly_discount_per}</p>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                </>
                                                            )
                                                        }
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        <div
                                            style={{
                                                textAlign: "right",
                                                padding: "1rem 4rem 1rem 4rem"
                                            }}
                                        >
                                            <Button
                                                style={{
                                                    fontSize: "1rem"
                                                }}
                                                type="submit"
                                            // disabled = {!IsSubmit}
                                            // onClick={updatePlan}
                                            >
                                                Change Plans
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Container fluid className="container h-100"></Container>
            </Container>
        </>
    );
}
export default Plan;


